<template>
  <v-dialog
    v-model="show"
    activator="parent"
    :width="$vuetify.breakpoint.mdAndDown ? '95%' : '50%'"
  >
    <v-card class="modal" :dark="$dark.get()">
      <v-card-title class="flex justify-center modal__title">
        Доступные отчеты
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="#5d4bd0"
              v-bind="attrs"
              v-on="on"
              @click="getClientsExelList"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Обновить список</span>
        </v-tooltip>
      </v-card-title>
      <ul>
        <li v-for="report in reportsList" :key="report.id" class="modal__item">
          <span class="font-weight-bold">Имя:</span> {{ report.excelName }}
          <span class="font-weight-bold">Создан : </span>
          {{ getDateFromArr(report.timeAdd) }}
          <v-btn
            icon
            color="warning"
            @click="download(report.fileName + '.xlsx')"
          >
            <v-icon>mdi-tray-arrow-down</v-icon>
          </v-btn>
        </li>
      </ul>
      <v-card-actions class="modal__actions">
        <v-btn @click="show = false">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import downloadFile from "@/Functions/downloadFile";
export default {
  name: "ClientsListModal",
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    getList: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
      reportsList: [],
      loading: false,
    };
  },
  watch: {
    async showModal() {
      await this.getClientsExelList();
      this.show = !this.show;
    },
  },
  methods: {
    async getClientsExelList() {
      this.reportsList = await this.getList();
    },
    download(file) {
      downloadFile(file);
    },
    //Вспомогательная функция создает дату из массива чисел
    getDateFromArr(respArr) {
      let arr = respArr.map((item) => {
        if (item.toString().length === 1) {
          return (item = `0${item}`);
        } else {
          return item;
        }
      });
      return `${arr[2]}.${arr[1]}.${arr[0]}  ${arr[3]}:${arr[4]}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 500px) {
    padding: 5px;
  }
  &__title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  &__text {
    padding: 0;
    margin: 0;
  }
  &__item {
    padding-top: 10px;
  }
  &__actions {
    width: 100%;
    justify-content: right;
  }
}
</style>
