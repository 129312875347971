import request from "../core/request";

export default {
  getAll() {
    return request.execute("get", "admin/homepage-tags");
  },
  deleteTag(id) {
    return request.execute("delete", `admin/homepage-tags/${id}`);
  },
  createTag(body) {
    return request.execute("post", "admin/homepage-tags", body);
  },
  changeTag(body) {
    return request.execute("put", `admin/homepage-tags/${body.id}`, body);
  },
};
