<template>
  <v-dialog
    v-model="requisitsModal"
    width="50%"
    @click:outside="$emit('update:requisitsModal', false)"
  >
    <v-card class="modal" :dark="$dark.get()">
      <v-card-title class="justify-center">
        {{ requisits ? "Изменение реквизитов" : "Добавление реквизитов" }}
      </v-card-title>
      <v-card-text class="modal__text">
        <v-form ref="reqForm">
          <v-text-field
            v-model="payload.address"
            :rules="[$validate.required]"
            label="Адресс"
            required
            clearable
          ></v-text-field>
          <v-text-field
            v-model="payload.postAddress"
            :rules="[$validate.required]"
            label="Почтовый адрес"
            required
            clearable
          ></v-text-field>
          <v-text-field
            v-model="payload.phone"
            :rules="[$validate.required]"
            label="Телефон"
            required
            clearable
          ></v-text-field>
          <v-text-field
            v-model.number="payload.bin"
            :rules="[$validate.required]"
            label="БИН"
            type="number"
            required
            clearable
          ></v-text-field>
          <v-text-field
            v-model="payload.bik"
            :rules="[$validate.required]"
            label="БИК"
            required
            clearable
          ></v-text-field>
          <v-text-field
            v-model="payload.ao"
            :rules="[$validate.required]"
            label="Название АО"
            required
            clearable
          ></v-text-field>
          <v-text-field
            v-model="payload.kbe"
            :rules="[$validate.required]"
            label="КБЕ"
            required
            clearable
          ></v-text-field>
          <v-text-field
            v-model="payload.knp"
            :rules="[$validate.required]"
            label="КНП"
            required
            clearable
          ></v-text-field>
          <v-text-field
            v-model="payload.iik"
            :rules="[$validate.required]"
            label="ИИК"
            required
            clearable
          ></v-text-field>
          <v-text-field
            v-model="payload.directorName"
            :rules="[$validate.required]"
            label="Имя директора"
            required
            clearable
          ></v-text-field>
          <v-text-field
            v-model.number="payload.commissionPercent"
            :rules="[$validate.required]"
            label="Процент комиссии"
            type="number"
            required
            clearable
            :disabled="$root.isProvider && true"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions class="flex justify-end">
        <v-btn @click="$emit('update:requisitsModal', false)">Отмена</v-btn>
        <v-btn v-if="requisits" color="#5d4bd0" dark @click="changeRequisits()"
          >Изменить</v-btn
        >
        <v-btn v-else color="#5d4bd0" dark @click="createRequisits()"
          >Добавить</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import showMessage from "@/Functions/message";
import api from "@/api";
export default {
  name: "Requisits",
  props: {
    requisitsModal: {
      type: Boolean,
      default: false,
    },
    providerId: {
      type: String,
      default: "",
    },
    requisits: {
      type: Object,
      default: () => null,
    },
    department: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      payload: {
        address: "",
        postAddress: "",
        phone: "",
        bin: "",
        bik: "",
        kbe: "",
        knp: "",
        ao: "",
        iik: "",
        directorName: "",
        commissionPercent: "",
      },
    };
  },
  watch: {
    requisitsModal(val) {
      if (!val) {
        this.$refs.reqForm.resetValidation();
      } else if (this.requisits) {
        this.payload = Object.assign({}, this.requisits);
      }
    },
  },
  methods: {
    async createRequisits() {
      if (this.$refs.reqForm.validate()) {
        let response;
        if (this.department) {
          this.payload.departmentId = this.providerId;
          response = await api.Department.addRequisits(this.payload);
        } else {
          this.payload.providerId = this.providerId;
          response = await api.Providers.addRequisitsToProvider(this.payload);
        }
        if (response.type !== "error") {
          showMessage("Реквизиты отправлены", true);
          this.$emit("editRequisits", false);
          //    очищаем все поля
          Object.keys(this.payload).forEach((key) => (this.payload[key] = ""));
        } else {
          showMessage(response.data.message);
        }
      } else {
        showMessage("Заполните все поля");
      }
    },
    async changeRequisits() {
      if (this.$refs.reqForm.validate()) {
        let response;
        if (this.department) {
          this.payload.departmentId = this.providerId;
          response = await api.Department.changeRequisits(this.payload);
        } else {
          this.payload.providerId = this.providerId;
          response = await api.Providers.changeRequisitsToProvider(
            this.payload
          );
        }
        if (response.type !== "error") {
          showMessage("Реквизиты изменены", true);
          this.$emit("editRequisits");
        } else {
          showMessage(response.data.message);
        }
      } else {
        showMessage("Заполните все поля");
      }
    },
  },
};
</script>
