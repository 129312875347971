<template>
  <div>
    <loader v-if="loading"></loader>
    <table-component
      v-else
      :items="items"
      :edit="true"
      :delet="true"
      :pages="pages"
      :search="true"
      :search-items="categoryItems"
      @get-search="getModal"
      @change_page="changePage"
      @delete-button="deleteButton"
      @seo-save="
        dialog = true;
        seo.category = $event;
      "
      @search="search"
      @image_click="setImage($event)"
      @sort-items="sortBody($event)"
    >
      <slot>
        <div class="btns">
          <v-btn color="warning" @click="downloadTree()"
            >Скачать древо<v-icon right>mdi-tray-arrow-up</v-icon></v-btn
          >
          <v-btn color="#5d4bd0" dark to="/catalog">Очередность каталога</v-btn>
        </div>
      </slot>
    </table-component>
    <category-dialog
      :show="dialogCategory"
      :loading="dialogLoading"
      :category="category"
      @close="dialogCategory = false"
    ></category-dialog>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import TableComponent from "@/components/TableComponent";
import category from "@/model/category.js";
import { mapGetters, mapActions } from "vuex";
import CategoryDialog from "@/components/CategoryDialog";
import showMessage from "@/Functions/message";
import downloadFile from "@/Functions/downloadFile";
import api from "@/api";
export default {
  components: {
    CategoryDialog,
    TableComponent,
    Loader,
  },
  data() {
    return {
      loading: true,
      items: {
        head: [
          { id: 1, name: "ID" },
          { id: 2, name: "Имя" },
          { id: 3, name: "Скрыт" },
          { id: 4, name: "Описание" },
          { id: 5, name: "Родитель" },
          { id: 6, name: "Удален" },
          { id: 7, name: "Позиция на странице" },
          { id: 8, name: "Промо" },
          { id: 9, name: "Дочерние категории" },
          { id: 10, name: "Изображение" },
        ],
        body: [],
      },
      pageInput: {
        page: 0,
        pageSize: 10,
      },
      pages: {
        count: 0,
        current: 0,
        elements: 0,
      },
      dialog: false,
      dialogLoading: false,
      dialogCategory: false,
      seo: {
        seo: 0,
        category: 0,
      },
      categoryItems: [],
      category: {},
    };
  },
  computed: {
    ...mapGetters({
      CATEGORY: "Category/STATE",
    }),
  },
  async created() {
    this.loading = true;
    this.checkPower();
    await this.setBody();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      GET_PAGINATED_CATEGORY: "Category/GET_PAGINATED_CATEGORY",
      DELETE_CATEGORY: "Category/DELETE_CATEGORY",
      SEARCH_CATEGORY: "Category/SEARCH_CATEGORY",
      GET_CATEGORY: "Category/GET_CATEGORY",
      EDIT_IMAGE: "Category/EDIT_IMAGE",
    }),
    async search(value) {
      this.categoryItems = await this.SEARCH_CATEGORY(value);
    },
    async getModal(value) {
      this.dialogCategory = true;
      this.dialogLoading = true;
      this.category = await this.GET_CATEGORY(value);
      this.dialogLoading = false;
    },
    redirect(value) {
      this.$router.push(this.$route.path + "/" + value);
    },
    checkPower() {
      let state = this.$power.check(this.$route.meta.power);
      if (state === false) {
        this.$router.push("/");
      }
    },
    async changePage(value) {
      this.pageInput.page = value;
      await this.setBody();
    },
    async setBody() {
      this.loading = true;
      await this.GET_PAGINATED_CATEGORY(this.pageInput);
      let model = new category();
      this.items.body = model.setBody(this.CATEGORY.paginatedCategory);
      this.pages = model.setPages(this.CATEGORY.paginatedCategory);
      this.loading = false;
    },
    async deleteButton(item) {
      this.loading = true;
      let errors = [];
      for (let i = 0; i < item.length; i++) {
        let response = await this.DELETE_CATEGORY(item[i].id);
        if (response.type === "error") {
          errors.push(response.data.message);
        }
      }
      if (errors.length !== 0) {
        showMessage(errors);
      }
      await this.setBody();
      this.loading = false;
    },
    // { value: File, id }
    setImage({ value, id }) {
      if (value.target.files[0].size > 1000000) {
        showMessage("Размер файла превышает 1мб");
      } else {
        let formData = new FormData();
        formData.append("file", value.target.files[0]);
        this.EDIT_IMAGE({
          categoryId: id,
          input: formData,
        }).then((res) => {
          if (res.type === "error") {
            showMessage(res.data.message);
          } else {
            showMessage(
              "Изображение успешно загрузилось, обновление страницы...",
              true
            );
            this.setBody();
          }
        });
      }
    },
    sortBody({ currentSort, sortDirection }) {
      this.items.body.sort((a, b) => {
        let modifier = 1;
        if (sortDirection === "desc") modifier = -1;
        if (a.fields[currentSort - 1].name < b.fields[currentSort - 1].name) {
          return -1 * modifier;
        }
        if (a.fields[currentSort - 1].name > b.fields[currentSort - 1].name) {
          return 1 * modifier;
        }
        return 0;
      });
    },
    async downloadTree() {
      const date = new Date().toLocaleDateString().replaceAll(".", "-");
      this.loading = true;
      const file = await api.Category.downloadCategory();
      // не ссылка на файл приходит как везде а сам файл в формате xls
      downloadFile(file.data, `Древо категорий от ${date}`);
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.btns {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
</style>
