<template>
  <v-dialog
    v-model="dialog"
    width="380"
    persistent
    :dark="$dark.get()"
    @click:outside="
      $emit('close-modal');
      shiftType = null;
    "
  >
    <v-form :key="loading">
      <v-card class="pb-4 pl-2 pr-2">
        <v-card-title> Рабочий день. </v-card-title>
        <v-card-text>
          <div
            v-if="
              typeof shift.shiftStatus === 'undefined' &&
              typeof shift.employee !== 'undefined'
            "
          >
            <v-select
              v-if="shift.employee.role.name === 'Курьер'"
              v-model="shiftType"
              label="График работы"
              item-text="name"
              :items="SCHEDULE.couriersPlan"
              return-object
            ></v-select>
            <v-select
              v-else-if="shift.employee.role.name === 'Сборщик'"
              v-model="shiftType"
              label="График работы"
              item-text="name"
              :items="SCHEDULE.pickersPlan"
              return-object
            ></v-select>
          </div>
          <div v-else-if="shift.employee">
            <v-row>
              <v-col class="pa-2 pl-4 pb-0" cols="5">Сотрудник</v-col>
              <v-col class="pa-2 pl-4 pb-0" cols="5">{{
                shift.employee.username
              }}</v-col>
              <v-col class="pa-2 pl-4 pt-1 pb-0" cols="5">Статус</v-col>
              <v-col class="pa-2 pl-4 pt-1 pb-0" cols="5">{{
                shift.shiftStatus.title
              }}</v-col>
              <v-col class="pa-2 pl-4 pt-1 mb-1" cols="5">Дата</v-col>
              <v-col class="pa-2 pl-4 pt-1 mb-1" cols="5">{{
                shift.beginPlan.substr(0, 10)
              }}</v-col>
              <v-col class="pa-2 pl-4 mb-2" cols="5"></v-col>
              <v-col class="pa-0 pr-2 mb-2" cols="3"> Время </v-col>
              <v-col class="pa-0 pr-2 mb-2" cols="3"> Дата </v-col>
              <v-col class="pa-2 pl-4 mb-2" cols="5"> Прибыл(план) </v-col>
              <v-col class="pa-0 pr-2 mb-2" cols="3">
                <v-text-field
                  v-model="beginPlanTime"
                  hide-details
                  outlined
                  dense
                  :rules="[$validate.required]"
                ></v-text-field>
              </v-col>
              <v-col class="pa-0 pr-3 mb-2" cols="4">
                <v-text-field
                  v-model="beginPlanDate"
                  hide-details
                  outlined
                  dense
                  :rules="[$validate.required]"
                ></v-text-field>
              </v-col>
              <v-col class="pa-2 pl-4 mb-2" cols="5"> Убыл(план) </v-col>
              <v-col class="pa-0 pr-2 mb-2" cols="3">
                <v-text-field
                  v-model="endPlanTime"
                  hide-details
                  outlined
                  dense
                  :rules="[$validate.required]"
                ></v-text-field>
              </v-col>
              <v-col class="pa-0 pr-3 mb-2" cols="4">
                <v-text-field
                  v-model="endPlanDate"
                  hide-details
                  outlined
                  dense
                  :rules="[$validate.required]"
                ></v-text-field>
              </v-col>
              <v-col class="pa-2 pl-4 mb-2" cols="8">
                Кол.во перерывов(план)
              </v-col>
              <v-col class="pa-0 pr-3 mb-2" cols="4">
                <v-text-field
                  :value="shift.breakAmountPlan || 0"
                  hide-details
                  outlined
                  dense
                  :rules="[$validate.required]"
                ></v-text-field>
              </v-col>
              <v-col class="pa-2 pl-4 mb-2" cols="8">
                Минут перерывов(план)
              </v-col>
              <v-col class="pa-0 pr-3 mb-2" cols="4">
                <v-text-field
                  :value="shift.breakTimePlan || 0"
                  hide-details
                  outlined
                  dense
                  :rules="[$validate.required]"
                ></v-text-field>
              </v-col>
              <v-col class="pa-2 pl-4 mb-2" cols="5"> Прибыл(факт) </v-col>
              <v-col class="pa-0 pr-2 mb-2" cols="3">
                <v-text-field
                  v-model="beginFactTime"
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col class="pa-0 pr-3 mb-2" cols="4">
                <v-text-field
                  v-model="beginFactDate"
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col class="pa-2 pl-4 mb-2" cols="5"> Убыл(факт) </v-col>
              <v-col class="pa-0 pr-2 mb-2" cols="3">
                <v-text-field
                  v-model="endFactTime"
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col class="pa-0 pr-3 mb-2" cols="4">
                <v-text-field
                  v-model="endFactDate"
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col class="pa-2 pl-4 mb-2" cols="8">
                Кол.во перерывов(факт)
              </v-col>
              <v-col class="pa-0 pt-2 pr-3 mb-2" cols="4">
                {{ shift.breakAmountFact || 0 }}
              </v-col>
              <v-col class="pa-2 pl-4" cols="8"> Минут перерывов(факт) </v-col>
              <v-col class="pa-0 pt-2 pr-3" cols="4">
                {{ shift.breakTimeFact || 0 }}
              </v-col>
              <v-col v-if="showDepartments" class="pa-3 pt-0">
                <v-select
                  label="Филиал для переноса"
                  item-text="name"
                  item-value="id"
                  hide-details
                  :items="USER.userInfo.departments"
                  @change="changeDapartment($event)"
                ></v-select>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions class="pl-4">
          <v-btn @click="$emit('close-modal')">Отмена</v-btn>
          <v-btn
            v-if="typeof shift.shiftStatus === 'undefined'"
            color="success"
            @click="addShift"
            >Добавить</v-btn
          >
          <v-btn v-else color="success" @click="updateShift">Сохранить</v-btn>
          <v-btn
            v-if="shift.shiftStatus"
            color="error"
            @click="showPassDialog = true"
            ><v-icon>mdi-delete</v-icon></v-btn
          >
          <v-btn
            v-if="shift.shiftStatus"
            color="#5d4bd0"
            dark
            @click="showDepartments = !showDepartments"
            ><v-icon>mdi-arrow-right</v-icon></v-btn
          >
        </v-card-actions>
        <PasswordDialog
          :show="showPassDialog"
          :true-password="'985152M'"
          @close-modal="closePasswordModal($event)"
        />
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import PasswordDialog from "@/components/Order/PasswordDialog";
import showMessage from "@/Functions/message";
import { mapActions, mapGetters } from "vuex";
import api from "@/api";
export default {
  components: { PasswordDialog },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    shift: {
      type: Object,
      required: true,
    },
    input: {
      type: Object,
      default: null,
    },
    shiftDate: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      shiftInput: {},
      beginPlanDate: "",
      beginPlanTime: "",
      endPlanDate: "",
      endPlanTime: "",
      beginFactDate: "",
      beginFactTime: "",
      endFactDate: "",
      endFactTime: "",
      shiftType: null,
      loading: false,
      showPassDialog: false,
      showDepartments: false,
    };
  },
  computed: {
    ...mapGetters({
      SCHEDULE: "Schedule/STATE",
      USER: "User/STATE",
    }),
  },
  watch: {
    input: {
      async handler(val, old) {
        if (val.departmentId !== old.departmentId) {
          this.scheduleList(this.input.departmentId);
        }
      },
      deep: true,
    },
    shift: {
      handler() {
        if (typeof this.shift.shift === "undefined") {
          if (this.shift.beginPlan !== null) {
            this.beginPlanDate = this.shift.beginPlan.substr(0, 10);
            this.beginPlanTime = this.shift.beginPlan.substr(11, 5);
          }
          if (this.shift.beginFact !== null) {
            this.beginFactDate = this.shift.beginFact.substr(0, 10);
            this.beginFactTime = this.shift.beginFact.substr(11, 5);
          } else {
            this.beginFactDate = null;
            this.beginFactTime = null;
          }

          if (this.shift.endPlan !== null) {
            this.endPlanDate = this.shift.endPlan.substr(0, 10);
            this.endPlanTime = this.shift.endPlan.substr(11, 5);
          }
          if (this.shift.endFact !== null) {
            this.endFactDate = this.shift.endFact.substr(0, 10);
            this.endFactTime = this.shift.endFact.substr(11, 5);
          } else {
            this.endFactDate = null;
            this.endFactTime = null;
          }
        }
      },
      deep: true,
    },
  },
  async mounted() {
    this.scheduleList(this.input.departmentId);
  },
  methods: {
    ...mapActions({
      shiftAdd: "Schedule/SHIFT_ADD",
      scheduleList: "Schedule/SCHEDULE_LIST",
      shiftUpdate: "Schedule/SHIFT_UPDATE",
      shiftDelete: "Schedule/SHIFT_DELETE",
    }),
    closePasswordModal(correctPass) {
      this.showPassDialog = false;
      if (!correctPass) {
        return;
      }
      this.deleteShift();
    },
    async deleteShift() {
      let response = await this.shiftDelete(this.shift.id);
      if (response.type === "success") {
        this.$emit("close-modal");
        this.$emit("rerender");
        this.shiftType = null;
        showMessage("Смена удалена", true);
      } else {
        showMessage(response.text);
      }
    },
    async addShift() {
      this.shiftInput = {
        beginPlan: this.shiftDate + "T" + this.shiftType.timeBegin + ":00.000Z",
        breakAmountPlan: this.shiftType.breakAmountPlan,
        breakTimePlan: this.shiftType.breakTimePlan,
        departmentId: this.input.departmentId,
        employeeId: this.shift.employee.id,
        endPlan: this.shiftDate + "T" + this.shiftType.timeEnd + ":00.000Z",
      };
      let result = await this.shiftAdd(this.shiftInput);
      if (result.type === "success") {
        this.$emit("close-modal");
        this.$emit("rerender");
        this.shiftType = null;
      } else {
        showMessage(result.text);
      }
    },
    async updateShift() {
      this.shiftInput = {
        beginPlan: this.beginPlanDate + "T" + this.beginPlanTime + ":00.000Z",
        breakAmountPlan: this.shift.breakAmountPlan,
        breakTimePlan: this.shift.breakTimePlan,
        departmentId: this.shift.department.id,
        employeeId: this.shift.employee.id,
        endPlan: this.endPlanDate + "T" + this.endPlanTime + ":00.000Z",
        id: this.shift.id,
      };
      if (this.shift.endFact !== null)
        this.shiftInput.endFact =
          this.endFactDate + "T" + this.endFactTime + ":00.000Z";
      if (this.shift.endPlan !== null)
        this.shiftInput.endPlan =
          this.endPlanDate + "T" + this.endPlanTime + ":00.000Z";
      let result = await this.shiftUpdate(this.shiftInput);
      if (result.type === "success") {
        this.$emit("close-modal");
        this.$emit("rerender");
        this.shiftType = null;
      } else {
        showMessage(result.text);
      }
    },
    async changeDapartment(depId) {
      const payload = {
        departmentId: depId,
        shiftId: this.shift.id,
      };
      let response = await api.Schedule.changeShiftDepartment(payload);
      if (response.type !== "error") {
        showMessage("Филиал изменен", true);
        this.$emit("close-modal");
        this.$emit("rerender");
      } else {
        showMessage(response.data.messsage);
      }
    },
  },
};
</script>
