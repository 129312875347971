<template>
  <v-dialog
    v-model="modal"
    activator="parent"
    width="70%"
    @click:outside="$emit('update:modal', false)"
  >
    <v-card class="modal" :dark="$dark.get()">
      <v-card-title class="flex justify-center pb-0">
        Редактирование фото
      </v-card-title>
      <v-card-text v-if="photo" class="pa-0">
        <p class="modal_text">
          Вы можете передвигать рамку нажав на нее, либо изменять ее размер с
          помощью кнопок больше или меньше
        </p>
        <p class="modal_text">
          Нажав кнопку 'Изменить' фото обрежется по границам рамки, убрав
          затемненную область
        </p>
        <div class="photo-conteiner">
          <!-- верхние кнопки -->
          <div class="photo-conteiner_btns">
            <v-btn
              icon
              @click="changeFrame('верх', true)"
              @mousedown="forceChange('верх', true)"
              @mouseup="stopChange()"
              @mouseleave="stopChange()"
              ><v-icon color="#5d4bd0">mdi-plus-circle</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="changeFrame('верх')"
              @mousedown="forceChange('верх')"
              @mouseup="stopChange()"
              @mouseleave="stopChange()"
              ><v-icon color="#5d4bd0">mdi-minus-circle</v-icon>
            </v-btn>
          </div>
          <div class="photo-conteiner_box">
            <!-- левые кнопки -->
            <div class="photo-conteiner_btns left">
              <v-btn
                icon
                @click="changeFrame('лево', true)"
                @mousedown="forceChange('лево', true)"
                @mouseup="stopChange()"
                @mouseleave="stopChange()"
                ><v-icon color="#5d4bd0">mdi-plus-circle</v-icon>
              </v-btn>
              <v-btn
                icon
                @click="changeFrame('лево')"
                @mousedown="forceChange('лево')"
                @mouseup="stopChange()"
                @mouseleave="stopChange()"
                ><v-icon color="#5d4bd0">mdi-minus-circle</v-icon>
              </v-btn>
            </div>

            <div class="photo">
              <img ref="photo" :src="photo.fileUrl" alt="photo" />
              <canvas
                ref="canvas"
                class="canvas"
                @mousedown="mouseDown = true"
                @mouseup="mouseDown = false"
                @mousemove="moveFrame($event)"
              ></canvas>
            </div>
            <!-- правые кнопки -->
            <div class="photo-conteiner_btns left">
              <v-btn
                icon
                @click="changeFrame('право', true)"
                @mousedown="forceChange('право', true)"
                @mouseup="stopChange()"
                @mouseleave="stopChange()"
                ><v-icon color="#5d4bd0">mdi-plus-circle</v-icon>
              </v-btn>
              <v-btn
                icon
                @click="changeFrame('право')"
                @mousedown="forceChange('право')"
                @mouseup="stopChange()"
                @mouseleave="stopChange()"
                ><v-icon color="#5d4bd0">mdi-minus-circle</v-icon>
              </v-btn>
            </div>
          </div>
          <!-- нижние кнопки -->
          <div class="photo-conteiner_btns">
            <v-btn
              icon
              @click="changeFrame('низ', true)"
              @mousedown="forceChange('низ', true)"
              @mouseup="stopChange()"
              @mouseleave="stopChange()"
              ><v-icon color="#5d4bd0">mdi-plus-circle</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="changeFrame('низ')"
              @mousedown="forceChange('низ')"
              @mouseup="stopChange()"
              @mouseleave="stopChange()"
              ><v-icon color="#5d4bd0">mdi-minus-circle</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="modal__actions">
        <v-btn color="#5d4bd0" dark @click="changeFoto()">Изменить</v-btn>
        <v-btn @click="$emit('update:modal', false)">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/api";
import ShowMessage from "@/Functions/message";

export default {
  name: "RedactPhoto",
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    photo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selection: {
        top: 50,
        left: 50,
        width: 200,
        height: 200,
      },
      canvas: null,
      mouseDown: false,
      intervalId: null,
    };
  },
  watch: {
    modal(val) {
      if (val) {
        setTimeout(() => {
          this.initCanvas();
        }, 200);
      }
    },
  },
  methods: {
    async changeFoto() {
      this.selection.initialWidth = this.canvas.width;
      this.selection.initialHeight = this.canvas.height;
      this.selection.id = this.photo.id;
      const response = await api.Products.redactPhoto(this.selection);
      if (response.type !== "error") {
        ShowMessage("Фото успешно обрезано", true);
        this.$emit("update:modal", false);
        this.$emit("reload");
      } else {
        ShowMessage(response.data.message);
      }
    },
    changeFrame(direction, plus) {
      switch (direction) {
        case "лево":
          if (plus) {
            this.selection.left -= 1;
            this.selection.width += 1;
          } else {
            this.selection.left += 1;
            this.selection.width -= 1;
          }
          break;
        case "право":
          if (plus) {
            this.selection.width += 1;
          } else {
            this.selection.width -= 1;
          }
          break;
        case "верх":
          if (plus) {
            this.selection.top -= 1;
            this.selection.height += 1;
          } else {
            this.selection.top += 1;
            this.selection.height -= 1;
          }
          break;
        case "низ":
          if (plus) {
            this.selection.height += 1;
          } else {
            this.selection.height -= 1;
          }
          break;
      }
      this.checkSelection();
      this.drawSelection();
    },
    forceChange(direction, plus) {
      this.intervalId = setInterval(() => {
        this.changeFrame(direction, plus);
      }, 20);
    },
    stopChange() {
      clearInterval(this.intervalId);
    },
    moveFrame(e) {
      if (!this.mouseDown) {
        return;
      }
      //Меняем позицию выделенного фрагмента
      this.selection.left = e.offsetX - this.selection.width / 2;
      this.selection.top = e.offsetY - this.selection.height / 2;
      this.checkSelection();
      this.drawSelection();
    },
    // начальная отрисовка канваса
    initCanvas() {
      const image = this.$refs.photo;
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");
      canvas.width = image.width;
      canvas.height = image.height;
      canvas.setAttribute(
        "style",
        "top: " + image.offsetTop + "px; left: " + image.offsetLeft + "px;"
      );
      this.canvas = canvas;
      this.ctx = ctx;
      let smallSide;
      // квадрат на 80% от меньшей стороны рисуем по центру
      if (image.width > image.height) {
        smallSide = image.height * 0.8;
        this.selection.width = smallSide;
        this.selection.height = smallSide;
        this.selection.left = (image.width - smallSide) / 2;
        this.selection.top = image.height * 0.1;
      } else {
        smallSide = image.width * 0.8;
        this.selection.width = smallSide;
        this.selection.height = smallSide;
        this.selection.left = image.height * 0.1;
        this.selection.top = (image.height - smallSide) / 2;
      }
      this.drawSelection();
    },

    drawSelection() {
      const ctx = this.ctx;
      const canvas = this.canvas;
      // темная область
      ctx.fillStyle = "rgba(0, 0, 0, 0.7)";
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      // выделенная область фото
      ctx.clearRect(
        this.selection.left,
        this.selection.top,
        this.selection.width,
        this.selection.height
      );
      // линии
      ctx.strokeStyle = "#fff";
      ctx.beginPath();

      ctx.moveTo(this.selection.left, 0);
      ctx.lineTo(this.selection.left, canvas.height);

      ctx.moveTo(this.selection.left + this.selection.width, 0);
      ctx.lineTo(this.selection.left + this.selection.width, canvas.height);

      ctx.moveTo(0, this.selection.top);
      ctx.lineTo(canvas.width, this.selection.top);

      ctx.moveTo(0, this.selection.top + this.selection.height);
      ctx.lineTo(canvas.width, this.selection.top + this.selection.height);

      ctx.stroke();
    },
    // проверка чтобы выделенная область не выходила за границы фото
    checkSelection() {
      const selection = this.selection;
      const canvas = this.canvas;
      if (selection.width < 0) {
        selection.width = 0;
      }
      if (selection.height < 0) {
        selection.height = 0;
      }
      if (selection.width > canvas.width) {
        selection.width = canvas.width;
      }
      if (selection.height > canvas.height) {
        selection.height = canvas.height;
      }
      if (selection.left < 0) {
        selection.left = 0;
      }
      if (selection.top < 0) {
        selection.top = 0;
      }
      if (selection.left > canvas.width - selection.width) {
        selection.left = canvas.width - selection.width;
      }
      if (selection.top > canvas.height - selection.height) {
        selection.top = canvas.height - selection.height;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__actions {
    width: 100%;
    justify-content: right;
  }
  &_text {
    margin-bottom: 0;
    text-align: center;
    font-size: 11px;
  }
}
.photo {
  position: relative;
  cursor: pointer;
}
.canvas {
  position: absolute;
}
.photo-conteiner {
  display: flex;
  flex-direction: column;
  align-items: center;
  &_box {
    display: flex;
    align-items: center;
  }
}
.left {
  display: flex;
  flex-direction: column;
}
</style>
