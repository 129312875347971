<template>
  <v-container fluid>
    <v-card height="86vh">
      <v-card-title>
        {{ $route.name }}
      </v-card-title>
      <v-card-text>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-row>
              <v-col cols="12" md="7">
                <tinymce id="d1" v-model="input.content"></tinymce>
              </v-col>
              <v-col cols="12" md="5" class="d-flex justify-center">
                <div class="page-card">
                  <site-page :content="input.content"></site-page>
                </div>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <div
              class="d-flex justify-center align-center page-card"
              style="width: 100%"
            >
              <v-card width="600px" elevation="6" class="ma-2 pa-12">
                <v-form ref="form">
                  <v-text-field
                    v-model="input.name"
                    label="Название"
                    disabled
                  ></v-text-field>
                </v-form>
              </v-card>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="ml-2 pa-0"
          fab
          x-small
          color="#5d4bd0"
          :disabled="tab === 0"
          @click="tab--"
          ><v-icon>mdi-chevron-left</v-icon></v-btn
        >
        <v-btn
          class="ml-2 pa-0"
          fab
          x-small
          color="#5d4bd0"
          :disabled="tab === 1"
          @click="tab++"
          ><v-icon>mdi-chevron-right</v-icon></v-btn
        >
        <v-btn
          v-if="tab === 1"
          class="ml-2"
          color="#5d4bd0"
          dark
          @click="edit === false ? add() : update()"
          >{{ edit === false ? "Добавить" : "Сохранить" }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import SitePage from "../components/SitePage";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { SitePage },
  data: () => ({
    input: {},
    tab: 0,
    edit: false,
    loading: true,
  }),
  computed: {
    ...mapGetters({}),
  },
  async created() {
    if (this.$route.params.id) {
      this.edit = true;
      let response = await this.getStatic(this.$route.params.id);
      if (response.type === "success") {
        this.input = response.data;
        this.input.seoId = this.input.seo[0].id;
      }
    }
    this.loading = false;
  },
  methods: {
    ...mapActions({
      saveStatic: "Static/SAVE_STATIC",
      addStatic: "Static/ADD_STATIC",
      getStatic: "Static/GET_STATIC_ID",
    }),
    async add() {
      let response = await this.addStatic(this.input);
      if (response.type === "success") {
        this.$router.push("/staticpage");
      } else {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: response.text,
        });
      }
    },
    async update() {
      let response = await this.saveStatic(this.input);
      if (response.type === "success") {
        this.$router.push("/staticpage");
      } else {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: response.text,
        });
      }
    },
  },
};
</script>
<style>
.page-card {
  height: 71vh;
  width: 550px;
}
#d1_ifr {
  height: 60.3vh !important;
}
@media screen and (max-height: 800px) {
  .page-card {
    height: 67vh;
  }
  #d1_ifr {
    height: 52.8vh !important;
  }
}
</style>
