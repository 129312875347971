<template>
  <v-dialog v-model="show" activator="parent" width="70%">
    <loader v-if="loading"></loader>
    <v-card v-else class="modal" :dark="$dark.get()">
      <v-card-title class="modal__header">
        <p>Баланс продукта</p>
        <p>{{ input.name }}</p>
      </v-card-title>
      <v-card-text v-if="showedProviderId">
        <v-row>
          <v-btn
            v-for="provider in currentProviders"
            :key="provider.id"
            :color="provider.id === showedProviderId ? '#5d4bd0' : ''"
            class="ma-2"
            @click="showedProviderId = provider.id"
            >{{ provider.name }}</v-btn
          >
        </v-row>
        <v-simple-table>
          <thead>
            <tr>
              <th>Филиал</th>
              <th>Остаток</th>
              <th>Цена</th>
              <th>Цена скидки</th>
              <th>Скидка в %</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="departmentObj in input.pricesList.filter(
                (item) => item.providerId === showedProviderId
              )"
              :key="departmentObj.departmentId + showedProviderId"
            >
              <td>{{ departmentObj.departmentName }}</td>
              <td>{{ departmentObj.balance }}</td>
              <td>{{ departmentObj.price }}</td>
              <td>{{ departmentObj.discountPrice || 0 }}</td>
              <td>{{ departmentObj.discountPercent || 0 }}%</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
      <v-card-text v-else
        >У данного продукта нет поставщиков и баланса</v-card-text
      >
      <v-card-actions class="modal__actions">
        <v-btn color="#5d4bd0" dark @click="show = false">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "@/components/Loader";
export default {
  name: "BalanceModal",
  components: { Loader },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    productId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      loading: true,
      input: null,
      showedProviderId: null,
      currentProviders: [],
    };
  },
  computed: {
    ...mapGetters({
      PROVIDERS: "Providers/STATE",
    }),
  },
  watch: {
    async showModal() {
      this.show = !this.show;
      this.loading = true;
      if (!this.PROVIDERS.allProviders.length) {
        await this.getAllProviders();
      }
      await this.setProduct();
      this.loading = false;
    },
  },
  methods: {
    ...mapActions({
      getDepartment: "Department/GET_DEPARTMENT",
      getCategory: "Category/GET_ALL_CATEGORY",
      getProduct: "Products/GET_PRODUCT",
      getMeasure: "Measure/GET_ALL_MEASURE",
      productImage: "Products/ADD_IMAGE",
      editProductAction: "Products/EDIT_PRODUCT",
      getAllProviders: "Providers/GET_ALL_PROVIDERS",
    }),
    async setProduct() {
      let response = await this.getProduct(this.productId);
      this.getCurrentProviders(response.pricesList);
      this.input = response;
    },
    // находит нужных поставщиков на основании массива с ценами у продукта
    getCurrentProviders(pricesList) {
      let current = this.PROVIDERS.allProviders.filter((provider) =>
        pricesList.map((item) => item.providerId).includes(provider.id)
      );
      if (current.length) {
        this.currentProviders = current;
        this.showedProviderId = current[0].id;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__actions {
    width: 100%;
    justify-content: right;
  }
  &__header {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
  }
}
</style>
