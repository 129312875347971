<template>
  <div :class="'bottom-bar elevation-3 ' + active">
    <v-btn
      v-if="edit"
      icon
      color="white"
      :to="$route.path + order + tag + '/' + id"
      :disabled="disable"
    >
      <v-icon> mdi-pencil </v-icon>
    </v-btn>
    <v-btn v-if="delet" icon color="white" @click="$emit('show')">
      <v-icon> mdi-trash-can </v-icon>
    </v-btn>
    <div v-if="disapprove">
      <v-btn icon color="white" @click="$emit('approve')">
        <v-icon> mdi-thumb-up </v-icon>
      </v-btn>
      <v-btn icon color="white" @click="$emit('show')">
        <v-icon> mdi-thumb-down </v-icon>
      </v-btn>
    </div>

    <v-btn
      v-if="editCategory"
      icon
      color="white"
      @click="$emit('showEditCategoryModal')"
    >
      <v-icon> mdi-pencil </v-icon>
    </v-btn>
  </div>
</template>
<script>
export default {
  props: {
    active: {
      type: String,
      default: "",
    },
    disapprove: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    editCategory: {
      type: Boolean,
      default: false,
    },
    delet: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: 0,
    },
    order: {
      type: String,
      default: "",
    },
    tag: {
      type: String,
      default: "",
    },
  },
};
</script>
<style scoped>
.bottom-bar {
  position: fixed;
  bottom: 0px;
  opacity: 0;
  transition: 0.5s ease;
  width: 100px;
  background-color: #5d4bd0 !important;
  color: white !important;
  justify-content: space-around;
  border-radius: 6px;
  display: flex;
  visibility: hidden;
  z-index: 10000;
}
.active {
  bottom: 20px;
  opacity: 1;
  visibility: visible;
}
</style>
