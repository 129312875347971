<template>
  <v-card :dark="$dark.get()" class="coupon">
    <v-card-text class="coupon_content">
      <div class="coupon_text">
        <p class="coupon_title">Название:</p>
        <p class="coupon_data">{{ action.name }}</p>
      </div>
      <div v-if="action.product" class="coupon_text">
        <p class="coupon_title">Продукт:</p>
        <p class="coupon_data">{{ action.product.name }}</p>
      </div>
      <div v-if="action.category" class="coupon_text">
        <p class="coupon_title">Категория:</p>
        <p class="coupon_data">{{ action.category.name }}</p>
      </div>
      <div class="coupon_text">
        <p class="coupon_title">Время проведения:</p>
        <p class="coupon_data">
          {{ action.promotionType === "UPCOMING" ? "Анонс" : "Действующая" }}
        </p>
      </div>
      <div class="coupon_text">
        <p class="coupon_title">Описание:</p>
        <p class="coupon_data">{{ action.description }}</p>
      </div>
      <div v-if="action.priority" class="coupon_text">
        <p class="coupon_title">Приоритет:</p>
        <p class="coupon_data">{{ action.priority }}</p>
      </div>
      <div class="coupon_text">
        <p class="coupon_title">Города:</p>
        <p class="coupon_data">
          <span v-for="city in getCity(action.cityIds)" :key="city.id">{{
            `${city.name} `
          }}</span>
        </p>
      </div>
      <div class="coupon_text">
        <p class="coupon_title">Дата начала:</p>
        <p class="coupon_data">{{ action.startDate }}</p>
      </div>
      <div class="coupon_text">
        <p class="coupon_title">Дата окончания:</p>
        <p class="coupon_data">{{ action.endDate }}</p>
      </div>
      <div class="coupon_text">
        <v-img v-if="action.imageUrl" :src="action.imageUrl"></v-img>
        <p v-else>Изображение отсутствует</p>
      </div>
    </v-card-text>
    <v-card-actions class="coupon_actions">
      <div>
        <v-btn icon color="#5D4BD0" @click="$emit('edit')"
          ><v-icon>mdi-pencil-circle</v-icon>
        </v-btn>
        <v-btn icon color="error" @click="$emit('delete')"
          ><v-icon>mdi-trash-can</v-icon>
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ActionCard",
  props: {
    action: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      CITY: "City/STATE",
    }),
  },
  methods: {
    getCity(cityesArr) {
      const arr = this.CITY.allCity.filter(
        (city) => cityesArr.indexOf(city.id) !== -1
      );
      return arr;
    },
  },
};
</script>

<style lang="scss" scoped>
.coupon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #5d4bd0;
  border-radius: 20px;
  width: calc(25% - 20px);
  @media screen and (max-width: 500px) {
    width: calc(100% - 20px);
  }
  &_content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &_text {
    display: flex;
    gap: 5px;
    background-color: #eeeeee;
    border-radius: 8px;
    padding: 5px 15px;
  }
  &_title {
    margin-bottom: 0;
  }
  &_data {
    margin-bottom: 0;
    font-weight: bold;
    color: black;
  }
  &_actions {
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 0 16px 16px;
  }
}
</style>
