<template>
  <v-card :dark="$dark.get()" style="border-radius: 0px" flat>
    <v-simple-table :dark="$dark.get()" fixed-header height="70vh">
      <thead>
        <tr>
          <th>Фото</th>
          <th>Название</th>
          <th>Количество</th>
          <th>Цена</th>
          <th style="width: 30px"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in orderList" :key="item.id">
          <td>
            <v-img :src="item.image" width="50" height="50"></v-img>
          </td>
          <td>
            {{ item.product.name }}
          </td>
          <td>
            <div class="d-flex align-center">
              {{ item.amount.toFixed(1) + " " + item.product.measure.name }}
              <div class="d-flex flex-column justify-center ml-2">
                <v-btn icon x-small @click="$emit('up-product', index)"
                  ><v-icon>mdi-chevron-up</v-icon></v-btn
                >
                <v-btn icon x-small @click="$emit('down-product', index)"
                  ><v-icon>mdi-chevron-down</v-icon></v-btn
                >
              </div>
            </div>
          </td>
          <td>
            {{ (item.price * item.amount).toFixed(1) + "тг" }}
          </td>
          <td>
            <v-btn color="error" icon @click="$emit('remove-product', index)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>
<script>
export default {
  props: {
    orderList: {
      type: Array,
      required: true,
    },
  },
};
</script>
