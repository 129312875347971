<template>
  <v-dialog
    v-model="modal"
    activator="parent"
    width="50%"
    @click:outside="$emit('update:modal', false)"
  >
    <v-card class="modal" :dark="$dark.get()">
      <v-card-title class="justify-center"
        >{{ noProfile ? "Создание" : "Редактирование" }} профиля</v-card-title
      >
      <v-form
        ref="formProfile"
        @submit.prevent="$emit('create', $refs.formProfile)"
      >
        <v-card-text class="modal__text">
          <input
            ref="profileFoto"
            type="file"
            hidden
            accept="image/*"
            @change="$emit('uploadFoto', $event.target.files[0])"
          />
          <v-btn color="#5d4bd0" dark @click="$refs.profileFoto.click()"
            >{{ createData.image ? "Изменить" : "Загрузить" }} картинку</v-btn
          >
          <v-img
            v-if="createData.image"
            :src="createData.image.fileUrl"
            class="mb-4 mt-4"
          />
          <div class="checkboxes">
            <v-checkbox
              v-model="createData.selfDelivery"
              label="Своя доставка"
            ></v-checkbox>
            <v-checkbox
              v-model="createData.ayanDeliveryComposition"
              label="Склады и доставка Аяна"
            ></v-checkbox>
          </div>
        </v-card-text>
        <v-card-actions class="flex justify-end">
          <v-btn @click="$emit('update:modal', false)">Закрыть</v-btn>
          <v-btn color="#5d4bd0" dark type="submit">{{
            noProfile ? "Создать" : "Изменить"
          }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CreateProfile",
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    noProfile: {
      type: Boolean,
      default: false,
    },
    createData: {
      type: Object,
      default: () => null,
    },
  },
};
</script>
