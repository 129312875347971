<template>
  <div>
    <loader v-if="loading"></loader>
    <v-card v-else>
      <v-card-title class="card-title">
        <p>{{ $route.name }}</p>
        <v-select
          v-model="depId"
          outlined
          hide-details
          dense
          :items="USER.userInfo.departments"
          item-text="name"
          item-value="id"
          class="input"
        ></v-select>
        <v-btn v-if="!showRevards" color="#5d4bd0" dark @click="openRevards()"
          >Ставки</v-btn
        >
        <v-btn v-else color="#5d4bd0" dark @click="showRevards = false"
          >Рейтинги</v-btn
        >
      </v-card-title>
      <v-form v-model="form" @submit.prevent="onSubmit">
        <v-card-text>
          <!-- Переключатель радиобаттонов -->
          <v-radio-group v-model="ratingVersion">
            <v-radio label="v1" value="v1"></v-radio>
            <v-radio label="v2" value="v2" @click="openRaitingV2()"></v-radio>
          </v-radio-group>

          <div v-if="showRevards">
            <v-simple-table>
              <thead>
                <tr>
                  <th>Роль</th>
                  <th>Ставка</th>
                  <th>Изменить</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="revard in REVARDS" :key="revard.id">
                  <td>{{ getRoleName(revard.roleId) }}</td>
                  <td>{{ revard.reward }}</td>
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="#5d4bd0"
                          v-bind="attrs"
                          v-on="on"
                          @click="openRevardModal(revard)"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Изменить ставку</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-btn class="mt-2" color="#5d4bd0" dark @click="revardModal = true"
              >Добавить ставку</v-btn
            >
          </div>

          <!-- Таблица -->
          <v-simple-table v-if="!showRevards" height="64vh" fixed-header>
            <!-- Заголовки -->
            <thead>
              <tr>
                <th v-for="head in items.headers" :key="head.id + '/header'">
                  <span
                    :class="head.id === 4 && ratingVersion === 'v1' && 'hidden'"
                  >
                    {{ head.text }}
                  </span>
                </th>
              </tr>
            </thead>
            <!-- Тело таблицы -->
            <tbody>
              <template v-if="ratingVersion === 'v1'">
                <tr v-for="ability in abilities" :key="ability.id + '/ability'">
                  <th>
                    <v-chip v-if="ability.id === 0" color="red">
                      <v-icon class="mr-3">mdi-shoe-sneaker</v-icon>
                      <span>{{ ability.text }}</span>
                    </v-chip>
                    <v-chip v-if="ability.id === 1" color="primary">
                      <v-icon class="mr-3">mdi-speedometer</v-icon>
                      <span>{{ ability.text }}</span>
                    </v-chip>
                    <v-chip v-if="ability.id === 2" color="green">
                      <v-icon class="mr-3">mdi-account-credit-card</v-icon>
                      <span>{{ ability.text }}</span>
                    </v-chip>
                    <v-chip v-if="ability.id === 3">
                      <v-icon class="mr-3">mdi-cash-multiple</v-icon>
                      <span>{{ ability.text }}</span>
                    </v-chip>
                  </th>
                  <th v-for="item in items.body" :key="item.id + '/item'">
                    <v-text-field
                      v-if="ability.id === 0"
                      v-model.number="item.minStamina"
                      :rules="[...mainRules, getRules('minStamina')]"
                      type="number"
                    />
                    <v-text-field
                      v-else-if="ability.id === 1"
                      v-model.number="item.minSpeed"
                      :rules="[...mainRules, getRules('minSpeed')]"
                      type="number"
                    />
                    <v-text-field
                      v-else-if="ability.id === 2"
                      v-model.number="item.minSkill"
                      :rules="[...mainRules, getRules('minSkill')]"
                      type="number"
                    />
                    <v-text-field
                      v-else-if="ability.id === 3"
                      v-model.number="item.reward"
                      :rules="[...mainRules]"
                      type="number"
                    />
                  </th>
                </tr>
              </template>
              <!-- Отображение только для скорости и вознаграждения -->
              <template v-else>
                <tr v-for="abilityV2 in abilitiesV2" :key="abilityV2.id">
                  <th>
                    <v-chip v-if="abilityV2.id === 0" color="#5d4bd0">
                      <v-icon class="mr-3">mdi-arrow-up-bold-outline</v-icon>
                      <span>{{ abilityV2.text }}</span>
                    </v-chip>
                    <v-chip v-if="abilityV2.id === 1" color="green">
                      <v-icon class="mr-3">mdi-cash-multiple</v-icon>
                      <span>{{ abilityV2.text }}</span>
                    </v-chip>
                    <v-chip v-if="abilityV2.id === 2" color="purple">
                      <v-icon class="mr-3">mdi-one-up</v-icon>
                      <span>{{ abilityV2.text }}</span>
                    </v-chip>
                  </th>
                  <th v-for="item in items.body" :key="item.id + '/item'">
                    <v-text-field
                      v-if="abilityV2.id === 0"
                      v-model.number="item.minValue"
                      :rules="[...mainRules, getRules('minValue')]"
                      type="number"
                    />
                    <v-text-field
                      v-if="abilityV2.id === 1"
                      v-model.number="item.reward"
                      :rules="[...mainRules]"
                      type="number"
                    />
                    <v-text-field
                      v-if="abilityV2.id === 2"
                      v-model.trim="item.name"
                      :rules="[$validate.required]"
                      type="text"
                    />
                  </th>
                </tr>
              </template>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-card-actions v-if="!showRevards">
          <v-btn
            color="success"
            type="submit"
            :disabled="!form || isLevelsAreChanged"
          >
            <v-icon>mdi-plus</v-icon>Сохранить
          </v-btn>
          <v-btn color="#5d4bd0" dark @click="levelModal = true"
            >Добавить уровень</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
    <!-- ввод пароля для второго версии -->
    <v-dialog v-model="showPasswordDialog" width="50%" persistent>
      <v-card>
        <v-card-title>Введите пароль</v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="password"
              label="Пароль"
              type="password"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="#5d4bd0" dark @click="checkPassword">ОК</v-btn>
          <v-btn @click="cancelPassword">Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Создание ставки -->
    <v-dialog v-model="revardModal" width="60%">
      <v-card>
        <v-card-title>
          {{ saveMode ? "Изменить ставку" : "Создать ставку" }}</v-card-title
        >
        <v-card-text>
          <v-form ref="revardForm">
            <v-select
              v-model="createData.roleId"
              outlined
              hide-details
              dense
              label="Роль"
              :items="roles"
              item-text="name"
              item-value="id"
              required
              :rules="[$validate.required]"
              class="input"
            ></v-select>
            <v-text-field
              v-model.number="createData.reward"
              label="Ставка"
              type="number"
              required
              :rules="[$validate.required]"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="saveMode" color="#5d4bd0" dark @click="changeRevard()"
            >Изменить</v-btn
          >
          <v-btn v-else color="#5d4bd0" dark @click="createRevard()"
            >Создать</v-btn
          >
          <v-btn @click="revardModal = false">Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Создание уровня -->
    <v-dialog v-model="levelModal" width="60%">
      <v-card>
        <v-card-title>Создать уровень</v-card-title>
        <v-card-text>
          <v-form ref="levelForm">
            <v-select
              v-model="createData.roleId"
              outlined
              hide-details
              dense
              label="Роль"
              :items="roles"
              item-text="name"
              item-value="id"
              required
              :rules="[$validate.required]"
              class="input"
            ></v-select>
            <v-text-field
              v-model="createData.name"
              label="Название"
              type="text"
              required
              :rules="[$validate.required]"
            ></v-text-field>
            <v-text-field
              v-model="createData.minValue"
              label="Мин. значение"
              type="number"
              required
              :rules="[$validate.required]"
            ></v-text-field>
            <v-text-field
              v-model="createData.level"
              label="Уровень"
              type="number"
              required
              :rules="[$validate.required]"
            ></v-text-field>
            <v-text-field
              v-model="createData.reward"
              label="Ставка"
              type="number"
              required
              :rules="[$validate.required]"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="#5d4bd0" dark @click="createLevel()">Создать</v-btn>
          <v-btn @click="levelModal = false">Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import ShowMessage from "@/Functions/message";
import api from "@/api";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    Loader,
  },
  data() {
    return {
      password: "",
      depId: 5,
      loading: true,
      form: false,
      ratingVersion: "v1",
      showRevards: false,
      showPasswordDialog: false,
      revardModal: false,
      levelModal: false,
      saveMode: false,
      createData: {},
      roles: [
        { id: 2, name: "Сборщик" },
        { id: 3, name: "Курьер" },
      ],
      abilities: [
        { id: 0, name: "minStamina", text: "Выносливость(мин.зн.)" },
        { id: 1, name: "minSpeed", text: "Скорость(мин.зн.)" },
        { id: 2, name: "minSkill", text: "Мастерство(мин.зн.)" },
        { id: 3, name: "reward", text: "Вознаграждение" },
        { id: 4, name: "name", text: "Статус" },
      ],
      abilitiesV2: [
        { id: 0, name: "maxValue", text: "Скорость" },
        { id: 1, name: "minValue", text: "Вознаграждение" },
        { id: 2, name: "reward", text: "Уровень" },
      ],
      items: {
        headers: [
          { id: 0, text: "" },
          { id: 1, text: "I уровень" },
          { id: 2, text: "II уровень" },
          { id: 3, text: "III уровень" },
          { id: 4, text: "IV уровень" },
        ],
        body: [],
      },
      mainRules: [
        (v) => v.length !== 0 || "This field is required",
        (v) => v >= 0 || "This field should be positive",
      ],
    };
  },
  computed: {
    ...mapGetters({
      RATING_LEVELS: "Employee/RATING_LEVELS",
      RATING_LEVELS_V2: "Employee/RATING_LEVELS_V2",
      REVARDS: "Employee/REVARDS",
      USER: "User/STATE",
    }),
    isLevelsAreChanged() {
      return this.isEqual(
        this.items.body,
        this.ratingVersion === "v1" ? this.RATING_LEVELS : this.RATING_LEVELS_V2
      );
    },
  },
  watch: {
    ratingVersion() {
      this.showRevards = false;
      this.renderPage();
    },
    depId(val) {
      if (this.showRevards) {
        this.GET_REWARDS(val);
      } else {
        this.renderPage(true);
      }
    },
    revardModal(val) {
      if (!val) {
        this.saveMode = false;
        this.createData = {};
      }
    },
  },
  async created() {
    this.renderPage();
  },
  methods: {
    ...mapActions({
      GET_RATING_LEVELS: "Employee/GET_RATING_LEVELS",
      GET_RATING_LEVELS_V2: "Employee/GET_RATING_LEVELS_V2",
      CHANGE_RATING_LEVELS: "Employee/CHANGE_RATING_LEVELS",
      CHANGE_RATING_LEVELS_V2: "Employee/CHANGE_RATING_LEVELS_V2",
      GET_REWARDS: "Employee/GET_REWARDS",
    }),
    async renderPage(changeData) {
      this.loading = true;
      if (this.ratingVersion === "v1") {
        if (!this.RATING_LEVELS.length || changeData) {
          await this.GET_RATING_LEVELS();
        }
        this.items.body = JSON.parse(JSON.stringify(this.RATING_LEVELS));
      } else {
        if (!this.RATING_LEVELS_V2.length || changeData) {
          await this.GET_RATING_LEVELS_V2(this.depId);
        }
        this.items.body = JSON.parse(JSON.stringify(this.RATING_LEVELS_V2));
      }
      this.loading = false;
    },
    async openRevards() {
      await this.GET_REWARDS(this.depId);
      this.showRevards = true;
    },
    openRevardModal(revard) {
      this.createData = { ...revard };
      this.revardModal = true;
      this.saveMode = true;
    },
    async createRevard() {
      if (!this.$refs.revardForm.validate()) {
        ShowMessage("Заполните все поля");
        return;
      }
      this.createData.departmentId = this.depId;
      let response = await api.Employee.createRevard(this.createData);
      if (response.type === "error") {
        ShowMessage(response.data.message);
      } else {
        ShowMessage("Ставка создана", true);
        this.GET_REWARDS(this.depId);
        this.revardModal = false;
      }
    },
    async changeRevard() {
      if (!this.$refs.revardForm.validate()) {
        ShowMessage("Заполните все поля");
        return;
      }
      this.createData.departmentId = this.depId;
      let response = await api.Employee.changeRevard(this.createData);
      if (response.type === "error") {
        ShowMessage(response.data.message);
      } else {
        ShowMessage("Ставка изменена", true);
        this.GET_REWARDS(this.depId);
        this.revardModal = false;
      }
    },
    // функция для вывода названия роли, так как бек не может норм апи сделать
    getRoleName(roleId) {
      const role = this.roles.find((role) => role.id === roleId);
      if (role) {
        return role.name;
      }
    },
    /**
     * возвращает объект в котором определяется выбранный элемент(curr), его предыдущий и следующий
     * @param {number} value - значение, которое приходит из v-text-field
     * @param {string} ability - строка названия поля у объекта Level (minStamina, minSkill, minSpeed)
     */
    getObjectWithNamedLevels(value, ability) {
      let curr = this.items.body.find((item) => item[ability] === value);
      let prev = this.items.body.find((item) => item.level === curr.level - 1);
      let next = this.items.body.find((item) => item.level === curr.level + 1);
      return {
        prev: prev || false,
        curr: curr || false,
        next: next || false,
      };
    },
    /**
     *
     * @param {*} param0
     */
    getIsValueInRange({ curr, next, prev }) {
      if (curr <= prev || curr >= next || prev === next) {
        return false;
      }
      return true;
    },
    checkPassword() {
      // Проверка пароля
      if (this.password === "admin212") {
        this.showPasswordDialog = false;
      } else {
        ShowMessage("Введите верный пароль");
        this.password = "";
      }
    },
    openRaitingV2() {
      if (this.ratingVersion === "v2") {
        this.showPasswordDialog = true;
      }
    },
    cancelPassword() {
      // Отмена ввода пароля
      this.showPasswordDialog = false;
      this.ratingVersion = "v1";
    },
    isEqual(obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    },
    async createLevel() {
      if (!this.$refs.levelForm.validate()) {
        ShowMessage("Заполните все поля");
        return;
      }
      this.createData.departmentId = this.depId;
      let response = await api.Employee.createRatingLevel(this.createData);
      if (response.type === "error") {
        ShowMessage(response.data.message);
      } else {
        ShowMessage("Уровень создан", true);
        this.renderPage(true);
        this.levelModal = false;
      }
    },
    async onSubmit() {
      if (!this.form) return;
      this.loading = true;
      const listOfChangedLevels = this.getWhichLevelsWereChanged(
        this.items.body,
        this.ratingVersion === "v1" ? this.RATING_LEVELS : this.RATING_LEVELS_V2
      );
      for (let level of listOfChangedLevels) {
        if (this.ratingVersion === "v1") {
          await this.CHANGE_RATING_LEVELS(level);
        } else {
          level.departmentId = this.depId;
          await this.CHANGE_RATING_LEVELS_V2(level);
        }
      }
      await this.renderPage(true);
      ShowMessage(`Изменено уровней - ${listOfChangedLevels.length}`, true);
      this.loading = false;
    },
    getWhichLevelsWereChanged(currLevels, oldLevels) {
      let levelArray = [];
      for (let i = 0; i < currLevels.length; i++) {
        if (!this.isEqual(currLevels[i], oldLevels[i])) {
          levelArray.push(currLevels[i]);
        }
      }
      return levelArray;
    },
    getRules(text) {
      return (value) => {
        const { prev, curr, next } = this.getObjectWithNamedLevels(value, text);
        const isInRange = this.getIsValueInRange({
          curr: curr[text],
          prev: prev[text],
          next: next[text],
        });
        if (!isInRange) return "Must be in range of surround values";
        else return true;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.card-title {
  align-items: center;
  justify-content: space-between;
}
.input {
  max-width: 300px !important;
}
.hidden {
  display: none;
}
</style>
