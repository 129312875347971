<template>
  <v-dialog
    v-model="exelModal"
    width="80%"
    @click:outside="$emit('update:exelModal', false)"
  >
    <v-card class="modal" :dark="$dark.get()">
      <v-card-text class="modal__text">
        <loader v-if="loading" />
        <input
          ref="exel"
          type="file"
          hidden
          accept=".xlsx"
          @change="uploadExel($event)"
        />
        <!-- <input
          ref="csvFileWithProducts"
          type="file"
          hidden
          accept=".xlsx"
          @change="uploadProducts($event)"
        /> -->
        <input
          ref="imagesInput"
          type="file"
          multiple
          hidden
          accept="image/*"
          @change="uploadImages($event.target.files)"
        />
        <section class="btns">
          <div class="btns_column">
            <p>Работа с товарами</p>
            <div class="btns_box">
              <v-btn
                class="btns_btn"
                color="#5d4bd0"
                dark
                @click="setApi('importUpdateFile')"
              >
                Загрузить товары
                <v-icon right>mdi-tray-arrow-up</v-icon>
              </v-btn>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="#5d4bd0"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('insructions', 'products')"
                  >
                    <v-icon>mdi-information-variant-circle</v-icon>
                  </v-btn>
                </template>
                <span>Инcтрукция по загрузке</span>
              </v-tooltip>
            </div>
            <div class="btns_box">
              <v-btn
                class="btns_btn"
                color="#5d4bd0"
                dark
                @click="$emit('price-modal')"
              >
                Загрузить цены
              </v-btn>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="#5d4bd0"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('insructions', '')"
                  >
                    <v-icon>mdi-information-variant-circle</v-icon>
                  </v-btn>
                </template>
                <span>Инcтрукция по загрузке</span>
              </v-tooltip>
            </div>
            <div class="btns_box">
              <v-btn
                class="btns_btn"
                color="#5d4bd0"
                dark
                @click="setApi('uploadBarcodes')"
              >
                Проверка на ШК
              </v-btn>
            </div>
            <div class="btns_box">
              <v-btn
                class="btns_btn"
                color="#5d4bd0"
                dark
                @click="setApi('uploadDescription')"
              >
                Обновление описания
              </v-btn>
            </div>
          </div>
          <div class="btns_column">
            <p>Работа с картинками</p>

            <div class="btns_box">
              <v-btn
                class="btns_btn"
                color="warning"
                @click="$refs.imagesInput.click()"
              >
                Загрузить картинки на сервер
                <v-icon right>mdi-tray-arrow-up</v-icon>
              </v-btn>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="#5d4bd0"
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('insructions', 'img')"
                  >
                    <v-icon>mdi-information-variant-circle</v-icon>
                  </v-btn>
                </template>
                <span>Инcтрукция по загрузке</span>
              </v-tooltip>
            </div>
            <div class="btns_box">
              <v-btn
                class="btns_btn"
                color="#5d4bd0"
                dark
                @click="setApi('uploadImages')"
              >
                Загрузить картинки к товарам
              </v-btn>
            </div>
            <div class="btns_box">
              <v-btn
                class="btns_btn"
                color="#5d4bd0"
                dark
                @click="setApi('uploadImageToDelete')"
              >
                Удалить картинки
              </v-btn>
            </div>
          </div>
        </section>
      </v-card-text>
      <v-card-actions class="flex justify-end">
        <v-btn right @click="$emit('update:exelModal', false)">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import loader from "@/components/Loader";
import showMessage from "@/Functions/message";
import downloadFile from "@/Functions/downloadFile";
import api from "@/api";

export default {
  name: "ExelModal",
  components: {
    loader,
  },
  props: {
    exelModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      STATE: "Providers/STATE",
      CITY: "City/STATE",
      DEPARTMENT: "Department/STATE",
    }),
  },
  mounted() {},
  methods: {
    ...mapActions({
      getAllProviders: "Providers/GET_ALL_PROVIDERS",
    }),
    setApi(api) {
      this.$refs.exel.click();
      this.api = api;
    },
    async uploadImages(imageFiles) {
      let checkSize = false;
      this.loading = true;
      let data = new FormData();
      // Добавляем все выбранные файлы в FormData.
      for (let i = 0; i < imageFiles.length; i++) {
        if (imageFiles[i].size > 1000000) {
          showMessage("Размер файла превышает 1мб");
          checkSize = true;
          this.loading = false;
          return;
        }
        data.append("file", imageFiles[i]);
      }
      if (checkSize) {
        return;
      }
      let request = {
        files: data,
        providerId: this.$route.params.id,
      };
      let response = await api.Providers.importImages(request);
      if (response?.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage();
        this.$emit("refresh");
      }
      // Очищаем выбранные файлы
      this.$refs.imagesInput.value = "";
      this.loading = false;
    },
    async uploadExel(file) {
      this.loading = true;
      let data = new FormData();
      data.append("file", file.target.files[0]);
      let request = data;
      if (this.api === "importUpdateFile") {
        request = {
          file: data,
          providerId: this.$route.params.id,
        };
      }
      let response = await api.Providers[this.api](request);
      // если ошибка в файле, то приходит файл с ошибками который нужно скачать
      if (response.fileName) {
        showMessage(
          "Ошибка при загрузке файла, подробную информацию вы можете посмотреть в прилагаемом файле"
        );
        downloadFile(response.fileName);
      } else if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage();
        this.$emit("refresh");
      }
      this.$refs.exel.value = "";
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.btns {
  display: flex;
  gap: 20px;
  &_column {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 10px;
    @media screen and (max-width: 860px) {
      width: 100%;
    }
  }
  &_box {
    width: 100%;
  }
  &_btn {
    width: 85%;
    font-size: 10px;
  }
  @media screen and (max-width: 860px) {
    flex-direction: column;
  }
}
</style>
