<template>
  <v-card :dark="$dark.get()">
    <loader v-if="loading" />
    <div class="custom-card">
      <div class="custom-card__title">
        <v-btn color="#5d4bd0" dark @click="$router.go(-1)">
          <v-icon left>mdi-arrow-left-circle</v-icon>
          вернуться
        </v-btn>
        <p class="custom-card__text">Список лимитов</p>
        <v-btn color="#5d4bd0" dark @click="setCreateMode()">
          Создать лимит
        </v-btn>
      </div>
      <p v-if="departments.length === 0">
        У данного поставщика нет цен на филиалах
      </p>
      <p v-if="noLimits">У данного поставщика пока нет лимитов</p>
      <div v-else class="custom-card__container">
        <div class="custom-table">
          <div class="head">
            <div
              v-for="item in items.head"
              :key="item.id"
              class="head__item"
              :class="item.id === 1 && 'img'"
            >
              {{ item.name }}
            </div>
          </div>
          <div v-for="item in items.body" :key="item.propertyId" class="value">
            <div class="value__item_img">
              {{ item.propertyId }}
            </div>
            <div class="value__item">
              {{ item.departmentName }}
            </div>
            <div class="value__item">{{ item.minOrderSum }} тг</div>
            <div class="value__item">
              <v-btn icon color="#5d4bd0" @click="setCurrentLimit(item)">
                <v-icon>mdi-lead-pencil</v-icon>
              </v-btn>
              <v-btn icon color="error" @click="setCurrentLimit(item, true)">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <limit-modal
      :show-modal.sync="modal"
      :limit="currentLimit"
      :edit-mode="editMode"
      :departments="departments"
      @createLimit="createLimit()"
      @editLimit="editLimit()"
    />
    <delete-dialog
      :show="show"
      @close-modal="show = !show"
      @delete-modal="deleteLimit()"
    />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import loader from "@/components/Loader";
import DeleteDialog from "@/components/DeleteDialog";
import LimitModal from "@/components/Limits/LimitModal";
// Вызывает сообщение об успешной операции, либо ошибку если введен текст
import showMessage from "@/Functions/message";
import api from "@/api";

export default {
  components: {
    loader,
    DeleteDialog,
    LimitModal,
  },
  data() {
    return {
      loading: false,
      noLimits: false,
      modal: false,
      show: false,
      providerName: "",
      items: {
        head: [
          { id: 1, name: "ID" },
          { id: 2, name: "Филиал" },
          { id: 3, name: "Сумма лимита" },
          { id: 4, name: "Действия" },
        ],
        body: [],
      },
      departments: [],
      currentLimit: {},
      editMode: false,
    };
  },
  computed: {
    ...mapGetters({
      STATE: "Providers/STATE",
      CITY: "City/STATE",
      DEPARTMENT: "Department/STATE",
    }),
  },
  mounted() {
    this.setDepartmets();
    this.setValues();
  },
  methods: {
    ...mapActions({
      getAllProviders: "Providers/GET_ALL_PROVIDERS",
      getProviderProducts: "Providers/GET_PROVIDER_PRODUCTS",
      deleteProviderAction: "Providers/DELETE_PROVIDER",
      getDepartment: "Department/GET_DEPARTMENT",
    }),
    //Подгружает все лимиты
    async setValues() {
      this.loading = true;
      let limitsResp = await api.Providers.getAyanLimits();
      if (limitsResp?.type === "error") {
        this.noLimits = true;
      } else {
        this.noLimits = false;
        this.items.body = limitsResp;
      }
      this.loading = false;
    },
    async setDepartmets() {
      this.loading = true;
      await this.getDepartment(this.$root.city);
      // Привожу к одному виду филиалы
      this.departments = this.DEPARTMENT.cityDepartment.map((dep) => {
        let obj = {
          departmentId: dep.id,
          departmentName: dep.name,
        };
        return obj;
      });
      this.loading = false;
    },
    setCreateMode() {
      this.modal = !this.modal;
      this.editMode = false;
      this.currentLimit = {};
    },
    async createLimit() {
      if (!this.currentLimit.departmentId || !this.currentLimit.minOrderSum) {
        showMessage("Заполните все поля");
        return;
      }
      this.loading = true;
      let body = {
        providerId: this.$route.params.id,
        departmentId: this.currentLimit.departmentId,
        minOrderSum: this.currentLimit.minOrderSum,
      };
      const response = await api.Providers.addLimitToAyan(body);
      if (response?.type === "error") {
        showMessage(response.data.message);
      }
      this.setValues();
      this.modal = !this.modal;
      this.currentLimit = {};
    },
    setCurrentLimit(limit, delMode) {
      this.currentLimit = limit;
      if (delMode) {
        this.show = true;
      } else {
        this.editMode = true;
        this.modal = !this.modal;
      }
    },
    async deleteLimit() {
      this.loading = true;
      const response = await api.Providers.deleteLimitToAyan([
        this.currentLimit.providerOrderLimitId || this.currentLimit.propertyId,
      ]);
      if (response?.type === "error") {
        showMessage(response.data.message);
      } else {
        this.currentLimit = {};
        this.show = false;
        this.setValues();
      }
    },
    async editLimit() {
      if (!this.currentLimit.minOrderSum) {
        showMessage("Сумма лимита не должна быть пустой");
        return;
      }
      this.loading = true;
      let body = {
        propertyId: this.currentLimit.propertyId,
        providerOrderLimitId: this.currentLimit.providerOrderLimitId,
        minOrderSum: this.currentLimit.minOrderSum,
      };
      const response = await api.Providers.editLimitToAyan(body);
      if (response?.type === "error") {
        showMessage(response.data.message);
      } else {
        this.currentLimit = {};
        this.modal = !this.modal;
        this.setValues();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-card {
  padding: 40px;
  position: relative;
  &__btn {
    width: 80%;
  }
  &__title {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__btns-block {
    display: flex;
    flex-direction: column;
    gap: 5px;
    &_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__text {
    margin: 0;
  }

  .custom-card__container {
    .custom-table {
      .head {
        display: flex;
        justify-content: space-between;
        &__item {
          text-align: left;
          font-weight: 500;
          width: 25%;
          text-align: center;
        }
      }
      .img {
        width: 5%;
        text-align: center;
      }
      .value {
        display: flex;
        justify-content: space-between;
        &__item {
          width: 25%;
          display: flex;
          align-items: center;
          justify-content: center;
          &_img {
            width: 5%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        &__btn {
          width: 100%;
        }
      }
    }
  }
}
.modal {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__select {
    max-width: 60%;
    margin-right: 20px;
  }
  &__title {
    text-align: center;
    font-size: large;
  }
  &__text {
    padding: 0;
    margin: 0;
  }
  &__li {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
  }
}
</style>
