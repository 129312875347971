<template>
  <section>
    <div class="header">
      <h1>Теги для чата</h1>
      <v-btn color="#5d4bd0" dark small @click="openModal('tag')">
        Добавить тэг
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>

    <section class="conteiner">
      <div class="conteiner_item">
        <p>Теги</p>
        <div
          v-for="item in TAGS"
          :key="item.id"
          class="conteiner_tag"
          :class="activeTag === item.id && 'active'"
          @click="
            questions = item.children;
            activeTag = item.id;
          "
        >
          {{ item.name }}
          <div>
            <v-btn icon small @click="deleteItem(item.id, 'tag')">
              <v-icon color="error">mdi-trash-can</v-icon>
            </v-btn>
            <v-btn icon small @click="openModal('question')">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>
      </div>

      <div class="conteiner_item other">
        <div class="conteiner_item__title">
          <p>Вопросы</p>
          <p>Ответы</p>
        </div>
        <div
          v-for="question in questions"
          :key="question.id"
          class="conteiner_box"
        >
          <div class="conteiner_tag answer">
            {{ question.name }}
            <div>
              <v-btn icon small @click="deleteItem(question.id, 'question')">
                <v-icon color="error">mdi-trash-can</v-icon>
              </v-btn>
              <v-btn icon small @click="openModal('answer', question.id)">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </div>
          <div v-if="question.answer" class="conteiner_tag answer">
            {{ question.answer }}
            <v-btn icon small @click="deleteItem(question.id, 'answer')">
              <v-icon color="error">mdi-trash-can</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </section>

    <v-dialog v-model="modal" activator="parent" width="60%">
      <v-card class="modal">
        <v-card-title class="modal_title"> Добавление </v-card-title>
        <v-form ref="form">
          <v-text-field
            v-model="newTag.title"
            :rules="titleRules"
            label="Название"
            required
          ></v-text-field>
          <div v-if="mode === 'tag'">
            <v-text-field
              v-for="(item, index) in newTag.questions"
              :key="index"
              v-model="newTag.questions[index]"
              :rules="titleRules"
              label="Вопрос"
              required
            ></v-text-field>
            <v-btn ccolor="#5d4bd0" dark @click="newTag.questions.push('')"
              >Добавить вопрос</v-btn
            >
          </div>
        </v-form>

        <v-card-actions class="modal_actions">
          <v-btn color="#5d4bd0" dark @click="addNew(mode)">Добавить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import showMessage from "@/Functions/message";
import api from "@/api";

export default {
  data() {
    return {
      modal: false,
      questions: [],
      answers: [],
      newTag: {
        title: "",
        questions: [""],
      },
      activeTag: 0,
      activeQuestion: 0,
      mode: "tag",
      titleRules: [
        (value) => {
          if (value) return true;
          return "Введите тэг";
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      TAGS: "Chat/STATE",
    }),
  },
  watch: {
    modal(newVal) {
      if (!newVal) {
        this.newTag.title = "";
        this.newTag.questions = [];
        this.$refs.form.resetValidation();
      }
    },
  },
  mounted() {
    this.setValues();
  },
  methods: {
    ...mapActions({
      GET_TAGS: "Chat/GET_TAGS",
      ADD_TAG: "Chat/ADD_TAG",
    }),
    //Подгружает все тэги
    async setValues() {
      await this.GET_TAGS();
      if (this.activeTag) {
        this.questions = this.TAGS.find(
          (tag) => tag.id === this.activeTag
        ).children;
      } else {
        this.questions = [];
      }
    },
    async deleteItem(id, mode) {
      let response;
      if (mode === "tag") {
        response = await api.Chat.deleteTag(id);
        this.activeTag = 0;
      }
      if (mode === "question") {
        response = await api.Chat.deleteQuestion(id);
      }
      if (mode === "answer") {
        response = await api.Chat.deleteAnswer(id);
      }
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        this.resetValues();
        this.setValues();
      }
    },
    async addNew(mode) {
      if (this.validateModal()) {
        let response;
        if (mode === "tag") {
          let resp = {
            nameTag: this.newTag.title.trim(),
            questions: this.newTag.questions,
          };
          response = await api.Chat.addTag(resp);
        }
        if (mode === "question") {
          let resp = {
            question: this.newTag.title.trim(),
            tagId: this.activeTag,
          };
          response = await api.Chat.addQuestion(resp);
        }
        if (mode === "answer") {
          let resp = {
            answer: this.newTag.title.trim(),
            questionId: this.activeQuestion,
          };
          response = await api.Chat.addAnswer(resp);
        }

        if (response.type === "error") {
          showMessage(response.data.message);
        } else {
          this.resetValues();
          this.setValues();
        }
      }
    },
    //Открывает модалку с данными из выбранного тега
    openModal(mode, id) {
      this.mode = mode;
      this.modal = true;
      this.activeQuestion = id;
    },
    resetValues() {
      showMessage();
      this.newTag.title = "";
      this.modal = false;
    },
    //Вспомогательная функция, проверяет нет ли пустых данных в форме
    validateModal() {
      if (this.newTag.title) {
        return true;
      } else {
        showMessage("Заполните все поля");
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.conteiner {
  padding-left: 20px;
  margin-top: 20px;
  display: flex;
  gap: 30px;
  &_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    &__title {
      display: flex;
      gap: 30px;
      justify-content: space-around;
      width: 100%;
    }
  }
  &_box {
    display: flex;
    gap: 30px;
    width: 100%;
  }
  &_tag {
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 5px 5px 5px 20px;
    border-radius: 8px;
    background-color: #f2f4fa;
    cursor: pointer;
  }
}
.answer {
  width: 50%;
}
.other {
  align-items: start;
}
.active {
  background-color: bisque;
}
.header {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding-left: 50px;
}
.modal {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &_title {
    justify-content: center;
  }
  &__text {
    padding: 0;
    margin: 0;
  }
  &_actions {
    justify-content: right;
  }
}
</style>
