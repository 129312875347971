<template>
  <section>
    <div class="header">
      <div class="header_title">
        <h1>Теги для витрины</h1>
      </div>
    </div>
    <div class="btns">
      <v-btn color="#5d4bd0" dark @click="modal = true">
        Добавить тэг
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <loader v-if="loading" />
    <div class="conteiner">
      <v-card v-for="tag in TAGS" :key="tag.id" class="conteiner__tag">
        <v-card-text class="pb-0">
          <p class="conteiner__tag_title">{{ tag.name }}</p>
          <p v-if="tag.description">Описание : {{ tag.description }}</p>
          <p v-if="tag.category">Категория : {{ tag.category.name }}</p>
          <p v-if="tag.department">Филиал : {{ tag.department.name }}</p>
          <p v-if="tag.pageLink">Ссылка : {{ tag.pageLink }}</p>
          <p v-if="tag.product">Продукт : {{ tag.product.name }}</p>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-btn
            icon
            @click="
              showDelete = true;
              tagId = tag.id;
            "
          >
            <v-icon color="error" class="conteiner_icon">mdi-trash-can</v-icon>
          </v-btn>
          <v-btn icon @click="openModalToRedact(tag)">
            <v-icon color="#5d4bd0" class="conteiner_icon"
              >mdi-lead-pencil</v-icon
            >
          </v-btn>
        </v-card-actions>
      </v-card>
      <!-- создание изменение тега -->
      <v-dialog v-model="modal" activator="parent" width="60%">
        <v-card class="modal">
          <v-card-title class="modal_title"
            >{{ isRedacting ? "Изменение " : "Добавление " }} тега</v-card-title
          >
          <v-form ref="vitrinModal">
            <v-text-field
              v-model="newTag.name"
              :rules="[$validate.required]"
              label="Название"
              required
            ></v-text-field>

            <v-text-field
              v-model="newTag.description"
              :rules="[$validate.required]"
              label="Описание"
              required
            ></v-text-field>

            <v-text-field
              v-model="newTag.productId"
              label="ID продукта"
              @change="changeField('productId')"
            ></v-text-field>

            <v-text-field
              v-model="newTag.pageLink"
              label="Ссылка"
              @change="changeField('pageLink')"
            ></v-text-field>

            <v-select
              v-model="newTag.departmentId"
              label="Филиал"
              :items="DEPARTMENT.cityDepartment"
              item-text="name"
              item-value="id"
              @change="changeField('departmentId')"
            ></v-select>

            <v-autocomplete
              v-model="newTag.categoryId"
              :items="CATEGORY"
              item-text="name"
              item-value="id"
              label="Категория"
              @change="changeField('categoryId')"
            />

            <v-select
              v-model="newTag.cityIds"
              label="Города"
              multiple
              :items="CITY.allCity"
              item-text="name"
              item-value="id"
            ></v-select>
          </v-form>

          <v-card-actions class="modal_actions">
            <v-btn @click="modal = false">Закрыть</v-btn>
            <v-btn
              color="#5d4bd0"
              dark
              @click="isRedacting ? changeTag() : addNewTag()"
              >{{ isRedacting ? "Изменить" : "Добавить" }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Подтвердение удаления -->
      <delete-dialog
        :show="showDelete"
        @close-modal="showDelete = !showDelete"
        @delete-modal="deleteTag()"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import showMessage from "@/Functions/message";
import DeleteDialog from "@/components/DeleteDialog";
import loader from "@/components/Loader";
import api from "@/api";

export default {
  components: { DeleteDialog, loader },
  data() {
    return {
      loading: false,
      showDelete: false,
      modal: false,
      redactingTagId: null,
      isRedacting: false,
      tagId: 0,
      newTag: {},
      titleRules: [
        (value) => {
          if (value) return true;
          return "Введите тэг";
        },
        (value) => {
          if (value?.length <= 20) return true;
          return "Слишком длинный тэг";
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      TAGS: "VitrinTags/STATE",
      CITY: "City/STATE",
      USER: "User/STATE",
      DEPARTMENT: "Department/STATE",
      CATEGORY: "Category/PRODUCTS_CATEGORY",
    }),
  },
  watch: {
    modal(newVal) {
      if (!newVal) {
        this.isRedacting = false;
        this.newTag = {};
        this.$refs.vitrinModal.resetValidation();
      }
    },
  },
  mounted() {
    if (!this.CATEGORY.length) {
      this.getCategory();
    }
    this.setValues();
  },
  methods: {
    ...mapActions({
      getAllTags: "VitrinTags/GET_ALL_TAGS",
      getCategory: "Category/GET_PRODUCTS_CATEGORY",
    }),
    //Подгружает все тэги
    async setValues() {
      this.loading = true;
      await this.getAllTags();
      this.loading = false;
    },
    async deleteTag() {
      const response = await api.VitrinTags.deleteTag(this.tagId);
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("Тег удален", true);
        this.setValues();
      }
      this.showDelete = false;
    },
    async addNewTag() {
      if (!this.$refs.vitrinModal.validate()) {
        showMessage("Не все поля заполнены");
        return;
      }
      const response = await api.VitrinTags.createTag(this.newTag);
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        this.setValues();
      }
      this.modal = false;
    },
    //Открывает модалку с данными из выбранного тега
    openModalToRedact(tagObj) {
      this.newTag.id = tagObj.id;
      this.newTag.name = tagObj.name;
      this.newTag.description = tagObj.description;
      this.newTag.productId = tagObj.product?.id || null;
      this.newTag.categoryId = tagObj.category?.id || null;
      this.newTag.departmentId = tagObj.department?.id || null;
      this.newTag.pageLink = tagObj.pageLink;
      this.newTag.cityIds = tagObj.cityIds;
      this.isRedacting = true;
      this.modal = true;
    },
    async changeTag() {
      if (!this.$refs.vitrinModal.validate()) {
        showMessage("Не все поля заполнены");
        return;
      }
      const response = await api.VitrinTags.changeTag(this.newTag);
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("Тег успешно изменен", true);
        this.setValues();
      }
      this.isRedacting = false;
      this.modal = false;
    },

    //Вспомогательная функция, проверяет нет ли пустых данных в форме
    changeField(name) {
      const arr = ["productId", "categoryId", "departmentId", "pageLink"];
      arr.forEach((item) => {
        if (item !== name) {
          this.newTag[item] = null;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btns {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}
.conteiner {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  &__header {
    padding-top: 10px;
    font-size: 20px;
    font-weight: bold;
  }
  &__tag {
    padding: 5px 10px;
    border-radius: 10px;
    border: 1px solid #5d4bd0;
    box-sizing: border-box;
    width: calc(25% - 10px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &_title {
      font-size: large;
      font-weight: bold;
    }
  }
  &_icon:hover {
    color: black !important;
  }
}
.modal {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &_title {
    justify-content: center;
  }
  &__text {
    padding: 0;
    margin: 0;
  }
  &_actions {
    justify-content: right;
  }
  &__checkboxes {
    display: flex;
    gap: 30px;
    @media screen and (max-width: 700px) {
      flex-wrap: wrap;
    }
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &_title {
    width: 90%;
  }
}
</style>
