var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"printSection"}},[_c('v-simple-table',{attrs:{"fixed-header":"","height":"78.5vh"}},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"40px","padding":"0 3px !important"}},[_vm._v("Сотрудник")]),_vm._l((_vm.days),function(day,index){return _c('th',{key:day + ' ' + index,staticClass:"cell text-center",style:({
            color: day == 'Сб' || day == 'Вс' ? 'red' : '',
          })},[_vm._v(" "+_vm._s(day + " " + (index + 1))+" ")])}),_c('th',{staticClass:"cell"},[_vm._v("Итого")])],2),_c('tr',[_c('th',{staticClass:"text-left cell"},[_vm._v("Слоты")]),_vm._l((_vm.slots),function(slot){return _c('th',{key:slot.date,staticClass:"cell"},[_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){return _vm.openSlotModal(slot)}}},[_vm._v(_vm._s(_vm.getCount(slot)))])],1)})],2)]),_c('tbody',[_c('tr',{style:({
          display: _vm.role == 0 || _vm.role == 1 ? '' : 'none',
        })},[_c('td',{staticClass:"text-center",attrs:{"colspan":_vm.days.length + 1}},[_vm._v("Курьеры")])]),_vm._l((_vm.couriers),function(item){return _c('tr',{key:'curier_' + item.employee.id,style:({
          display: _vm.role == 0 || _vm.role == 1 ? '' : 'none',
        })},[_c('td',{staticClass:"cell"},[_c('div',{staticClass:"name-cell"},[_c('span',[_vm._v(_vm._s(item.employee.username))]),_c('div',{staticClass:"name-cell_btns"},[_c('v-btn',{attrs:{"icon":"","color":"#5d4bd0"},on:{"click":function($event){return _vm.$router.push('/employee-profile/' + item.employee.id)}}},[_c('v-icon',[_vm._v("mdi-account-circle")])],1),_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":function($event){_vm.showPlan = true;
                  _vm.choosenEmloyee = item.employee;}}},[_c('v-icon',[_vm._v("mdi-plus-circle")])],1)],1)])]),_vm._l((_vm.days),function(day,index){return _c('td',{key:'day_cur_' + index,staticClass:"text-center hover_edge cell",style:({
            background: day == 'Сб' || day == 'Вс' ? '#ffdada' : '',
            color: day == 'Сб' || day == 'Вс' ? 'black' : '',
            border:
              _vm.$dark.get() === true
                ? 'thin solid rgba(255, 255, 255, 0.3)'
                : 'thin solid rgba(0, 0, 0, 0.12)',
          }),on:{"click":function($event){_vm.dialog = true;
            _vm.shift = item.shift[index] || item;
            _vm.getDay(index);}}},[(item.shift[index])?_c('div',{class:_vm.getShiftStatusClass(item.shift[index].shiftStatus.id)},[_vm._v(" "+_vm._s(item.shift[index].hours || 0)+"ч. "+_vm._s(item.shift[index].minutes || 0)+"м. ")]):_vm._e()])}),_c('td',{style:({
            border:
              _vm.$dark.get() === true
                ? 'thin solid rgba(255, 255, 255, 0.3)'
                : 'thin solid rgba(0, 0, 0, 0.12)',
          })},[_vm._v(" "+_vm._s(item.result.hours)+"ч. "+_vm._s(item.result.minutes)+"м. ")])],2)}),_c('tr',{style:({
          display: _vm.role == 0 || _vm.role == 2 ? '' : 'none',
        })},[_c('td',{staticClass:"text-center",attrs:{"colspan":_vm.days.length + 1}},[_vm._v("Сборщики")])]),_vm._l((_vm.pickers),function(item){return _c('tr',{key:'picker_' + item.employee.id,style:({
          display: _vm.role == 0 || _vm.role == 2 ? '' : 'none',
        })},[_c('td',{staticClass:"cell"},[_c('div',{staticClass:"name-cell"},[_c('span',[_vm._v(_vm._s(item.employee.username))]),_c('div',{staticClass:"name-cell_btns"},[_c('v-btn',{attrs:{"icon":"","color":"#5d4bd0"},on:{"click":function($event){return _vm.$router.push('/employee-profile/' + item.employee.id)}}},[_c('v-icon',[_vm._v("mdi-account-circle")])],1),_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":function($event){_vm.showPlan = true;
                  _vm.choosenEmloyee = item.employee;}}},[_c('v-icon',[_vm._v("mdi-plus-circle")])],1)],1)])]),_vm._l((_vm.days),function(day,index){return _c('td',{key:'day_pic_' + index,staticClass:"text-center hover_edge cell",style:({
            background: day == 'Сб' || day == 'Вс' ? '#ffdada' : '',
            color: day == 'Сб' || day == 'Вс' ? 'black' : '',
            border:
              _vm.$dark.get() === true
                ? 'thin solid rgba(255, 255, 255, 0.3)'
                : 'thin solid rgba(0, 0, 0, 0.12)',
          }),on:{"click":function($event){_vm.dialog = true;
            _vm.shift = item.shift[index] || item;
            _vm.getDay(index);}}},[(item.shift[index])?_c('div',{class:_vm.getShiftStatusClass(item.shift[index].shiftStatus.id)},[_vm._v(" "+_vm._s(item.shift[index].hours || 0)+"ч. "+_vm._s(item.shift[index].minutes || 0)+"м. ")]):_vm._e()])}),_c('td',{style:({
            border:
              _vm.$dark.get() === true
                ? 'thin solid rgba(255, 255, 255, 0.3)'
                : 'thin solid rgba(0, 0, 0, 0.12)',
          })},[_vm._v(" "+_vm._s(item.result.hours)+"ч. "+_vm._s(item.result.minutes)+"м. ")])],2)})],2)]),_c('schedule-dialog',{attrs:{"dialog":_vm.dialog,"shift":_vm.shift,"input":_vm.input,"shift-date":_vm.shiftDate},on:{"close-modal":function($event){_vm.dialog = false},"rerender":function($event){return _vm.$emit('rerender')}}}),_c('SlotModal',{attrs:{"dialog":_vm.showSlot,"data":_vm.editedSlot,"dep":_vm.input.departmentId},on:{"update:dialog":function($event){_vm.showSlot=$event},"rerender":function($event){return _vm.$emit('rerender')}}}),_c('PlanModal',{attrs:{"modal":_vm.showPlan,"employee":_vm.choosenEmloyee,"dep":_vm.input.departmentId,"date-prop":[_vm.slots[0].date, _vm.slots.slice(-1)[0].date]},on:{"update:modal":function($event){_vm.showPlan=$event},"rerender":function($event){return _vm.$emit('rerender')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }