<template>
  <section>
    <loader v-if="loading" />
    <div class="header">
      <div class="header_title">
        <h1>
          {{ isApproved ? "Подтвержденные отзывы" : "Неподтвержденные отзывы" }}
        </h1>
      </div>

      <v-switch
        v-model="isApproved"
        hide-details
        :label="
          isApproved ? 'Подтвержденные отзывы' : 'Неподтвержденные отзывы'
        "
      ></v-switch>
    </div>
    <!-- основная таблица -->
    <v-simple-table :dark="$dark.get()">
      <thead>
        <tr>
          <th v-for="title in head" :key="title" class="text-left">
            {{ title }}
          </th>
        </tr>
      </thead>
      <!-- тело таблицы -->
      <tbody>
        <tr v-for="rev in allRevievs" :key="rev.providerProductReviewId">
          <td>{{ rev.clientName }}</td>
          <td>{{ rev.description }}</td>
          <td>
            <v-rating
              :value="rev.valueRating"
              half-increments
              readonly
              length="5"
              color="orange lighten-3"
              :size="16"
              background-color="#050505"
              class="rate-stars"
            />
          </td>
          <td>{{ rev.images.length ? "Да" : "Нет" }}</td>
          <td>
            <v-btn icon color="primary" @click="openModal(rev)">
              <v-icon>mdi-information-slab-circle</v-icon>
            </v-btn>
          </td>
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="#5d4bd0"
                  v-bind="attrs"
                  v-on="on"
                  @click="approve(rev.providerProductReviewId)"
                >
                  <v-icon>{{
                    isApproved ? "mdi-thumb-down" : "mdi-thumb-up"
                  }}</v-icon>
                </v-btn>
              </template>
              <span>{{
                isApproved ? "Отменить подтверждение" : "Подтвердить отзыв"
              }}</span>
            </v-tooltip>
          </td>
          <td>
            <v-btn
              icon
              color="error"
              @click="deleteReview(rev.providerProductReviewId)"
            >
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <!-- пагинация -->
    <v-pagination
      v-if="totalPages > 1"
      :value="payload.page + 1"
      total-visible="7"
      :length="totalPages"
      :dark="$dark.get()"
      color="#5d4bd0"
      @input="setValues($event)"
    ></v-pagination>
    <!-- Окно с полной информацией -->
    <v-dialog v-model="modal" activator="parent" width="60%">
      <v-card class="modal">
        <v-card-title class="modal_title">Информация об отзыве</v-card-title>
        <v-card-text v-if="shovedReview">
          <p>
            <span class="modal_bold">Имя пользователя :</span>
            {{ shovedReview.clientName }}
          </p>
          <p>
            <span class="modal_bold">Дата добавления :</span>
            {{ getDate(shovedReview.dateAdd) }}
          </p>
          <p>
            <span class="modal_bold">Текст комментария :</span>
            {{ shovedReview.description }}
          </p>
          <p>
            <span class="modal_bold">Название продукта :</span>
            {{ shovedReview.providerProductName }}
          </p>
          <p>
            <span class="modal_bold">Оценка :</span>
            {{ shovedReview.valueRating }}
          </p>
          <!-- блок картинок -->
          <div v-if="shovedReview.images.length" class="modal_images">
            <p class="modal_bold">Прикрепленные фото</p>
            <img
              v-for="img in shovedReview.images"
              :key="img.id"
              :src="img.fileUrl"
              :alt="img.alt"
              width="100%"
            />
          </div>
        </v-card-text>
        <v-card-actions class="modal_actions">
          <v-btn
            color="error"
            @click="
              deleteReview(shovedReview.providerProductReviewId);
              modal = false;
            "
            >Удалить</v-btn
          >
          <v-btn
            color="#5d4bd0"
            dark
            @click="
              approve(shovedReview.providerProductReviewId);
              modal = false;
            "
            >Подтвердить</v-btn
          >
          <v-btn @click="modal = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Подтвердение удаления -->
    <delete-dialog
      :show="show"
      @close-modal="show = !show"
      @delete-modal="deleteReview()"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import showMessage from "@/Functions/message";
import DeleteDialog from "@/components/DeleteDialog";
import loader from "@/components/Loader";
import moment from "moment";
import api from "@/api";

export default {
  components: {
    loader,
    DeleteDialog,
  },
  data() {
    return {
      payload: {
        page: 0,
        size: 13,
      },
      head: ["Имя", "Текст", "Оценка", "Фото", "Инфо", "Действие", "Удалить"],
      allRevievs: [],
      isApproved: false,
      modal: false,
      totalPages: 0,
      show: false,
      deletedId: 0,
      loading: false,
      shovedReview: null,
    };
  },
  computed: {
    ...mapGetters({
      STATE: "Reviews/STATE",
    }),
  },
  watch: {
    isApproved() {
      this.setValues(1);
    },
  },
  mounted() {
    this.setValues();
  },
  methods: {
    ...mapActions({
      GET_ALL_APPROVED: "Reviews/GET_ALL_APPROVED",
      GET_ALL_NOTAPPROVED: "Reviews/GET_ALL_NOTAPPROVED",
    }),
    //Подгружает все тэги
    async setValues(page) {
      if (page) {
        this.payload.page = page - 1;
      }
      if (this.isApproved) {
        await this.GET_ALL_APPROVED(this.payload);
        this.allRevievs = this.STATE.allApproved.content;
        this.totalPages = this.STATE.allApproved.totalPages;
      } else {
        await this.GET_ALL_NOTAPPROVED(this.payload);
        this.allRevievs = this.STATE.allNotapproved.content;
        this.totalPages = this.STATE.allNotapproved.totalPages;
      }
    },
    openModal(review) {
      this.shovedReview = review;
      this.modal = true;
    },
    getDate(number) {
      moment.locale("ru");
      const date = moment(number).format("LL");
      return date;
    },
    async approve(id) {
      this.loading = true;
      let response;
      if (this.isApproved) {
        response = await api.Reviews.disapproveReview(id);
      } else {
        response = await api.Reviews.approveReview(id);
      }
      if (response?.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage(
          this.isApproved
            ? "У отзыва снято подтверждение"
            : "Отзыв подтвержден",
          true
        );
      }
      await this.setValues();
      this.loading = false;
    },
    async deleteReview(id) {
      if (id) {
        this.deletedId = id;
      } else {
        this.loading = true;
        let response = await api.Reviews.deleteReview(this.deletedId);
        if (response?.type === "error") {
          showMessage(response.data.message);
        } else {
          showMessage("Отзыв удален", true);
        }
        this.deletedId = 0;
        await this.setValues();
        this.loading = false;
      }
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss">
.modal {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &_title {
    justify-content: center;
  }
  &__text {
    padding: 0;
    margin: 0;
  }
  &_actions {
    justify-content: right;
  }
  &_bold {
    font-weight: 800;
  }
  &_images {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &_title {
    width: 90%;
    h1 {
      margin-left: 10%;
    }
  }
}
.rate-stars {
  button {
    padding: 0 !important;
  }
  width: 90px;
}
</style>
