<template>
  <v-dialog
    v-model="instructionsModal"
    width="70%"
    @click:outside="$emit('update:instructionsModal', false)"
  >
    <v-card class="modal" :dark="$dark.get()">
      <v-card-text>
        <p class="text-h5 text-center">Инструкция по загрузке</p>
        <ol v-if="instructionType === 'img'">
          <li>
            В появившемя окне, выберите папку где хранятся ваши картинки,
            выберите все неоходимые
          </li>
          <li>Загрузите все картинки на сервер</li>
          <li>Размер картинок не должен превышать 1mb</li>
        </ol>
        <ol v-else>
          <li>Сначала необходимо загрузить список картинок!</li>
          <li>Файл нужно загружать в формате .XLSX</li>
          <li v-if="instructionType === 'products'">
            В файле есть 6 столбцов
            <ol type="a">
              <li>name - Название вашего продукта</li>
              <li>measure - Единица измерения продукта</li>
              <li>barcode - Штрих-код продукта</li>
              <li>shortDescription - Краткое описание продукта</li>
              <li>description - Полное описание продукта</li>
              <li>img - Имя картинки для продукта который вы загрузили</li>
            </ol>
          </li>
          <li v-else>
            В файле есть 4 столбца
            <ol type="a">
              <li>SkuPrice - Оставить пустым</li>
              <li>barcode - Штрих-код вашего продукта</li>
              <li>price - Цена продукта для данного филиала</li>
              <li>balance - Количество позиций вашего продукта</li>
            </ol>
          </li>
          <li>Не оставляйте поля пустыми</li>
          <li>
            Если файл загружен успешно появится уведомление и список товаров
            обновится
          </li>
        </ol>
      </v-card-text>
      <v-card-actions class="flex justify-end">
        <v-btn right @click="$emit('update:instructionsModal', false)"
          >Закрыть</v-btn
        >
        <v-btn
          right
          color="#5d4bd0"
          dark
          @click="$emit('update:instructionsModal', false)"
          >Прочитано</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "InsructionDialog",
  props: {
    instructionsModal: {
      type: Boolean,
      default: false,
    },
    instructionType: {
      type: String,
      default: "",
    },
  },
};
</script>
