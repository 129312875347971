// title - заголовки
// titleList - массив подзаголовков
// subtitle - подзаголовки
// info - массив данных в подзаголовках, показывается слева
// bold - номер слова в поле text которое будет выделено жирным
const data = [
  {
    title: "Личные данные",
    titleList: [
      {
        subtitle: "Профиль",
        info: [
          {
            bold: [],
            text: "В профиле указывается:",
          },
          {
            bold: [],
            text: "•	Название Вашего юридического лица;",
          },
          {
            bold: [],
            text: "•	Дата создания профиля;",
          },
          {
            bold: [],
            text:
              "•	Информация о наличии своей доставки, а также наличие доставки и складов от Аяна;",
          },
          {
            bold: [],
            text:
              "•	Количество успешных заказов и сколько из них были вовремя доставлены;",
          },
          {
            bold: [],
            text:
              "•	Реквизиты продавца: Адрес, АО, БИК, БИН, ИИК, Кбе, КНП, телефон, Ф.И.О. директора, процент комиссии.",
          },
          {
            bold: [],
            text:
              "Также Вы можете изменить логотип магазина. Его увидят покупатели на сайте и в приложении Ayan Market. ",
          },
        ],
      },
    ],
  },
  {
    title: "Заказы",
    titleList: [
      {
        subtitle: "Работа с заказами",
        info: [
          {
            bold: [],
            text:
              "Заказы — ваша главная страница, с которой происходит управление всеми заказами. Для перехода к ней выберите в боковом меню пункт Заказы.",
          },
          {
            bold: [],
            text:
              "Если нажать на 3 стрелочки любого заказа в правой части экрана, то откроется его состав.",
          },
        ],
      },
      {
        subtitle: "Статусы заказов",
        info: [
          {
            bold: [],
            text:
              "Все заказы можно отфильтровать по статусам. Вам доступны следующие статусы: ",
          },
          {
            bold: [0, 25, 26],
            text:
              "Новый- заказы, которые вам необходимо подтвердить, что товары есть у вас в наличии и вы можете выдать их курьеру. После подтверждения заказы перейдут в статус На кассе. ",
          },
          {
            bold: [0, 1, 21],
            text:
              "На кассе- заказы, за которыми могут приехать курьеры и забрать их для доставки клиентам. Когда курьер заберёт заказ, статус сменится на Доставляется ",
          },
          {
            bold: [0, 15],
            text:
              "Доставляется- заказы, которые сейчас у курьеров и доставляются клиентам. После вручения заказа, статус изменится на Доставлен.",
          },
          {
            bold: [0],
            text:
              "Доставлен- заказы, которые клиенты приняли у курьера. Это конечный статус заказов.",
          },
          {
            bold: [0],
            text:
              "Отменен- заказы, которые были отменены по какой-либо причине вами или клиентом",
          },
        ],
      },
      {
        subtitle: "Статусы оплаты",
        info: [
          {
            bold: [0, 1],
            text: "Ожидает списания- клиент еще не произвел оплату. ",
          },
          {
            bold: [0],
            text: "Заморожено- оплата клиентом произведена. ",
          },
          {
            bold: [0, 1],
            text: "Оплата отменена- клиент вернул свои средства. ",
          },
        ],
      },
      {
        subtitle: "Управление заказами",
        info: [
          {
            bold: [],
            text:
              "В верхней панели можно задать любой период, чтобы увидеть все заказы, их статусы и оборот денежных средств. ",
          },
        ],
      },
    ],
  },
  {
    title: "Управление персоналом",
    titleList: [
      {
        subtitle: "Сотрудники",
        info: [
          {
            bold: [],
            text:
              "В данной вкладке Вы можете добавить нового сотрудника или изменить данные у уже созданной учетной записи. Сотрудники нужны для работы с приложением DIS, где отображаются активные заказы, которые необходимо собрать и направить курьеру. При создании профиля, указывайте актуальный номер телефона, так как курьеры будут звонить на те номера, которые закреплены за учетной записью.  ",
          },
        ],
      },
    ],
  },
  {
    title: "Отчеты",
    titleList: [
      {
        subtitle: "Создание",
        info: [
          {
            bold: [],
            text:
              "Вы можете сформировать отчет по продажам, указав необходимый период и филиал. Отчет сформируется в формате EXCEL. ",
          },
        ],
      },
      {
        subtitle: "Виды отчетов",
        info: [
          {
            bold: [0],
            text: "Свернутый- отображается сумма заказов без состава. ",
          },
          {
            bold: [0],
            text: "Развернутый- отображается сумма и состав заказов",
          },
        ],
      },
    ],
  },
  {
    title: "Филиалы",
    titleList: [
      {
        subtitle: "Общее",
        info: [
          {
            bold: [],
            text:
              "Здесь отображаются все Ваши текущие филиалы. Нажав на стрелочку, вы перейдете в сам филиал, где сможете редактировать адрес, минимальную сумму заказа, комментарии курьеру и фото филиала, отображаемого на сайте.",
          },
        ],
      },
      {
        subtitle: "Время работы филиала",
        info: [
          {
            bold: [],
            text:
              "Возможна редакция времени работы филиалы. В указанное время будут поступать заказы. Если заказ упал после часов работы, заказ переносится на следующий рабочий день. Рекомендуем указать время закрытия на пол часа раньше, чтобы успеть передать курьеру заказ. ",
          },
        ],
      },
    ],
  },
  {
    title: "Товары",
    titleList: [
      {
        subtitle: "Добавление товара",
        info: [
          {
            bold: [],
            text: "Вы можете добавить товар несколькими способами: ",
          },
          {
            bold: [0],
            text:
              "- Создание новой карточки товара. Потребуются такие данные, как наименование, категория, описание, краткое описание, единица измерения, штрих-код, фотография товара. После добавления товаров, они направляются на модерацию, где администратор проверяет правильность заполнения карточки. Администратор может согласовать, либо отказать в добавлении товаров.",
          },
          {
            bold: [0],
            text:
              "- Добавление в свой каталог уже существующей карточки товара.  В поисковике Вы можете найти необходимый товар по наименованию. Если же товар не нашелся, просим использовать первый вариант. ",
          },
        ],
      },
      {
        subtitle: "Редактирование товара",
        info: [
          {
            bold: [],
            text:
              "Возможно отредактировать данные, как и при добавлении нового товара.",
          },
        ],
      },
      {
        subtitle: "Изменение цены и остатков",
        info: [
          {
            bold: [],
            text: "Актуальная цена и остаток на текущий момент",
          },
        ],
      },
      {
        subtitle: "Работа с товарами",
        info: [
          {
            bold: [],
            text:
              "Это функционал, позволяющий добавлять товары в большом объеме автоматически. Потребуется заполненная карта ввода, согласно установленному шаблону и фотографии в формате PNG, WebP,JPEG(предпочтительне всего webP) разрешение до 400 мп на сторону, вес фото не должен превышать 100 kb. ",
          },
        ],
      },
    ],
  },
];
export default data;
