<template>
  <section>
    <div class="header">
      <div class="header_title">
        <h1>Теги для {{ currentTags }}</h1>
      </div>

      <v-select
        v-model="currentTags"
        hide-details
        label="Теги для"
        :items="tags"
      ></v-select>
    </div>
    <!-- теги для отзывов -->
    <div v-if="currentTags === 'отзывов'">
      <div class="btns">
        <v-btn color="#5d4bd0" dark @click="getDeleted()">
          {{
            showDeleted ? "Скрыть удаленные теги" : "Посмотреть удаленные теги"
          }}
        </v-btn>
        <v-btn color="#5d4bd0" dark @click="modal = true">
          Добавить тэг
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>

      <div v-for="(item, key) in allTags" :key="key" class="conteiner">
        <p class="conteiner__header">С оценкой: {{ key }}</p>
        <div class="conteiner__item">
          <div v-for="tag in item" :key="tag.id" class="conteiner__tag active">
            {{ tag.title }}
            <v-btn icon @click="deleteTag(tag.id)">
              <v-icon class="conteiner_icon">mdi-close</v-icon>
            </v-btn>
            <v-btn icon @click="openModalToRedact(tag)">
              <v-icon class="conteiner_icon">mdi-lead-pencil</v-icon>
            </v-btn>
          </div>
        </div>
      </div>

      <div v-if="showDeleted" class="conteiner">
        <p class="conteiner__header">Удаленные теги</p>
        <div class="conteiner__item">
          <div
            v-for="tag in deletedTags"
            :key="tag.title"
            class="conteiner__tag deleted"
          >
            {{ tag.title }}
            <v-btn icon @click="restoreTag(tag.id)">
              <v-icon class="conteiner_icon">mdi-plus</v-icon>
            </v-btn>
            <v-btn icon @click="openModalToRedact(tag, true)">
              <v-icon class="conteiner_icon">mdi-lead-pencil</v-icon>
            </v-btn>
          </div>
        </div>
      </div>

      <v-dialog v-model="modal" activator="parent" width="60%">
        <v-card class="modal">
          <v-card-title class="modal_title"
            >{{ isRedacting ? "Изменение " : "Добавление " }} тега</v-card-title
          >
          <v-text-field
            v-model="newTagTitle"
            :rules="titleRules"
            label="Название"
            required
          ></v-text-field>
          <v-text-field
            v-model="newTagTitleKz"
            :rules="titleRules"
            label="Название на казахском"
            required
          ></v-text-field>
          <p class="modal__text">Для рейтинга</p>
          <div class="modal__checkboxes">
            <v-checkbox
              v-for="item in ratingsArr"
              :key="item"
              v-model="selectedRatingsArr"
              :label="item"
              :value="item"
            ></v-checkbox>
          </div>
          <v-card-actions class="modal_actions">
            <v-btn
              color="#5d4bd0"
              dark
              @click="isRedacting ? changeTag() : addNewTag()"
              >{{ isRedacting ? "Изменить" : "Добавить" }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- теги для отмены заказа -->
    <deleted-tags v-if="currentTags === 'отмены заказа'" />
    <!-- теги для модерации продуктов -->
    <moderate-tags v-if="currentTags === 'модерации'" />
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DeletedTags from "@/components/Tags/DeletedTags.vue";
import ModerateTags from "@/components/Tags/ModerateTags.vue";
import showMessage from "@/Functions/message";

export default {
  components: { DeletedTags, ModerateTags },
  data() {
    return {
      allTags: null,
      deletedTags: null,
      modal: false,
      currentTags: "отзывов",
      tags: ["отзывов", "отмены заказа", "модерации"],
      ratingsArr: ["1", "2", "3", "4", "5"],
      selectedRatingsArr: [],
      newTagTitle: "",
      newTagTitleKz: "",
      redactingTagId: null,
      isRedacting: false,
      showDeleted: false,
      titleRules: [
        (value) => {
          if (value) return true;
          return "Введите тэг";
        },
        (value) => {
          if (value?.length <= 20) return true;
          return "Слишком длинный тэг";
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      STATE: "Reviews/STATE",
    }),
  },
  watch: {
    modal(newVal) {
      if (!newVal) {
        this.isRedacting = false;
        this.redactingTagId = null;
        this.selectedRatingsArr = [];
        this.newTagTitle = "";
        this.newTagTitleKz = "";
      }
    },
  },
  mounted() {
    this.setValues();
  },
  methods: {
    ...mapActions({
      getAllTags: "Reviews/GET_ALL_TAGS",
      deleteOneTag: "Reviews/DELETE_ONE_TAG",
      getDeletedTags: "Reviews/GET_ALL_DELETED_TAGS",
      restoreOneTag: "Reviews/RESTORE_ONE_TAG",
      sendAddNewTag: "Reviews/ADD_NEW_TAG",
      sendRedactTag: "Reviews/REDACT_TAG",
    }),
    //Подгружает все тэги
    async setValues() {
      await this.getAllTags();
      this.allTags = this.STATE.allTags;
    },
    async deleteTag(id) {
      await this.deleteOneTag(id);
      this.setValues();
    },
    async restoreTag(id) {
      await this.restoreOneTag(id);
      this.setValues();
      this.getDeleted();
    },
    //Подгружает удаленные тэги
    async getDeleted() {
      if (!this.showDeleted) {
        await this.getDeletedTags();
        this.deletedTags = this.STATE.deletedTags;
      }
      this.showDeleted = !this.showDeleted;
    },
    async addNewTag() {
      if (this.validateModal()) {
        let resp = {
          title: this.newTagTitle.trim(),
          titleKz: this.newTagTitleKz.trim(),
          ratings: this.selectedRatingsArr,
        };
        const response = await this.sendAddNewTag(resp);
        if (response) {
          showMessage(response.data.message);
        } else {
          showMessage();
          this.newTagTitle = "";
          this.newTagTitleKz = "";
          this.selectedRatingsArr = [];
          this.modal = false;
          this.setValues();
        }
      }
    },
    //Открывает модалку с данными из выбранного тега
    openModalToRedact(tagObj, isDeleted = false) {
      this.newTagTitle = tagObj.title;
      this.newTagTitleKz = tagObj.titleKz;
      if (isDeleted) {
        this.selectedRatingsArr = tagObj.reviewRatings.map((item) =>
          item.value.toString()
        );
      } else {
        this.selectedRatingsArr = this.findTagByID(tagObj.id);
      }
      this.redactingTagId = tagObj.id;
      this.isRedacting = true;
      this.modal = true;
    },
    async changeTag() {
      if (this.validateModal()) {
        let resp = {
          id: this.redactingTagId,
          title: this.newTagTitle.trim(),
          titleKz: this.newTagTitleKz.trim(),
          ratings: this.selectedRatingsArr,
        };
        const response = await this.sendRedactTag(resp);
        if (response) {
          showMessage(response.data.message);
        } else {
          showMessage();
          this.modal = false;
          this.setValues();
        }
      }
    },
    //Вспомогательная функция, ищет в каких оценках есть данный тег
    findTagByID(id) {
      let ratingArr = [];
      let keys = Object.keys(this.allTags);
      keys.forEach((key) => {
        this.allTags[key].forEach((tag) => {
          if (tag.id === id) {
            ratingArr.push(key);
          }
        });
      });
      return ratingArr;
    },
    //Вспомогательная функция, проверяет нет ли пустых данных в форме
    validateModal() {
      if (
        this.newTagTitle &&
        this.newTagTitleKz &&
        this.selectedRatingsArr.length > 0
      ) {
        return true;
      } else {
        showMessage("Заполните все поля");
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btns {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}
.conteiner {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  &__header {
    padding-top: 10px;
    font-size: 20px;
    font-weight: bold;
  }
  &__item {
    width: 100%;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: left;
  }
  &__tag {
    padding: 5px 10px;
    border-radius: 10px;
    color: white;
  }
  &_icon:hover {
    color: white !important;
  }
}
.active {
  background-color: green;
}
.deleted {
  background-color: red;
}
.modal {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &_title {
    justify-content: center;
  }
  &__text {
    padding: 0;
    margin: 0;
  }
  &_actions {
    justify-content: right;
  }
  &__checkboxes {
    display: flex;
    gap: 30px;
    @media screen and (max-width: 700px) {
      flex-wrap: wrap;
    }
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &_title {
    width: 90%;
  }
}
</style>
