<template>
  <v-card-text class="diagram">
    <loader v-if="loading"></loader>
    <div class="create-btn">
      <v-btn small color="success" @click="openCreateModal = true"
        >Добавить компанию</v-btn
      >
    </div>
    <v-simple-table>
      <thead>
        <tr>
          <th>Название</th>
          <th>Действия</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in COMPANY" :key="item.id">
          <td>{{ item.name }}</td>
          <td class="table-btns">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  color="#5d4bd0"
                  v-on="on"
                  @click="showOptions(item.id)"
                >
                  <v-icon>mdi-lead-pencil</v-icon>
                </v-btn>
              </template>
              <span>Опции доставки</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  color="#5d4bd0"
                  v-on="on"
                  @click="showCity(item.id)"
                >
                  <v-icon>mdi-city</v-icon>
                </v-btn>
              </template>
              <span>Подключение городов</span>
            </v-tooltip>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-dialog v-model="openCreateModal" activator="parent" width="70%">
      <v-card class="modal" :dark="$dark.get()">
        <v-card-title class="flex justify-center">
          Создать компанию
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="newCompanyName"
            :rules="[$validate.required]"
            label="Название"
            type="text"
            required
            clearable
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="modal__actions">
          <v-btn @click="openCreateModal = false">Закрыть</v-btn>
          <v-btn color="#5d4bd0" dark @click="createCompany()">Создать</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openCityModal" activator="parent" width="70%">
      <v-card class="modal" :dark="$dark.get()">
        <v-card-title class="flex justify-center">
          Города компании
        </v-card-title>
        <v-card-text>
          <div>
            Подключенные города:
            <div v-for="city in cityes" :key="city.id" class="city-box">
              <span class="black-text">{{ city.name }} </span>
              <v-btn x-small icon color="error" @click="removeCity(city.id)"
                ><v-icon>mdi-trash-can</v-icon></v-btn
              >
            </div>
            <span v-if="!cityes.length" class="black-text">Отсутствуют</span>
          </div>
          <v-select
            v-model="cityId"
            :items="CITY.allCity"
            item-text="name"
            item-value="id"
            label="Добавить город"
            dense
            hide-details
          />
        </v-card-text>
        <v-card-actions class="modal__actions">
          <v-btn @click="openCityModal = false">Закрыть</v-btn>
          <v-btn color="#5d4bd0" dark @click="addCity()">Добавить город</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openOptionsModal" activator="parent" width="70%">
      <v-card class="modal" :dark="$dark.get()">
        <v-card-title class="flex justify-center">
          Опции доставки
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <thead>
              <tr>
                <th>Код</th>
                <th>Название опции</th>
                <th>Тип доставки</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="option in companyOptions" :key="option.id">
                <td>{{ option.code }}</td>
                <td>{{ option.name }}</td>
                <td>{{ option.deliveryType.name }}</td>
                <td class="table-btns">
                  <v-btn
                    icon
                    color="error"
                    @click="
                      deletedOptionId = option.id;
                      showDeleteModal = true;
                    "
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-form v-if="showCreateForm" ref="optionForm">
            <v-text-field
              v-model="input.name"
              :rules="[$validate.required]"
              label="Название опции"
              type="text"
              required
              clearable
            ></v-text-field>
            <v-text-field
              v-model="input.code"
              :rules="[$validate.required]"
              label="Код опции"
              type="number"
              required
              clearable
            ></v-text-field>
            <v-autocomplete
              v-model="input.deliveryTypeId"
              :items="DELIVERY_TYPES"
              item-text="name"
              item-value="id"
              no-data-text="Ничего не найдено"
              class="mt-3"
              label="Выберите тип доставки"
              clearable
              required
            />
            <v-btn small @click="showCreateForm = false">Отмена</v-btn>
            <v-btn small color="#5d4bd0" dark @click="addOption()"
              >Подтвердить</v-btn
            >
          </v-form>
        </v-card-text>
        <v-card-actions class="modal__actions">
          <v-btn @click="openOptionsModal = false">Закрыть</v-btn>
          <v-btn color="#5d4bd0" dark @click="showCreateForm = true"
            >Добавить опцию</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DeleteDialog
      :show="showDeleteModal"
      @close-modal="showDeleteModal = !showDeleteModal"
      @delete-modal="deleteOption()"
    />
  </v-card-text>
</template>

<script>
import Loader from "@/components/Loader";
import ShowMessage from "@/Functions/message";
import DeleteDialog from "@/components/DeleteDialog";
import { mapGetters, mapActions } from "vuex";
import api from "@/api";
export default {
  name: "Company",
  components: {
    Loader,
    DeleteDialog,
  },
  data() {
    return {
      loading: false,
      openCreateModal: false,
      openOptionsModal: false,
      showDeleteModal: false,
      showCreateForm: false,
      openCityModal: false,
      companyOptions: [],
      companyCity: [],
      cityId: null,
      deletedOptionId: null,
      newCompanyName: "",
      companyId: null,
      input: {},
    };
  },
  computed: {
    ...mapGetters({
      COMPANY: "Logistic/COMPANY",
      DELIVERY_TYPES: "Logistic/DELIVERY_TYPES",
      CITY: "City/STATE",
    }),
    cityes() {
      return this.CITY.allCity.filter((city) =>
        this.companyCity.some((compCity) => compCity.cityId === city.id)
      );
    },
  },
  mounted() {
    if (!this.COMPANY.length) {
      this.setAll();
    }
  },
  methods: {
    ...mapActions({
      GET_COMPANY: "Logistic/GET_COMPANY",
    }),
    async setAll() {
      this.loading = true;
      await this.GET_COMPANY();
      this.loading = false;
    },
    async createCompany() {
      if (!this.newCompanyName) {
        ShowMessage("Укажите название");
        return;
      }
      let response = await api.Logistic.addCompany({
        name: this.newCompanyName,
      });
      if (response.type === "error") {
        ShowMessage(response.data.message);
      } else {
        ShowMessage("Компания создана", true);
        this.setAll();
      }
      this.newCompanyName = "";
      this.openCreateModal = false;
    },
    async showCity(companyId) {
      const response = await api.Logistic.getCompanyCity(companyId);
      this.companyCity = response;
      this.companyId = companyId;
      this.openCityModal = true;
    },
    async showOptions(companyId) {
      const response = await api.Logistic.getOptions(companyId);
      this.companyOptions = response;
      this.companyId = companyId;
      this.openOptionsModal = true;
    },
    async deleteOption() {
      let response = await api.Logistic.removeOptionFromCompany(
        this.deletedOptionId
      );
      if (response.type === "error") {
        ShowMessage(response.data.message);
      } else {
        ShowMessage("Опция удалена", true);
        this.showOptions(this.companyId);
        this.showDeleteModal = false;
      }
    },
    async addOption() {
      if (!this.$refs.optionForm.validate()) {
        ShowMessage("Заполните все поля");
        return;
      }
      this.input.deliveryCompanyId = this.companyId;
      let response = await api.Logistic.addOptionToCompany(this.input);
      if (response.type === "error") {
        ShowMessage(response.data.message);
      } else {
        ShowMessage("Опция добавлена", true);
        this.showOptions(this.companyId);
        this.showCreateForm = false;
      }
    },
    async addCity() {
      if (!this.cityId) {
        ShowMessage("Выберите город");
        return;
      }
      const body = {
        cityId: this.cityId,
        deliveryCompanyId: this.companyId,
        isOn: true,
      };
      let response = await api.Logistic.addCityToCompany(body);
      if (response.type === "error") {
        ShowMessage(response.data.message);
      } else {
        ShowMessage("Город добавлен", true);
        this.showCity(this.companyId);
        this.cityId = null;
      }
    },
    async removeCity(cityId) {
      const companyCity = this.companyCity.find(
        (item) => item.cityId === cityId
      );
      let response = await api.Logistic.deleteCityFromCompany(companyCity.id);
      if (response.type === "error") {
        ShowMessage(response.data.message);
      } else {
        ShowMessage("Город удален", true);
        this.showCity(this.companyId);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-btns {
  width: 110px;
}
.create-btn {
  width: 100%;
  display: flex;
  justify-content: right;
}
.black-text {
  color: black;
  font-weight: bold;
  font-size: 16px;
}
.city-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
</style>
