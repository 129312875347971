<template>
  <v-card :dark="$dark.get()">
    <loader v-if="loading" />
    <div class="custom-card">
      <div class="custom-card__title header">
        <p>Типы курьеров</p>
        <v-btn color="#5d4bd0" dark @click="modal = true">
          Добавить
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
      <div class="custom-card__container">
        <div class="custom-table">
          <div class="head">
            <div v-for="item in items.head" :key="item.id" class="head__item">
              {{ item.name }}
            </div>
          </div>
          <div v-for="item in items.body" :key="item.id" class="value">
            <div class="value__item">
              {{ item.name }}
            </div>
            <div class="value__item">
              {{ item.speed }}
            </div>
            <div class="value__item">
              {{
                item.maxDistance > 5000
                  ? "Без ограничений"
                  : `${item.maxDistance} м`
              }}
            </div>
            <div class="value__item">{{ item.basketSize }} корзин(ы)</div>
            <div class="value__item">
              {{ item.weight > 200 ? "Без ограничений" : `${item.weight} кг` }}
            </div>
            <div class="value__item">
              {{ item.deliveryTrust }}
            </div>
            <div class="value__item">Не более {{ item.maxOrders }}</div>
            <div class="value__item">
              {{ item.priority }}
            </div>
            <div class="value__item">
              {{ item.gsm }}
            </div>
            <div class="value__item">
              <v-btn icon color="#5d4bd0" @click="openModalToRedact(item)">
                <v-icon>mdi-lead-pencil</v-icon>
              </v-btn>
              <v-btn icon color="error" @click="deleteCourierType(item.id)">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="modal" activator="parent" width="50%">
      <v-card class="modal" :dark="$dark.get()">
        <v-form
          ref="form"
          @submit.prevent="isRedacting ? changeType() : addNewType()"
        >
          <v-text-field
            v-model.trim="redactingObj.name"
            :rules="[$validate.required]"
            label="Название"
            required
            clearable
          ></v-text-field>
          <v-text-field
            v-model.number="redactingObj.speed"
            :rules="[$validate.required]"
            label="Скорость"
            type="number"
            required
            clearable
          ></v-text-field>
          <v-text-field
            v-model.number="redactingObj.maxDistance"
            :rules="[$validate.required]"
            label="Максимальное расстояние"
            type="number"
            hint="Укажите 9999 если оно не учитывается"
            required
            clearable
          ></v-text-field>
          <v-text-field
            v-model.number="redactingObj.basketSize"
            :rules="[$validate.required]"
            label="Объем"
            type="number"
            required
            clearable
          ></v-text-field>
          <v-text-field
            v-model.number="redactingObj.weight"
            :rules="[$validate.required]"
            label="Вес"
            type="number"
            hint="Укажите 999 если вес неограничен"
            required
            clearable
          ></v-text-field>
          <v-text-field
            v-model.number="redactingObj.gsm"
            :rules="[$validate.requiredWithZero]"
            label="Стоимость ГСМ на 1км"
            type="number"
            required
            clearable
          ></v-text-field>
          <v-text-field
            v-model.number="redactingObj.maxOrders"
            :rules="[$validate.required]"
            label="Количество заказов"
            type="number"
            required
            clearable
          ></v-text-field>
          <v-select
            v-model="redactingObj.deliveryTrust"
            label="Надежность"
            :items="deliveryTrust"
            :rules="[$validate.required]"
            required
          ></v-select>
          <v-select
            v-model.number="redactingObj.priority"
            label="Приоритет"
            :items="createPriorityArr"
            :rules="[$validate.required]"
            required
          ></v-select>
          <v-card-actions class="flex justify-end">
            <v-btn right @click="modal = false">Отмена</v-btn>
            <v-btn color="#5d4bd0" dark type="submit" right>{{
              isRedacting ? "Изменить" : "Добавить"
            }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <delete-dialog
      :show="show"
      @close-modal="show = !show"
      @delete-modal="deleteCourierType()"
    />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import loader from "@/components/Loader";
import DeleteDialog from "@/components/DeleteDialog";
import showMessage from "@/Functions/message";

export default {
  components: {
    loader,
    DeleteDialog,
  },
  data() {
    return {
      loading: false,
      modal: false,
      isRedacting: false,
      show: false,
      deletedId: 0,
      deliveryTrust: ["high", "medium", "low"],
      redactingObj: {
        name: "",
        speed: "",
        maxDistance: "",
        basketSize: "",
        weight: "",
        deliveryTrust: "",
        maxOrders: "",
        profile: "foot",
        priority: "",
        gsm: "",
      },
      responseObj: null,
      items: {
        head: [
          { id: 1, name: "Тип курьера" },
          { id: 2, name: "Скорость" },
          { id: 3, name: "Расстояние" },
          { id: 4, name: "Объем" },
          { id: 5, name: "Вес" },
          { id: 6, name: "Надежность доставки" },
          { id: 7, name: "Количество заказов" },
          { id: 8, name: "Приоритет" },
          { id: 9, name: "ГСМ" },
          { id: 10, name: "Изменить" },
        ],
        body: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      STATE: "Couriers/STATE",
    }),
    //создает массив от 1 до количеcтва типов
    createPriorityArr() {
      let arr = [...Array(this.items.body.length).keys()].map((x) => (x += 1));
      return arr;
    },
  },
  watch: {
    modal(newVal) {
      if (!newVal) {
        this.isRedacting = false;
        this.$refs.form.resetValidation();
        this.redactingObj = { ...this.responseObj };
      }
    },
  },
  mounted() {
    this.setValues();
    this.responseObj = { ...this.redactingObj };
  },
  methods: {
    ...mapActions({
      getAllCourierTypes: "Couriers/GET_ALL_COIRIERS_TYPES",
      editType: "Couriers/EDIT_COURIER_TYPE",
      createType: "Couriers/CREATE_COURIER_TYPE",
      deleteType: "Couriers/DELETE_COURIER_TYPE",
    }),
    //Подгружает все типы курьеров
    async setValues() {
      this.loading = true;
      await this.getAllCourierTypes();
      if (this.STATE.allTypes.length > 0) {
        this.items.body = this.STATE.allTypes;
      } else {
        this.$notify({
          group: "app",
          type: "warn",
          title: "Ошибка",
          text: "Данные не загрузились",
        });
      }
      this.loading = false;
    },
    //Изменяет данные о типе курьера
    async changeType() {
      if (this.checkFormFields()) {
        this.loading = true;
        let response = await this.editType(this.redactingObj);
        if (response?.type === "error") {
          showMessage(response.data.message);
        } else {
          showMessage();
        }
        this.modal = false;
        await this.setValues();
        this.loading = false;
      }
    },
    //Добавляет новый тип курьера
    async addNewType() {
      this.$refs.form.validate();
      if (this.checkFormFields()) {
        this.loading = true;
        let response = await this.createType(this.redactingObj);
        if (response?.type === "error") {
          showMessage(response.data.message);
        } else {
          showMessage();
        }
        this.modal = false;
        await this.setValues();
        this.loading = false;
      }
    },
    //Открывает модалку с данными из выбранного типа
    openModalToRedact(typeObj) {
      this.redactingObj = { ...typeObj };
      this.isRedacting = true;
      this.modal = true;
    },
    checkFormFields() {
      let values = Object.values(this.redactingObj);
      let isAllCorrect = values.every((value) => value || value === 0);
      return isAllCorrect;
    },
    async deleteCourierType(id) {
      if (id) {
        this.deletedId = id;
      } else {
        this.loading = true;
        let response = await this.deleteType(this.deletedId);
        if (response?.type === "error") {
          showMessage(response.data.message);
        } else {
          showMessage();
        }
        this.deletedId = 0;
        await this.setValues();
        this.loading = false;
      }
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-card {
  padding: 40px;
  position: relative;
  &__title {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    display: flex;
    justify-content: space-between;
  }

  .custom-card__container {
    .custom-table {
      .head {
        display: flex;
        justify-content: space-between;
        &__item {
          text-align: left;
          font-weight: 500;
          width: 10%;
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .value {
        display: flex;
        justify-content: space-between;
        &__item {
          text-align: left;
          margin-top: 20px;
          width: 10%;
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        &__btn {
          width: 100%;
        }
      }
    }
  }
}
.modal {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__text {
    padding: 0;
    margin: 0;
  }
}
</style>
