<template>
  <section>
    <loader v-if="loading" />
    <v-card :dark="$dark.get()" min-height="90vh">
      <v-card-title class="header">
        Акции
        <v-btn class="coupon-btn" @click="modal = true">Добавить акцию</v-btn>
        <v-select
          label="Тип акции"
          :items="promotion"
          item-value="value"
          item-text="name"
          class="header_select"
          @change="setValues($event)"
        ></v-select>
        <div class="action-periods">
          <p class="action-periods_text">Периоды отображения акций</p>
          <v-row class="ma-0">
            <v-col cols="6" class="pa-0">
              <v-menu
                ref="menu"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                :dark="$dark.get()"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="input.startDate"
                    readonly
                    solo
                    v-bind="attrs"
                    hide-details
                    dense
                    class="action-periods_input"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="input.startDate"
                  no-title
                  :max="input.endDate"
                  @change="
                    setValues();
                    menu1 = !menu1;
                  "
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" class="pa-0">
              <v-menu
                ref="menu"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                :dark="$dark.get()"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="action-periods_input"
                    :value="input.endDate"
                    readonly
                    solo
                    v-bind="attrs"
                    hide-details
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="input.endDate"
                  no-title
                  :min="input.startDate"
                  @change="
                    setValues();
                    menu2 = !menu2;
                  "
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </div>
      </v-card-title>
      <loader v-if="loading"></loader>
      <v-card-text v-else class="main-content">
        <ActionCard
          v-for="item in ACTIONS"
          :key="item.id"
          :action="item"
          @delete="deleteAction(item.id)"
          @edit="openModal(item)"
        />
      </v-card-text>
    </v-card>
    <!-- Окно с полной информацией -->
    <v-dialog v-model="modal" activator="parent" width="60%">
      <v-card class="modal">
        <v-card-title class="modal_title"
          >{{ editMode ? "Изменение" : "Создание" }} акции</v-card-title
        >
        <v-card-text>
          <v-form ref="action">
            <v-text-field
              v-model="action.name"
              :rules="[$validate.required]"
              color="#5D4BD0"
              label="Название"
              required
            ></v-text-field>
            <v-text-field
              v-model="action.description"
              :rules="[$validate.required]"
              color="#5D4BD0"
              label="Описание"
              required
            ></v-text-field>
            <v-menu
              v-model="menu3"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="action.startDate"
                  label="Начало действия"
                  prepend-icon="mdi-calendar"
                  color="#5D4BD0"
                  readonly
                  v-bind="attrs"
                  hide-details
                  :rules="[$validate.required]"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="action.startDate"
                no-title
                scrollable
                @change="menu3 = false"
              ></v-date-picker>
            </v-menu>

            <v-menu
              v-model="menu4"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="action.endDate"
                  label="Окончание действия"
                  prepend-icon="mdi-calendar"
                  color="#5D4BD0"
                  readonly
                  v-bind="attrs"
                  hide-details
                  :rules="[$validate.required]"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="action.endDate"
                no-title
                scrollable
                :min="action.startDate"
                @change="menu4 = false"
              ></v-date-picker>
            </v-menu>
            <v-select
              v-model="action.cityIds"
              label="Города"
              :items="CITY.allCity"
              item-value="id"
              item-text="name"
              color="#5D4BD0"
              multiple
            ></v-select>
            <!-- приоритет -->
            <v-text-field
              v-model="action.priority"
              type="number"
              :rules="[$validate.required]"
              color="#5D4BD0"
              label="Приоритет"
              required
            ></v-text-field>
            <!-- продукт -->
            <v-text-field
              v-model="action.product"
              color="#5D4BD0"
              label="ID Продукта"
              type="number"
            ></v-text-field>
            <!-- категории -->
            <v-autocomplete
              v-model="action.category"
              color="#5D4BD0"
              :items="CATEGORY"
              item-text="name"
              item-value="id"
              no-data-text="Ничего не найдено"
              class="mt-3"
              label="Поиск по категории"
              clearable
            />
            <!-- картинка -->
            <input
              ref="imgInput"
              type="file"
              hidden
              accept="image/*"
              @change="setImage($event)"
            />
            <v-btn
              color="#5D4BD0"
              class="mb-5"
              dark
              @click="$refs.imgInput.click()"
              >{{ editMode ? "Заменить" : "Добавить" }} изображение</v-btn
            >
            <v-img width="300" contain :src="img.url" />
          </v-form>
        </v-card-text>
        <v-card-actions class="modal_actions">
          <v-btn v-if="editMode" color="#5D4BD0" dark @click="editAction()"
            >Изменить</v-btn
          >
          <v-btn v-else color="#5D4BD0" dark @click="createAction()"
            >Создать</v-btn
          >
          <v-btn class="coupon-btn" @click="modal = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Подтвердение удаления -->
    <delete-dialog
      :show="show"
      @close-modal="show = !show"
      @delete-modal="deleteAction()"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import showMessage from "@/Functions/message";
import DeleteDialog from "@/components/DeleteDialog";
import ActionCard from "@/components/Actions/ActionCard";
import ShowMessage from "@/Functions/message";
import loader from "@/components/Loader";
import moment from "moment";
import api from "@/api";

export default {
  components: {
    loader,
    DeleteDialog,
    ActionCard,
  },
  data() {
    return {
      action: {},
      modal: false,
      editMode: false,
      deletedId: 0,
      show: false,
      loading: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      img: {},
      promotion: [
        {
          name: "Действующая",
          value: "ACTIVE",
        },
        {
          name: "Анонс",
          value: "UPCOMING",
        },
        {
          name: "Все",
          value: "ALL",
        },
      ],
      input: {
        endDate: "",
        startDate: "",
        priorityAsc: "true",
        promotionType: "ALL",
      },
    };
  },
  computed: {
    ...mapGetters({
      ACTIONS: "Actions/STATE",
      CITY: "City/STATE",
      CATEGORY: "Category/PRODUCTS_CATEGORY",
    }),
  },
  watch: {
    modal(val) {
      if (!val) {
        this.editMode = false;
        this.action = {};
        this.img = {};
        this.$refs.action.resetValidation();
      }
    },
  },
  mounted() {
    this.input.startDate = moment().format("YYYY-MM-DD");
    this.input.endDate = moment().format("YYYY-MM-DD");
    this.setValues();
    this.setCategory();
  },
  methods: {
    ...mapActions({
      GET_ACTIONS: "Actions/GET_ACTIONS",
      ADD_COUPON: "Coupons/ADD_COUPON",
      DELETE_COUPON: "Coupons/DELETE_COUPON",
      EDIT_COUPON: "Coupons/EDIT_COUPON",
      GET_PRODUCTS_CATEGORY: "Category/GET_PRODUCTS_CATEGORY",
    }),
    //Подгружает все тэги
    async setValues(type) {
      if (type) {
        this.input.promotionType = type;
      }
      this.loading = true;
      await this.GET_ACTIONS(this.input);
      this.loading = false;
    },
    setCategory() {
      if (!this.CATEGORY.length) {
        this.GET_PRODUCTS_CATEGORY();
      }
    },
    setImage(input) {
      const image = input.target.files[0];
      this.img.file = image;
      this.img.url = URL.createObjectURL(image);
      this.$forceUpdate();
    },
    async createAction() {
      if (!this.$refs.action.validate()) {
        showMessage("Заполните все поля");
        return;
      }
      this.loading = true;
      this.action.categoryId = this.action.category;
      this.action.productId = this.action.product;
      let response = await api.Actions.createAction(this.action);
      if (response.type !== "error") {
        // если добавили картинку загружаем ее через отдельную апи
        if (this.img.file) {
          const formData = new FormData();
          formData.append("file", this.img.file);
          const payload = {
            id: response.id,
            file: formData,
          };
          await api.Actions.addImage(payload);
        }
        ShowMessage("Акция создана", true);
        this.modal = false;
        this.setValues();
      } else {
        ShowMessage(response.data.message);
      }
      this.loading = false;
    },
    async editAction() {
      if (!this.$refs.action.validate()) {
        showMessage("Заполните все поля");
        return;
      }
      this.loading = true;
      this.action.categoryId = this.action.category
        ? this.action.category.id
          ? this.action.category.id
          : this.action.category
        : null;
      this.action.productId = this.action.product
        ? this.action.product.id
          ? this.action.product.id
          : this.action.product
        : null;
      const response = await api.Actions.editAction(this.action);
      // если добавили картинку загружаем ее через отдельную апи
      if (this.img.file) {
        const formData = new FormData();
        formData.append("file", this.img.file);
        const payload = {
          id: this.action.id,
          file: formData,
        };
        await api.Actions.addImage(payload);
      }
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("Акция изменена", true);
        await this.setValues();
      }
      this.modal = false;
      this.loading = false;
    },
    openModal(item) {
      this.editMode = true;
      this.action = JSON.parse(JSON.stringify(item));
      if (this.action.product && this.action.product.id) {
        this.action.product = this.action.product.id;
      }
      this.img.url = this.action.imageUrl;
      this.modal = true;
    },
    async deleteAction(id) {
      if (id) {
        this.deletedId = id;
      } else {
        this.loading = true;
        let response = await api.Actions.deleteAction(this.deletedId);
        if (response?.type === "error") {
          showMessage(response.data.message);
        } else {
          showMessage("Акция удалена", true);
        }
        this.deletedId = 0;
        await this.setValues();
        this.loading = false;
      }
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss" scoped>
.action-periods {
  display: flex;
  flex-direction: column;
  &_text {
    color: black;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 14px;
  }
  &_input {
    border: #f183aa 1px solid !important;
    border-radius: 12px;
  }
}
.main-content {
  margin-top: 20px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.coupon-btn {
  border: 1px solid #5d4bd0;
  color: #5d4bd0 !important;
  border-radius: 7px;
}
.modal {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &_title {
    justify-content: center;
  }
  &__text {
    padding: 0;
    margin: 0;
  }
  &_actions {
    justify-content: right;
  }
  &_bold {
    font-weight: 800;
  }
  &_images {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  &_select {
    max-width: 15%;
    @media (max-width: 600px) {
      max-width: 90%;
    }
  }
  @media (max-width: 600px) {
    flex-direction: column;
    padding-left: 5px;
  }
}
</style>
