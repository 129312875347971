<template>
  <div>
    <v-dialog persistent :value="true" width="100px" overlay-opacity="0.8">
      <v-card
        height="100px"
        class="d-flex justify-center align-center"
        :dark="$dark.get()"
      >
        <v-progress-circular
          indeterminate
          size="56"
          color="#5d4bd0"
        ></v-progress-circular>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  computed: {
    dark() {
      if (this.$dark.get() == true) {
        return "--dark";
      }
      return "";
    },
  },
};
</script>
