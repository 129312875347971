<template>
  <v-dialog
    v-if="category.name"
    v-model="show"
    persistent
    width="650px"
    transition="scroll-y-transition"
    @click:outside="$emit('close')"
  >
    <v-form>
      <v-card :loading="loading">
        <v-card-title>
          {{ category.name }}
          <v-spacer></v-spacer>
          <v-btn
            fab
            x-small
            elevation="0"
            color="grey lighten-2"
            outlined
            @click="$emit('close')"
            ><v-icon color="grey darken-2">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>Описание</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-html="category.description || 'Нет'"></div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-simple-table class="mt-3">
            <tbody>
              <tr>
                <td>Родитель</td>
                <td>
                  {{ getParents(category.parentMap) }}
                </td>
              </tr>
              <tr>
                <td>Изображение</td>
                <td>
                  <v-img
                    v-if="category.image !== null"
                    :src="category.image.fileUrl"
                    width="40px"
                  ></v-img>
                  <p v-else>Нет изображения</p>
                </td>
              </tr>
              <tr>
                <td>SEO</td>
                <td>
                  {{ ifSeo }}
                </td>
              </tr>
              <tr>
                <td>Дочерние категории</td>
                <td>
                  <v-chip-group v-if="category.children.length" column>
                    <v-chip
                      v-for="item in category.children"
                      :key="item.id"
                      :color="colors[randomColor()]"
                      label
                      style="color: white"
                      small
                    >
                      {{ item.name + " - " + item.id }}
                    </v-chip>
                  </v-chip-group>
                  <p v-else>Нет дочерних категорий</p>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-btn
            block
            color="#5d4bd0"
            dark
            :to="$route.path + '/' + category.id"
            >Редактировать</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    category: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      colors: [
        "deep-purple",
        "indigo",
        "blue",
        "success",
        "amber",
        "error",
        "teal",
      ],
    };
  },
  computed: {
    ifSeo() {
      if (this.category.seo && this.category.seo.length !== 0) {
        return "Есть";
      }
      return "Отсутствует";
    },
  },
  methods: {
    randomColor() {
      return (Math.random() * (6 - 1) + 1).toFixed();
    },
    getParents(categoryObj) {
      if (categoryObj) {
        return Object.keys(categoryObj).join(";");
      } else {
        return "Нет родительских категорий";
      }
    },
  },
};
</script>
