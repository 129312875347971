<template>
  <div>
    <v-card :dark="$dark.get()" class="mb-2" style="overflow: hidden">
      <v-card-title class="pa-2">
        <div>
          <p class="pr-5 mb-1">{{ $route.name }}</p>
          <ol v-if="!edit" class="help">
            <li>Выберите адрес на карте</li>
            <li>Выберите филиал из списка появившихся</li>
            <li>Выберите категорию</li>
            <li>Сформмируйте заказ</li>
            <li>Заполните поля клиента (телефон, имя, фамилия, квартира)</li>
            <li>Заполните детали(если необходимо)</li>
          </ol>
        </div>

        <v-chip-group
          v-if="!loading"
          v-model="input.departmentIds[0]"
          style="max-width: calc(100% - 24px)"
        >
          <v-chip
            v-for="item in departments"
            :key="item.id"
            color="primary"
            filter
            :disabled="edit && item.id !== input.departmentIds[0]"
            :value="item.id"
            @click="changeDepartment(item)"
          >
            {{ item.name }}
          </v-chip>
        </v-chip-group>
        <div v-if="edit" class="dep-block">
          <v-select
            v-model="selectedDepartment"
            class="dep-block__select"
            label="Филиалы"
            :items="allDeps"
            item-text="name"
            item-value="id"
          ></v-select>
          <v-btn color="#5d4bd0" dark @click="postponeOrder()">Перенести</v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <!-- блок с картой -->
        <div v-if="!edit" class="content_map">
          <div class="content_map__search-block">
            <v-text-field
              v-model="searchText"
              color="#5d4bd0"
              label="Поиск по карте"
              single-line
              clearable
              hide-details
              prepend-inner-icon="mdi-magnify"
              class="content_map__input"
              @click:clear="searchText = ''"
            />
            <v-btn color="#5d4bd0" dark @click="searchAddress()"> Найти </v-btn>
          </div>
          <yandex-map
            :controls="map.controls"
            :zoom="map.zoom"
            :settings="mapSettings"
            :coords="map.coords"
            :options="map.options"
            @click="mapClick"
            @map-was-initialized="myMap = $event"
          >
            <ymap-marker
              v-if="map.MarkerVisible"
              :coords="map.coords"
              marker-id="index"
            />
          </yandex-map>
        </div>
      </v-card-text>
    </v-card>
    <loader v-if="loading"></loader>
    <v-row v-else style="align-items: stretch">
      <v-col>
        <order-options
          :key="productKey"
          :input="input"
          :validation="validation"
          @get-validation="getStatus"
          @up-product="upProduct"
          @down-product="downProduct"
          @remove-product="removeProduct"
        ></order-options>
        <p v-if="input.paidForDelivery">
          Доставка: {{ input.paidForDelivery }} тг
        </p>
      </v-col>
      <v-col>
        <order-select
          v-if="!search"
          :category="CATEGORY.allCategory"
          :products="PRODUCT.paginatedProducts"
          :input="inputPage"
          @change_page="inputPage.page = $event"
          @new_item="newItem"
        ></order-select>
      </v-col>
    </v-row>
    <v-btn :dark="!$dark.get()" class="mt-2" @click="validation++">{{
      $route.meta.button
    }}</v-btn>
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mt-2 ml-2 pl-2 pr-2"
          elevation="0"
          color="error"
          v-bind="attrs"
          v-on="on"
          @click="input.products = []"
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>
      </template>
      <span>Удалить все продукты</span>
    </v-tooltip>
  </div>
</template>
<script>
import OrderOptions from "@/components/Order/OrderCreate/OrderOptions";
import OrderSelect from "@/components/Order/OrderCreate/OrderSelect";
// import OrderSearch from "@/components/Order/OrderCreate/OrderSearch";
import Loader from "@/components/Loader";
import singleorder from "@/model/singleorder";
import showMessage from "@/Functions/message";
import { mapActions, mapGetters } from "vuex";
import api from "@/api";
export default {
  components: { Loader, OrderOptions, OrderSelect },
  data() {
    return {
      edit: false,
      loading: true,
      products: [],
      input: {},
      inputPage: {
        categoryId: 0,
        departmentIds: [],
        size: 13,
        page: 0,
      },
      inputSearch: {
        departmentId: 5,
        name: "",
        page: 0,
        size: 13,
      },
      productKey: 0,
      validation: 0,
      refValid: false,
      search: false,
      selectedDepartment: 0,
      allDeps: [],
      searchText: "",
      myMap: {},
      departments: [],
      map: {
        zoom: 15,
        coords: [49.807754, 73.088504],
        controls: ["geolocationControl", "zoomControl"],
        MarkerVisible: true,
        options: {
          restrictMapArea: false,
        },
      },
      mapSettings: {
        apiKey: "4ca540e5-4dd9-43dd-b029-5d4a1097eec8",
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
      },
    };
  },
  computed: {
    ...mapGetters({
      CATEGORY: "Category/STATE",
      PRODUCT: "Products/STATE",
      DEPARTMENT: "Department/STATE",
      CITYES: "City/STATE",
    }),
  },
  watch: {
    inputPage: {
      async handler() {
        await this.getProduct(this.inputPage);
      },
      deep: true,
    },
    inputSearch: {
      async handler() {
        if (this.search) {
          await this.getSearch(this.inputSearch);
        }
      },
      deep: true,
    },
  },
  async created() {
    let promiseArr = [];
    promiseArr.push(this.getAll(1));
    promiseArr.push(this.getDepartment(this.$root.city));
    promiseArr.push(this.renderPage());
    promiseArr.push(this.getPayment());
    promiseArr.push(this.getAtype());
    await Promise.allSettled(promiseArr);
    this.getAllDeps();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getSingle: "Order/GET_ORDER",
      getAll: "Category/GET_ALL_CATEGORY",
      getProduct: "Products/GET_PAGINATED_PRODUCTS",
      getPayment: "Payment/GET_ALL_PAYMENT",
      getAtype: "Atypes/GET_ALL_ADDRESS",
      addOrder: "Order/ADD_ORDER",
      editOrder: "Order/EDIT_ORDER",
      getDepartment: "Department/GET_DEPARTMENT",
      getSearch: "Products/PRODUCT_FIND",
      singleProduct: "Products/GET_ROW",
      editDepartment: "Order/EDIT_DEPARTMENT",
    }),
    async renderPage() {
      let model = new singleorder();
      this.input = model.setInput();
      if (this.$route.params.id) {
        this.edit = true;
        this.editPower();
        const single = await this.getSingle(this.$route.params.id);
        this.input = model.setData(single);
        this.inputPage.departmentIds = this.input.departmentIds;
        this.inputPage.categoryId = this.CATEGORY.allCategory[0].id;
        this.departments = this.DEPARTMENT.cityDepartment;
      } else {
        // this.inputPage.departmentIds = [this.DEPARTMENT.cityDepartment[0].id];
        this.input.departmentIds = [this.DEPARTMENT.cityDepartment[0].id];
        this.input.departmentId = this.DEPARTMENT.cityDepartment[0].id;
      }
    },
    async searchItems(val) {
      this.loading = true;
      this.inputSearch.name = val;
      await this.getSearch(this.inputSearch);
      this.loading = false;
    },
    async offSearch() {
      this.search = false;
    },
    editPower() {
      let state = this.$power.check("admin_order_edit");
      if (state === false) {
        this.$router.push("/");
      }
    },
    async newItem(val) {
      // Если продукт есть в заказе то плюсуем, если нет добавляем в заказе
      let oId = await this.input.products.findIndex(
        (el) => el.productId === val.product.providerProductId
      );
      if (oId >= 0) {
        this.input.products[oId].amount += this.input.products[
          oId
        ].product.measure.coef;
      } else {
        // приходят совсем другие поля в запросе, поэтому тут переделываем объект продукта
        this.input.products.push({
          productId: val.product.providerProductId,
          image: val.product.images[0],
          product: {
            name: val.product.name,
            measure: {
              name: val.product.measureType,
              coef: val.product.measureStep,
            },
          },
          price: val.price,
          amount: 1,
          providerId: val.providerId,
        });
      }
      this.productKey++;
    },
    upProduct(index) {
      this.input.products[index].amount += this.input.products[
        index
      ].product.measure.coef;
      this.productKey++;
    },
    downProduct(index) {
      this.input.products[index].amount -= this.input.products[
        index
      ].product.measure.coef;
      this.productKey--;
      if (this.input.products[index].amount.toFixed(1) <= 0.0) {
        this.input.products.splice(index, 1);
      }
    },
    removeProduct(index) {
      this.input.products.splice(index, 1);
      this.productKey++;
    },
    async orderCreate() {
      this.loading = true;
      if (this.refValid) {
        let result;
        if (this.edit === false) {
          this.input.address.geo = this.map.coords.join();
          result = await this.addOrder(this.input);
        } else {
          result = await this.editOrder(this.input);
        }
        if (result.type !== "error") {
          this.$router.push("/");
          showMessage("Заказ успешно изменен", true);
        } else {
          showMessage(result.data.message);
        }
      } else {
        showMessage("Заполните все поля");
      }
      this.loading = false;
    },
    getStatus(val) {
      this.refValid = val;
      this.orderCreate();
    },
    changeDepartment(dep) {
      this.inputPage.departmentIds = [dep.id];
      this.inputPage.page = 0;
      this.inputPage.categoryId = this.CATEGORY.allCategory[0].id;
      this.inputSearch.page = 0;
      this.input.departmentId = dep.id;
      if (!this.edit) {
        this.input.paidForDelivery = dep.totalDeliveryPrice;
      }
    },
    async getAllDeps() {
      let promisArr = [];
      this.CITYES.allCity.forEach((city) => {
        let data = api.Department.departmentAll(city.id);
        promisArr.push(data);
      });
      let resolve = await Promise.allSettled(promisArr);
      resolve.forEach(
        (res) => (this.allDeps = this.allDeps.concat(res.value.data))
      );
    },
    // Изменение филиала у заказа
    async postponeOrder() {
      const input = {
        departmentId: this.selectedDepartment,
        orderId: this.$route.params.id,
      };
      let response = await this.editDepartment(input);
      if (response?.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("Филиал для заказа изменен", true);
        this.renderPage();
      }
    },
    async setDepartments() {
      this.loading = true;
      let response = await api.Address.findAddress({
        pointCoords: this.map.coords,
      });
      this.departments = response.data;
      this.inputPage.departmentIds = [response.data[0].id];
      this.loading = false;
    },
    // функции работы с картой, пока отключили, но не буду удалять могут и вернуть)
    mapClick(e) {
      this.map.coords = e.get("coords");
      ymaps.geocode(this.map.coords).then((result) => {
        let adress = result.geoObjects.get(0).properties.get("text");
        this.searchText = adress;
        this.input.address.street = this.getClearAddress(adress)[0];
        this.input.address.house = this.getClearAddress(adress)[1];
      });
      this.setDepartments();
    },
    getClearAddress(data) {
      // Уберем минус слова и если улицы двойные разделенные через запятую, то уберем её
      let replaces_text = [
        "Казахстан, ",
        this.CITYES.allCity[this.$root.city - 1].name + ", ",
        "Карагандинская область, ",
        "Нур-Султан (Астана), ",
      ];
      for (let i = 0; i < replaces_text.length; i++) {
        data = data.replace(replaces_text[i], "");
      }
      let arr = data.split(",");
      let home = [];
      home[1] = arr.pop(); // Потому что сначала получим дом, потом улицу
      home[0] = arr.pop();
      return home;
    },
    searchAddress() {
      let activeCityTitle = this.CITYES.allCity[this.$root.city - 1].name;
      ymaps.geocode(`${activeCityTitle}, ${this.searchText}`).then((result) => {
        let adress = result.geoObjects.get(0).properties.get("text");
        this.input.address.street = this.getClearAddress(adress)[0];
        this.input.address.house = this.getClearAddress(adress)[1];
        let userCoodinates = result.geoObjects.get(0).geometry.getCoordinates();
        this.map.coords = userCoodinates;
        this.setDepartments();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.dep-block {
  display: flex;
  gap: 10px;
  justify-content: right;
  width: 100%;
  align-items: center;
  &__select {
    max-width: 20% !important;
    @media screen and (max-width: 600px) {
      max-width: 100% !important;
    }
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}
.content_map {
  width: 100%;
  height: 400px;
  &__input {
    max-width: 350px;
  }
  &__search-block {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
  }
}
.ymap-container {
  border-radius: 10px;
  overflow: hidden;
  height: 320px;
  box-shadow: 0 0 15px rgb(170, 170, 170);
  @media screen and (max-width: 3000px) {
    min-height: 32vmax;
    height: 75%;
  }
  @media screen and (max-width: 1920px) {
    min-height: 29vmax;
    height: 70%;
  }
  @media screen and (max-width: 1440px) {
    min-height: 450px;
    height: 50%;
  }
  @media screen and (max-width: 1366px) {
    min-height: 320px;
    height: 30%;
  }
}
.help {
  font-weight: 400 !important;
  font-size: 14px;
}
</style>
