var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"80%"},on:{"click:outside":function($event){return _vm.$emit('update:exelModal', false)}},model:{value:(_vm.exelModal),callback:function ($$v) {_vm.exelModal=$$v},expression:"exelModal"}},[_c('v-card',{staticClass:"modal",attrs:{"dark":_vm.$dark.get()}},[_c('v-card-text',{staticClass:"modal__text"},[(_vm.loading)?_c('loader'):_vm._e(),_c('input',{ref:"exel",attrs:{"type":"file","hidden":"","accept":".xlsx"},on:{"change":function($event){return _vm.uploadExel($event)}}}),_c('input',{ref:"imagesInput",attrs:{"type":"file","multiple":"","hidden":"","accept":"image/*"},on:{"change":function($event){return _vm.uploadImages($event.target.files)}}}),_c('section',{staticClass:"btns"},[_c('div',{staticClass:"btns_column"},[_c('p',[_vm._v("Работа с товарами")]),_c('div',{staticClass:"btns_box"},[_c('v-btn',{staticClass:"btns_btn",attrs:{"color":"#5d4bd0","dark":""},on:{"click":function($event){return _vm.setApi('importUpdateFile')}}},[_vm._v(" Загрузить товары "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-tray-arrow-up")])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"#5d4bd0","dark":""},on:{"click":function($event){return _vm.$emit('insructions', 'products')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-variant-circle")])],1)]}}])},[_c('span',[_vm._v("Инcтрукция по загрузке")])])],1),_c('div',{staticClass:"btns_box"},[_c('v-btn',{staticClass:"btns_btn",attrs:{"color":"#5d4bd0","dark":""},on:{"click":function($event){return _vm.$emit('price-modal')}}},[_vm._v(" Загрузить цены ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"#5d4bd0","dark":""},on:{"click":function($event){return _vm.$emit('insructions', '')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-variant-circle")])],1)]}}])},[_c('span',[_vm._v("Инcтрукция по загрузке")])])],1),_c('div',{staticClass:"btns_box"},[_c('v-btn',{staticClass:"btns_btn",attrs:{"color":"#5d4bd0","dark":""},on:{"click":function($event){return _vm.setApi('uploadBarcodes')}}},[_vm._v(" Проверка на ШК ")])],1),_c('div',{staticClass:"btns_box"},[_c('v-btn',{staticClass:"btns_btn",attrs:{"color":"#5d4bd0","dark":""},on:{"click":function($event){return _vm.setApi('uploadDescription')}}},[_vm._v(" Обновление описания ")])],1)]),_c('div',{staticClass:"btns_column"},[_c('p',[_vm._v("Работа с картинками")]),_c('div',{staticClass:"btns_box"},[_c('v-btn',{staticClass:"btns_btn",attrs:{"color":"warning"},on:{"click":function($event){return _vm.$refs.imagesInput.click()}}},[_vm._v(" Загрузить картинки на сервер "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-tray-arrow-up")])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"#5d4bd0"},on:{"click":function($event){return _vm.$emit('insructions', 'img')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-variant-circle")])],1)]}}])},[_c('span',[_vm._v("Инcтрукция по загрузке")])])],1),_c('div',{staticClass:"btns_box"},[_c('v-btn',{staticClass:"btns_btn",attrs:{"color":"#5d4bd0","dark":""},on:{"click":function($event){return _vm.setApi('uploadImages')}}},[_vm._v(" Загрузить картинки к товарам ")])],1),_c('div',{staticClass:"btns_box"},[_c('v-btn',{staticClass:"btns_btn",attrs:{"color":"#5d4bd0","dark":""},on:{"click":function($event){return _vm.setApi('uploadImageToDelete')}}},[_vm._v(" Удалить картинки ")])],1)])])],1),_c('v-card-actions',{staticClass:"flex justify-end"},[_c('v-btn',{attrs:{"right":""},on:{"click":function($event){return _vm.$emit('update:exelModal', false)}}},[_vm._v("Закрыть")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }