<template>
  <section class="test">
    <div class="test_top">
      <v-btn color="#5d4bd0" dark icon @click="$emit('hide')">
        <v-icon left>mdi-arrow-left-circle</v-icon>
      </v-btn>
      <p>
        Название теста:
        <span class="test_name">{{ test.educationalTests[0].name }}</span>
      </p>
      <v-btn
        color="#5d4bd0"
        dark
        small
        @click="
          redactingModal = true;
          editingTest = JSON.parse(JSON.stringify(test));
        "
        >Редактировать</v-btn
      >
    </div>
    <div class="test_bottom">
      <div>
        <div
          v-for="(item, i) in test.educationalTests[0].educationalTestQuestions"
          :key="item.id"
        >
          <p class="test_question">{{ i + 1 }}.{{ item.question }} ?</p>
          <ul class="test_ul">
            <li class="test_answer right">{{ item.rightAnswer }}</li>
            <li class="test_answer">{{ item.wrongAnswerOne }}</li>
            <li class="test_answer">{{ item.wrongAnswerTwo }}</li>
            <li class="test_answer">{{ item.wrongAnswerThree }}</li>
          </ul>
          <div class="test_question__btns">
            <v-btn color="#5d4bd0" dark icon @click="editQuestion(item)"
              ><v-icon>mdi-pencil</v-icon></v-btn
            >
            <v-btn color="error" icon @click="deleteQuestion(item.id)"
              ><v-icon>mdi-trash-can</v-icon></v-btn
            >
          </div>
        </div>
      </div>
      <div class="test_new-question">
        <p>
          {{
            editingQuestion
              ? "Редактирование вопроса"
              : "Создание нового вопроса"
          }}
        </p>
        <v-form ref="questionForm" class="test-form">
          <v-text-field
            v-model.trim="question.question"
            type="text"
            label="Введите вопрос"
            class="header_input"
            :rules="[$validate.required]"
          ></v-text-field>
          <v-text-field
            v-model.trim="question.rightAnswer"
            type="text"
            label="Укажите ответ"
            class="header_input"
            :rules="[$validate.required]"
          ></v-text-field>
          <v-text-field
            v-model.trim="question.wrongAnswerOne"
            type="text"
            label="Неверный ответ 1"
            class="header_input"
            :rules="[$validate.required]"
          ></v-text-field>
          <v-text-field
            v-model.trim="question.wrongAnswerTwo"
            type="text"
            label="Неверный ответ 2"
            class="header_input"
            :rules="[$validate.required]"
          ></v-text-field>
          <v-text-field
            v-model.trim="question.wrongAnswerThree"
            type="text"
            label="Неверный ответ 3"
            class="header_input"
            :rules="[$validate.required]"
          ></v-text-field>
          <v-btn
            v-if="editingQuestion"
            style="margin-right: 10px"
            @click="editingQuestion = false"
            >Отмена</v-btn
          >
          <v-btn
            color="#5d4bd0"
            dark
            @click="editingQuestion ? updateQuestion() : addQuestion()"
            >{{
              editingQuestion ? "Изменить вопрос" : "Добавить вопрос"
            }}</v-btn
          >
        </v-form>
      </div>
    </div>
    <v-dialog v-model="redactingModal" activator="parent" width="50%">
      <v-card v-if="editingTest" class="modal" :dark="$dark.get()">
        <v-card-title class="flex justify-center modal__title">
          Редактирование
        </v-card-title>
        <v-card-text>
          <v-form ref="testForm" class="test-form">
            <v-text-field
              v-model.trim="editingTest.videoUrl"
              type="text"
              label="Ссылка на видео"
              class="header_input"
              :rules="[$validate.required]"
            ></v-text-field>
            <v-text-field
              v-model.trim="editingTest.name"
              type="text"
              label="Название видео"
              class="header_input"
              :rules="[$validate.required]"
            ></v-text-field>
            <v-text-field
              v-model.trim="editingTest.educationalTests[0].name"
              type="text"
              label="Название теста"
              class="header_input"
              :rules="[$validate.required]"
            ></v-text-field>
            <v-select
              v-model="editingTest.role.id"
              label="Для какой роли тест"
              :items="ROLE.allRoles"
              item-value="id"
              item-text="name"
              class="header_input"
              :rules="[$validate.required]"
            ></v-select>
            <v-select
              v-model="editingTest.department.id"
              label="Для какого филиала тест"
              :items="DEPARTMENT.cityDepartment"
              item-value="id"
              item-text="name"
              class="header_input"
              :rules="[$validate.required]"
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions class="modal__actions">
          <v-btn @click="redactingModal = false">Закрыть</v-btn>
          <v-btn color="#5d4bd0" dark @click="editTest()">Изменить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import ShowMessage from "@/Functions/message";
import api from "@/api";
export default {
  name: "EditTest",
  props: {
    test: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      question: {},
      redactingModal: false,
      editingQuestion: false,
      editingTest: null,
    };
  },
  computed: {
    ...mapGetters({
      DEPARTMENT: "Department/STATE",
      ROLE: "Role/STATE",
    }),
  },
  watch: {
    editingQuestion(val) {
      if (!val) {
        this.question = {};
        this.$refs.questionForm.resetValidation();
      }
    },
  },
  methods: {
    async addQuestion() {
      if (!this.$refs.questionForm.validate()) {
        ShowMessage("Заполните все поля");
        return;
      }
      this.question.educationalTestId = this.test.educationalTests[0].id;
      const response = await api.Education.addQuestion(this.question);
      if (response.type === "error") {
        ShowMessage(response.data.message);
        return;
      }
      ShowMessage("Вопрос успешно добавлен", true);
      this.test.educationalTests[0].educationalTestQuestions.push(response);
      this.$refs.questionForm.resetValidation();
      this.question = {};
    },
    async deleteQuestion(id) {
      const response = await api.Education.deleteQuestion(id);
      if (response.type === "error") {
        ShowMessage(response.data.message);
        return;
      }
      this.test.educationalTests[0].educationalTestQuestions = this.test.educationalTests[0].educationalTestQuestions.filter(
        (question) => question.id !== id
      );
    },
    editQuestion(question) {
      this.editingQuestion = true;
      this.question = { ...question };
    },
    async updateQuestion() {
      if (!this.$refs.questionForm.validate()) {
        ShowMessage("Заполните все поля");
        return;
      }
      const response = await api.Education.editQuestion(this.question);
      if (response.type === "error") {
        ShowMessage(response.data.message);
        return;
      }
      ShowMessage("Вопрос успешно изменен", true);
      const index = this.test.educationalTests[0].educationalTestQuestions.findIndex(
        (question) => question.id === this.question.id
      );
      this.test.educationalTests[0].educationalTestQuestions.splice(
        index,
        1,
        response
      );
      this.editingQuestion = false;
    },
    async editTest() {
      if (!this.$refs.testForm.validate()) {
        ShowMessage("Заполните все поля");
        return;
      }
      const payloadVideo = {
        departmentId: this.editingTest.department.id,
        name: this.editingTest.name,
        videoUrl: this.editingTest.videoUrl,
        videoId: this.editingTest.id,
        roleId: this.editingTest.role.id,
        position: this.editingTest.position,
      };
      const responseVideo = await api.Education.editVideo(payloadVideo);
      if (
        this.editingTest.educationalTests[0].name !==
        this.test.educationalTests[0].name
      ) {
        const payloadTest = {
          educationalVideoId: this.editingTest.id,
          id: this.editingTest.educationalTests[0].id,
          name: this.editingTest.educationalTests[0].name,
        };
        const responseTest = await api.Education.editTest(payloadTest);
      }
      this.$emit("update");
      this.$emit("hide");
    },
  },
};
</script>

<style lang="scss" scoped>
.test {
  &_name {
    font-size: large;
    font-weight: bold;
  }
  &_question {
    font-weight: bold;
    margin: 0;
  }
  &_ul {
    margin-bottom: 10px;
  }
  &_bottom {
    display: flex;
    justify-content: space-between;
    padding: 0 50px;
  }
  &_top {
    display: flex;
    align-items: center;
    gap: 20px;
    p {
      margin: 0;
    }
  }
  &_new-question {
    width: 300px;
  }
}
.right {
  color: green;
}
</style>
