<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-card
        dark
        class="elevation-12"
        max-width="500px"
        width="100%"
        min-width="300px"
      >
        <v-toolbar color="#5d4bd0" dark>
          <v-toolbar-title>АСТОР</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="downloadForm" class="login">
            <v-text-field
              v-model="input.login"
              label="Логин"
              name="login"
              prepend-icon="mdi-account"
              type="text"
              :rules="[$validate.required]"
            ></v-text-field>
            <v-text-field
              v-model="input.password"
              label="Пароль"
              name="password"
              prepend-icon="mdi-lock"
              type="password"
              :rules="[$validate.required]"
            ></v-text-field>
            <v-file-input
              v-model="input.file"
              label="Файл"
              name="file"
              prepend-icon="mdi-file"
              :rules="[$validate.required, $validate.file]"
            ></v-file-input>
          </v-form>
        </v-card-text>
        <v-card-actions class="pl-16 pr-16 pb-6">
          <v-btn block color="#5d4bd0" dark @click="download">
            загрузить файл
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      input: {
        password: "",
        login: "",
        file: null,
      },
    };
  },
  methods: {
    ...mapActions({
      downloadFile: "Astor/DOWNLOAD_FILE",
    }),
    async download() {
      if (this.$refs.downloadForm.validate()) {
        let response = await this.downloadFile(this.input);
        if (response.type === "success") {
          this.$notify({
            group: "app",
            type: "success",
            title: "Успех",
            text: "Файл успешно загружен",
          });
        } else {
          this.$notify({
            group: "app",
            type: "error",
            title: "Ошибка",
            text: "Вы неправильно ввели логин или пароль",
          });
        }
      }
    },
  },
};
</script>
