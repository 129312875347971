<template>
  <v-card>
    <v-card-title class="header"
      >Как работать в админ панели-системы AyanMarket</v-card-title
    >
    <v-card-text class="work">
      <v-list class="work_list">
        <!-- группы/заголовки-->
        <div
          v-for="group in itemsGroup"
          :key="group.title"
          :class="activeGroup === group.title && 'active-div'"
          @click="activeGroup = group.title"
        >
          <v-list-group no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="work-routes">{{
                  group.title
                }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-icon
              slot="appendIcon"
              :color="activeGroup === group.title ? 'white' : 'black'"
              >mdi-chevron-down</v-icon
            >
            <!-- подзаголовки-->
            <div
              v-for="subtitle in group.titleList"
              :key="subtitle.subtitle"
              class="work_item"
              :class="
                activeSubtitle.subtitle === subtitle.subtitle && 'active-item'
              "
            >
              <v-list-item @click="activeSubtitle = subtitle">
                <v-list-item-content>
                  <v-list-item-title class="item-route">{{
                    subtitle.subtitle
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list-group>
        </div>
      </v-list>
      <!-- информация из подзаголовков -->
      <div class="presentation">
        <p class="presentation_title">{{ activeSubtitle.subtitle }}</p>
        <p v-for="(text, i) in activeSubtitle.info" :key="i" ref="word">
          {{ makeText(text, i) }}
        </p>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import data from "./data";
export default {
  data() {
    return {
      activeGroup: "",
      activeSubtitle: "",
      itemsGroup: [],
    };
  },

  created() {
    this.itemsGroup = data;
    if (!this.itemsGroup[0]) return;
    this.activeGroup = this.itemsGroup[0].title;
    this.activeSubtitle = this.itemsGroup[0].titleList[0];
  },
  methods: {
    makeText(obj, index) {
      setTimeout(() => {
        let text = obj.text.split(" ");
        let html = "";
        text.forEach((word, i) => {
          if (obj.bold.includes(i)) {
            html += `<span class='bold-text'>${word} </span>`;
          } else {
            html += `${word} `;
          }
          this.$refs.word[index].innerHTML = html;
        });
      }, 200);
    },
  },
};
</script>
<style lang="scss">
.header {
  background: linear-gradient(to right, #e93e7b 50%, white 70%);
  margin-left: -12px;
  color: white;
  padding: 5px 10px;
}
.work {
  display: flex;
  justify-content: space-between;
  &_list {
    width: 30%;
  }
  &_item {
    margin: 3px 0 0 30px;
  }
}
.presentation {
  width: 50%;
  margin-top: 20px;
  &_title {
    font-weight: 700;
    color: black;
    font-size: 20px;
  }
}
.active-div {
  .v-list-group__header {
    background-color: #5d4bd0 !important;
    border-radius: 15px;
  }
  .work-routes {
    color: white;
  }
}
.active-item {
  background-color: #5d4bd0;
  border-radius: 15px;
  .item-route {
    color: white;
  }
}
.item-route {
  color: black;
}
.work-routes {
  color: black;
}
.bold-text {
  font-weight: bold;
  color: black;
}
</style>
