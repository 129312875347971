<template>
  <v-card :dark="$dark.get()">
    <loader v-if="loading" />
    <v-card-title class="header"> Добавить товар </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <div class="section">
        <h2>Мой товар уже есть на AyanMarket</h2>
        <span>Если ваш товар уже продается на AyanMarket, то Вам нужно: </span>
        <ul>
          <li>
            Ввести в поле поиска наименование товара в виде
            бренд-название-модель-цвет
          </li>
          <li>Нажать на поиск и выбрать подходящий товар</li>
          <li>Добавить ваш артикул и стоимость (должна включать НДС)</li>
          <li>Товар после этого будет выставлен на продажу в течении часа</li>
        </ul>
      </div>
      <v-text-field
        v-model="findTextByName"
        label="Поиск по названию..."
        :hint="findTextByName ? '' : 'Например: apple iPhone 13 черный'"
        class="search-input"
        @keyup.enter="findProducts()"
        @input="checkText($event)"
      >
        <v-btn
          v-if="findTextByName"
          slot="append"
          color="red"
          icon
          @click="clearInput()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn slot="append" color="#5d4bd0" icon @click="findProducts()">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-text-field>
      <!-- таблица с найденными проудктами -->
      <p v-if="noFindProducts">По данному запросу ничего не найдено</p>
      <v-simple-table v-if="products.length">
        <thead>
          <tr>
            <th>Товар</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in products" :key="item.id">
            <td>
              <p v-if="item.images ? item.images.length === 0 : true">?</p>
              <v-img
                v-else
                width="80px"
                height="80px"
                :src="item.images[0].fileUrl"
              />
            </td>
            <td class="name">{{ item.name }}</td>
            <td>
              <v-btn
                color="#5d4bd0"
                dark
                small
                @click="addProductToProvider(item.id)"
              >
                Выбрать
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-pagination
        v-if="pages.count > 1"
        :value="pages.current + 1"
        :dark="$dark.get()"
        :total-visible="7"
        :length="pages.count"
        color="#5d4bd0"
        @input="changePage($event - 1)"
      ></v-pagination>
      <v-divider></v-divider>

      <div class="section">
        <h2>Новый товар вручную</h2>
        <p>
          Для создания нового товара, достаточно выбрать категорию, заполнить
          основные характеристики, загрузить фото. Перед отправкой убедитесь что
          все данные корректны (артикул, цена и т.д)
        </p>
        <v-btn
          color="#5d4bd0"
          dark
          small
          @click="$router.push(`/products/create`)"
        >
          Создать новый товар
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import loader from "@/components/Loader";
import showMessage from "@/Functions/message";
import api from "@/api";

export default {
  components: { loader },
  data() {
    return {
      loading: false,
      findTextByName: "",
      body: {
        pageSize: 50,
        page: 0,
      },
      pages: {
        current: 0,
        count: 0,
      },
      products: [],
      noFindProducts: false,
    };
  },
  computed: {
    ...mapGetters({
      CATEGORY: "Category/PRODUCTS_CATEGORY",
    }),
  },
  async created() {},
  methods: {
    ...mapActions({
      getDepartment: "Department/GET_DEPARTMENT",
    }),
    async findProducts() {
      if (!this.findTextByName) {
        return;
      }
      let request = {
        ...this.body,
        name: this.findTextByName,
      };
      this.loading = true;
      const response = await api.Products.findMVPProductWithCategoryByName(
        request
      );
      this.products = response.content;
      this.pages.current = response.number;
      this.pages.count = response.totalPages;
      this.loading = false;
      if (response.content.length === 0) {
        this.noFindProducts = true;
      } else {
        this.noFindProducts = false;
      }
    },
    async addProductToProvider(productId) {
      this.loading = true;
      const request = {
        providerId: this.$route.params.providerId,
        body: [productId],
      };
      const response = await api.Products.addProductToProvider(request);
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("Продукт добавлен в ваш список товаров", true);
      }
      this.loading = false;
    },
    clearInput() {
      this.findTextByName = "";
      this.noFindProducts = false;
      this.products = [];
      this.pages.current = 0;
      this.pages.count = 0;
    },
    // если убрали весь текст из ввода возвращает исходные продукты
    checkText(text) {
      if (!text) {
        this.clearInput();
      }
    },
    // меняет страницу в зависимости от режима
    changePage(page) {
      this.body.page = page;
      this.findProducts();
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  background: linear-gradient(to right, #e93e7b 50%, white 70%);
  margin-left: -12px;
  color: white;
  padding: 5px 10px;
}
.search-input {
  max-width: 40%;
}
.name {
  color: #4395d5;
}
.section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-top: 20px;
  h2 {
    color: black;
  }
}
</style>
