<template>
  <v-card :dark="$dark.get()" elevation="1">
    <loader v-if="loading"></loader>
    <v-card-text v-if="product" class="card">
      <div v-for="(item, i) in product" :key="i" class="product">
        <p class="product_title">{{ i === 0 ? "До изменений" : "После" }}</p>
        <div class="product_item">
          <p class="product_item__top">Название</p>
          <p class="product_item__bot">{{ item.name }}</p>
        </div>
        <div class="product_item">
          <p class="product_item__top">Описание</p>
          <p class="product_item__bot">{{ item.description }}</p>
        </div>
        <div class="product_item">
          <p class="product_item__top">Короткое описание</p>
          <p class="product_item__bot">{{ item.shortDescription }}</p>
        </div>
        <div class="product_item">
          <p class="product_item__top">
            Штрихкод<span v-if="item.barcodes.length > 1">ы</span>
          </p>
          <p
            v-for="barcode in item.barcodes"
            :key="barcode"
            class="product_item__bot"
          >
            {{ barcode }}
          </p>
        </div>
        <p>Характеристики</p>
        <div
          v-for="feature in item.features"
          :key="feature.featureName"
          class="product_item"
        >
          <p class="product_item__top">{{ feature.featureName }}</p>
          <p
            v-for="val in feature.featureValue"
            :key="val.value"
            class="product_item__bot"
          >
            {{ val.value }}
          </p>
        </div>
      </div>
      <div v-for="(item, i) in product" :key="i + 'img'" class="image">
        <p class="product_title">{{ i === 0 ? "До изменений" : "После" }}</p>
        <img v-for="img in item.imageUrls" :key="img" :src="img" width="300" />
      </div>
    </v-card-text>
    <v-card-actions class="actions">
      <v-btn color="#5d4bd0" dark @click="moderate('APPROVED')">
        Одобрить
      </v-btn>
      <v-btn color="#5d4bd0" dark @click="showCommentDialog = true">
        Отклонить
      </v-btn>
    </v-card-actions>
    <v-dialog v-model="showCommentDialog" activator="parent" width="70%">
      <v-card class="modal" :dark="$dark.get()">
        <v-card-title class="flex justify-center">
          Укажите причину отказа в публикации
        </v-card-title>
        <v-card-text>
          <v-form ref="rejectForm">
            <v-textarea
              v-model="comment"
              :rules="[$validate.required]"
              label="Причина отказа"
              auto-grow
              clearable
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions class="modal__actions">
          <v-btn color="#5d4bd0" dark @click="checkComment()">Подвердить</v-btn>
          <v-btn @click="showCommentDialog = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import ShowMessage from "@/Functions/message";
import Loader from "@/components/Loader";

export default {
  components: {
    Loader,
  },
  data() {
    return {
      loading: true,
      product: null,
      showCommentDialog: false,
      comment: "",
    };
  },
  watch: {
    showCommentDialog(val) {
      if (!val) {
        this.comment = "";
        this.$refs.rejectForm.resetValidation();
      }
    },
  },
  async created() {
    this.setProduct();
  },
  methods: {
    ...mapActions({
      GET_PRODUCT_DIFFERENCE: "Products/GET_PRODUCT_DIFFERENCE",
      MODERATE_PRODUCTS: "Products/MODERATE_PRODUCTS",
    }),
    async setProduct() {
      let response = await this.GET_PRODUCT_DIFFERENCE(this.$route.params.id);
      if (response) {
        this.product = [response.before, response.after];
      }
      this.loading = false;
    },
    async moderate(status) {
      this.loading = true;
      const obj = {
        comment: this.comment,
        moderationStatus: status,
        productId: this.product[0].providerProductId,
        providerId: this.product[0].providerId,
      };
      const response = await this.MODERATE_PRODUCTS([obj]);
      if (response.type === "error") {
        ShowMessage(response.data.message);
      } else {
        ShowMessage(`Статус продукта изменен`, true);
        this.$router.push("/products-moderation");
        this.showCommentDialog = false;
        return;
      }
      this.loading = false;
    },
    checkComment() {
      if (this.$refs.rejectForm.validate()) {
        this.moderate("REJECTED");
      } else {
        ShowMessage("Укажите причину отказа");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  gap: 30px;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
}
.product {
  &_title {
    background-color: #f2f4fa;
    color: #5d4bd0;
    border-radius: 8px;
    font-weight: bold;
    font-size: 16px;
    padding: 5px 15px;
  }
  &_item {
    padding: 5px 15px;
    border-radius: 8px;
    background-color: #f2f4fa;
    margin-bottom: 10px;
    &__top {
      margin-bottom: 0 !important;
      font-size: 12px;
    }
    &__bot {
      margin-bottom: 0 !important;
      font-weight: bold;
      color: black;
      font-size: 16px;
    }
  }
}
.image {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}
.actions {
  display: flex;
  justify-content: right;
}
</style>
