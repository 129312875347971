<template>
  <div v-if="loading" class="loader">
    <loader></loader>
  </div>
  <v-card v-else fluid :dark="$dark.get()" class="modal">
    <v-card-title>{{ mode }} филиал</v-card-title>
    <v-card-text style="padding-top: 2.5px">
      <v-row>
        <v-col class="d-flex justify-center align-center">
          <v-text-field
            v-model="searchText"
            color="#4caf50"
            label="Поиск по карте"
            single-line
            clearable
            hide-details
            prepend-inner-icon="mdi-magnify"
            class="dis-header__rounded"
            @click:clear="searchText = ''"
          />
          <v-btn color="#4caf50" dark class="ml-2" @click="searchAddress">
            Найти
          </v-btn>
        </v-col>
        <v-col cols="12" class="pt-0">
          <yandex-map
            :controls="map.controls"
            :zoom="map.zoom"
            :settings="mapSettings"
            :coords="map.coords"
            :options="map.options"
            @click="mapClick"
            @map-was-initialized="initMap"
          >
            <ymap-marker
              v-if="map.MarkerVisible"
              :coords="map.coords"
              marker-id="index"
            />
          </yandex-map>
        </v-col>
      </v-row>
      <p>Введите адрес филиала вручную или выберите на карте</p>
    </v-card-text>
    <v-form ref="form" lazy-validation @submit.prevent="submitForm()">
      <v-text-field
        v-model="input.name"
        :rules="[$validate.required]"
        label="Название филиала"
        required
        clearable
      ></v-text-field>
      <v-text-field
        v-model="adressInp.street"
        :rules="[$validate.required]"
        label="Улица"
        required
        clearable
      ></v-text-field>
      <v-text-field
        v-model="adressInp.house"
        :rules="[$validate.required]"
        label="Дом"
        required
        clearable
      ></v-text-field>
      <div class="checkboxes">
        <v-checkbox
          v-model="input.selfZoned"
          label="Со своими курьерами"
        ></v-checkbox>
        <v-checkbox
          v-model="input.isAyan"
          label="Принадлежит Аяну"
        ></v-checkbox>
      </div>
      <v-card-actions class="flex justify-end">
        <v-btn right @click="$router.replace('/department')">Отмена</v-btn>
        <v-btn color="#5d4bd0" dark type="submit">{{ mode }}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "@/components/Loader";
import showMessage from "@/Functions/message";
import api from "@/api";
export default {
  components: {
    Loader,
  },
  data() {
    return {
      myMap: {},
      loading: true,
      mode: "Добавить",
      input: {
        name: "",
        scheduleId: 1,
        addressId: 0,
        cityId: 0,
        selfZoned: false,
        isAyan: false,
        delete: false,
      },
      firstData: {},
      adressInp: {
        street: "",
        house: "",
        addressTypeId: 2,
      },
      map: {
        zoom: 15,
        coords: [49.807754, 73.088504],
        controls: ["geolocationControl", "zoomControl"],
        MarkerVisible: true,
        options: {
          restrictMapArea: false,
        },
      },
      mapSettings: {
        apiKey: "4ca540e5-4dd9-43dd-b029-5d4a1097eec8",
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
      },
      searchText: "",
    };
  },
  computed: {
    ...mapGetters({
      ADDRESS: "Address/STATE",
      CiTY: "City/STATE",
    }),
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapActions({
      addDepartment: "Department/ADD_DEPARTMENT",
      getDepartment: "Department/GET_SINGLE",
      editDepartment: "Department/EDIT_DEPARTMENT",
    }),
    async getData() {
      this.loading = true;
      if (this.$route.params.id) {
        let response = await this.getDepartment(this.$route.params.id);
        if (response.selfZoned === null) {
          response.selfZoned = false;
        }
        this.firstData = JSON.parse(JSON.stringify(response));
        this.mode = "Изменить";
        this.input = response;
        this.adressInp.street = response.address.street;
        this.adressInp.house = response.address.house;
        this.map.coords = this.firstData.address.geo.split(",");
      }
      this.loading = false;
    },
    async addButton() {
      this.loading = true;
      let adressId = await this.getAdressId();
      this.input.addressId = adressId;
      this.input.cityId = this.$root.city;
      let response = await this.addDepartment(this.input);
      if (response?.type !== "error") {
        this.$router.replace("/department");
      } else {
        showMessage(response.data.message);
      }
      this.loading = false;
    },
    async editButton() {
      if (!this.checkData()) {
        return;
      }
      this.loading = true;
      let adressId = await this.getAdressId();
      let payload = {
        id: this.input.id,
        name: this.input.name,
        scheduleId: 1,
        addressId: adressId,
        cityId: this.$root.city,
        selfZoned: this.input.selfZoned,
        isAyan: this.input.isAyan,
        delete: this.input.delete,
      };
      let response = await this.editDepartment(payload);
      if (response?.type !== "error") {
        this.$router.replace("/department");
      } else {
        showMessage(response.data.message);
      }
      this.loading = false;
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        if (this.$route.params.id) {
          this.editButton();
        } else {
          this.addButton();
        }
      }
    },
    //Вспомогательная функция получает id адреса
    async getAdressId() {
      let response = await api.Address.addAddress(this.adressInp);
      return response.data.id;
    },
    //Вспомогательная функция проверяет изменил ли пользователь какие либо данные
    checkData() {
      if (
        this.firstData.name === this.input.name &&
        this.firstData.selfZoned === this.input.selfZoned &&
        this.firstData.address.house === this.adressInp.house &&
        this.firstData.address.street === this.adressInp.street &&
        this.firstData.isAyan === this.input.isAyan
      ) {
        showMessage("Никакие данные не изменены");
        return false;
      }
      return true;
    },
    initMap(map) {
      this.myMap = map;
    },
    mapClick(e) {
      this.map.coords = e.get("coords");
      ymaps.geocode(this.map.coords).then((result) => {
        let adress = result.geoObjects.get(0).properties.get("text");
        this.searchText = adress;
        this.adressInp.street = this.getClearAddress(adress)[0];
        this.adressInp.house = this.getClearAddress(adress)[1];
      });
    },
    getClearAddress(data) {
      // Уберем минус слова и если улицы двойные разделенные через запятую, то уберем её
      let replaces_text = [
        "Казахстан, ",
        this.CiTY.allCity[this.$root.city].name + ", ",
        "Карагандинская область, ",
        "Нур-Султан (Астана), ",
      ];
      for (let i = 0; i < replaces_text.length; i++) {
        data = data.replace(replaces_text[i], "");
      }
      let arr = data.split(",");
      let home = [];
      home[1] = arr.pop(); // Потому что сначала получим дом, потом улицу
      home[0] = arr.pop();
      return home;
    },
    searchAddress() {
      let activeCityTitle = this.CiTY.allCity[this.$root.city - 1].name;
      ymaps.geocode(`${activeCityTitle}, ${this.searchText}`).then((result) => {
        let adress = result.geoObjects.get(0).properties.get("text");
        this.adressInp.street = this.getClearAddress(adress)[0];
        this.adressInp.house = this.getClearAddress(adress)[1];
        let userCoodinates = result.geoObjects.get(0).geometry.getCoordinates();
        this.map.coords = userCoodinates;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.checkboxes {
  display: flex;
  gap: 20px;
}
.modal {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__text {
    padding: 0;
    margin: 0;
  }
}
.ymap-container {
  @media screen and (max-width: 3000px) {
    min-height: 32vmax;
    height: 75%;
  }
  @media screen and (max-width: 1920px) {
    min-height: 29vmax;
    height: 70%;
  }
  @media screen and (max-width: 1440px) {
    min-height: 450px;
    height: 50%;
  }
  @media screen and (max-width: 1366px) {
    min-height: 320px;
    height: 30%;
  }
}
</style>
