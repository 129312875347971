import request from "../core/request";

export default {
  deleteAction(id) {
    return request.execute("delete", `admin/promotions/${id}`);
  },
  createAction(input) {
    return request.execute("post", "admin/promotions", input);
  },
  editAction(input) {
    return request.execute("put", "admin/promotions", input);
  },
  getActions(body) {
    return request.execute("post", `admin/promotions/filter`, body);
  },
  addImage(body) {
    return request.execute(
      "post",
      `admin/promotions/add/image/${body.id}`,
      body.file
    );
  },
  editImage(body) {
    return request.execute(
      "post",
      `admin/promotions/add/image/${body.id}`,
      body.file
    );
  },
};
