<template>
  <div>
    <loader v-if="loading"></loader>
    <input
      ref="excelFile"
      type="file"
      hidden
      accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      @change="importStackTable($event)"
    />

    <clients-list-modal
      :download="downloadFile"
      :show-modal="showModal"
      :get-list="getList()"
    />

    <!-- INSTRUCTION FOR EXCEL FILES -->
    <instruction-modal-comp :instruction-modal.sync="instructionModal" />

    <!-- ADD STACK -->
    <overlay-form
      :overlay-title="'Добавить Тип оборудования'"
      :form-type="'add'"
      :modal-state="addStackModal"
      @close-modal="addStackModal = false"
      @submit-button="addNewStack()"
    >
      <div>
        <v-text-field
          v-model.number="input.stackNumber"
          type="number"
          label="№ места хранения"
          :rules="[$validate.required]"
        />
        <v-select
          v-model="input.stackTypeCodeName"
          :items="STACKSYSTEM.stackTypes"
          item-text="name"
          item-value="codeName"
          label="Тип оборудования"
          :rules="[$validate.required]"
        />
        <v-select
          v-model="input.departmentId"
          :items="departments"
          item-text="name"
          item-value="id"
          label="id Филиала"
        />
      </div>
    </overlay-form>

    <!-- EDIT STACK -->
    <overlay-form
      :overlay-title="'Редактировать Тип оборудования'"
      :form-type="'edit'"
      :modal-state="editStackModal"
      @close-modal="editStackModal = false"
      @submit-button="editStack()"
    >
      <div>
        <v-text-field
          v-model.number="input.stackNumber"
          type="number"
          label="№ места хранения"
          :rules="[$validate.required]"
        />
        <v-select
          v-model="input.stackTypeCodeName"
          :items="STACKSYSTEM.stackTypes"
          item-text="name"
          item-value="codeName"
          label="Тип оборудования"
        />
        <v-select
          v-model="input.departmentId"
          :items="departments"
          item-text="name"
          item-value="id"
          label="id Филиала"
        />
      </div>
    </overlay-form>

    <!-- ADD SHELF -->
    <overlay-form
      :overlay-title="'Добавить Полку'"
      :form-type="'add'"
      :modal-state="addShelfModal"
      @close-modal="addShelfModal = false"
      @submit-button="addNewShelf()"
    >
      <div>
        <v-text-field
          v-model.number="input.stackId"
          label="id типа оборудования"
          :rules="[$validate.required]"
        />
        <v-text-field
          v-model.number="input.shelfNumber"
          type="number"
          label="№ Полки"
          :rules="[$validate.required]"
        />
      </div>
    </overlay-form>

    <!-- EDIT SHELF -->
    <overlay-form
      :overlay-title="'Редактировать Полку'"
      :form-type="'edit'"
      :modal-state="editShelfModal"
      @close-modal="editShelfModal = false"
      @submit-button="editShelf()"
    >
      <div>
        <v-text-field
          v-model.number="input.stackId"
          label="id типа оборудования"
        />
        <v-text-field
          v-model.number="input.shelfNumber"
          type="number"
          label="№ Полки"
        />
      </div>
    </overlay-form>

    <!-- HEADER -->
    <v-container fluid>
      <v-card flat :dark="$dark.get()" class="stack-system__header">
        <v-container fluid>
          <v-row align="center">
            <!-- список филиалов -->
            <v-col cols="2">
              <v-select
                v-model="stackFindProps.departmentId"
                class="stack-system__title"
                label="Филиал"
                :items="departments"
                item-text="name"
                item-value="id"
                @input="loadStacks(stackFindProps.departmentId)"
              ></v-select>
            </v-col>

            <v-col>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="d-none d-md-flex"
                    elevation="0"
                    color="warning"
                    v-bind="attrs"
                    v-on="on"
                    @click="$refs.excelFile.click()"
                  >
                    <v-icon>mdi-tray-arrow-up</v-icon>
                    <div class="ml-2">Импорт</div>
                  </v-btn>
                </template>
                <span>Загрузить таблицу стеллажей на сервер</span>
              </v-tooltip>
              <!-- кнопка для маленького экрана -->
              <v-btn
                icon
                class="d-flex d-md-none"
                color="warning"
                @click="$refs.excelFile.click()"
              >
                <v-icon>mdi-tray-arrow-up</v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="creatingTable"
                    class="d-none d-md-flex"
                    elevation="0"
                    color="warning"
                    v-bind="attrs"
                    v-on="on"
                    @click="exportStackTable()"
                  >
                    <v-icon>mdi-tray-arrow-down</v-icon>
                  </v-btn>
                </template>
                <span>Создать таблицу стеллажей с филиалом</span>
              </v-tooltip>
              <!-- кнопка для маленького экрана -->
              <v-btn
                icon
                class="d-flex d-md-none"
                color="warning"
                :disabled="creatingTable"
                @click="exportStackTable()"
              >
                <v-icon>mdi-tray-arrow-down</v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="d-none d-md-flex"
                    color="#5d4bd0"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="instructionModal = true"
                  >
                    <v-icon>mdi-information-outline</v-icon>
                    <div class="ml-2">инструкция</div>
                  </v-btn>
                </template>
                <span>Инструкция по заполнению таблицы стеллажей</span>
              </v-tooltip>
              <!-- кнопка для маленького экрана -->
              <v-btn
                icon
                class="d-flex d-md-none"
                color="#5d4bd0"
                @click="instructionModal = true"
              >
                <v-icon>mdi-information-outline</v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="d-none d-md-flex"
                    color="#5d4bd0"
                    v-bind="attrs"
                    v-on="on"
                    @click="goToExcludedQueuePage()"
                  >
                    <v-icon>mdi-tray-full</v-icon>
                  </v-btn>
                </template>
                <span>Очередь ислкючений категорий</span>
              </v-tooltip>
              <!-- кнопка для маленького экрана -->
              <v-btn
                icon
                class="d-flex d-md-none"
                color="#5d4bd0"
                @click="goToExcludedQueuePage()"
              >
                <v-icon>mdi-tray-full</v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="d-none d-md-flex"
                    color="#5d4bd0"
                    v-bind="attrs"
                    v-on="on"
                    @click="showModal = !showModal"
                  >
                    <v-icon>mdi-format-list-numbered</v-icon>
                  </v-btn>
                </template>
                <span>Список готовых таблиц</span>
              </v-tooltip>
              <!-- кнопка для маленького экрана -->
              <v-btn
                icon
                class="d-flex d-md-none"
                color="#5d4bd0"
                @click="showModal = !showModal"
              >
                <v-icon>mdi-format-list-numbered</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>

    <!-- BODY -->
    <v-container fluid>
      <v-card flat :dark="$dark.get()" class="stack-system__container">
        <v-row class="mt-1">
          <v-col cols="3">
            <div class="text-center">
              <v-chip color="primary" style="font-size: 1.05rem" label>
                Тип оборудования
              </v-chip>
              <v-btn
                icon
                color="success"
                @click="
                  addStackModal = true;
                  input.departmentId = stackFindProps.departmentId;
                "
              >
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </div>
            <v-card-text>
              <v-list dense style="height: 70vh; overflow-y: auto">
                <v-list-item-group v-model="selectedStack" color="primary">
                  <v-list-item
                    v-for="(stack, index) in stacks"
                    :key="'stack-' + index"
                  >
                    <v-list-item-content @click="loadShelves(stack)">
                      <v-list-item-title>
                        {{ stack.stackTypeName }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        М.хранения:{{ stack.stackNumber }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-btn icon @click="openStack(stack)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        @click="
                          showStackDeleteDialog = true;
                          willBeRemovedStack = stack;
                        "
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>
          </v-col>
          <v-col cols="3">
            <div class="text-center">
              <v-chip color="#5d4bd0" style="font-size: 1.05rem" label>
                Полки
              </v-chip>
              <v-btn
                icon
                color="success"
                @click="
                  addShelfModal = true;
                  input.stackId = stacks[selectedStack || 0].stackId;
                "
              >
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </div>
            <v-card-text>
              <v-list dense style="height: 70vh; overflow-y: auto">
                <v-list-item-group color="#5d4bd0">
                  <v-list-item
                    v-for="(shelf, index) in shelves"
                    :key="'shelf-' + index"
                  >
                    <v-list-item-content @click="loadProducts(shelf)">
                      <v-list-item-title>
                        Полка #{{ shelf.shelfNumber }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        id: {{ shelf.shelfId }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-btn icon @click="openShelf(shelf)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        @click="
                          showShelfDeleteDialog = true;
                          willBeRemovedShelf = shelf;
                        "
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>
          </v-col>
          <v-col cols="6">
            <div class="text-center">
              <v-chip color="#5d4bd0" style="font-size: 1.05rem" label>
                Продукты
              </v-chip>
            </div>
            <v-card-text>
              <v-simple-table dense height="70vh">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Name</th>
                      <th class="text-left">Category</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(product, index) in products"
                      :key="'product-' + index"
                    >
                      <td>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ product.productName }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            id связи товара с полкой:
                            {{ product.shelfProductId }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </td>
                      <td>
                        {{ product.categoryId }}
                      </td>
                      <td>
                        <v-btn icon @click="deleteProduct(product)">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>

      <!-- STACK DELETE DIALOG -->
      <delete-dialog
        :show="showStackDeleteDialog"
        @close-modal="showStackDeleteDialog = false"
        @delete-modal="deleteStack()"
      />

      <!-- SHELF DELETE DIALOG -->
      <delete-dialog
        :show="showShelfDeleteDialog"
        @close-modal="showShelfDeleteDialog = false"
        @delete-modal="deleteShelf()"
      />
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import api from "@/api";
import loader from "../components/Loader";
import OverlayForm from "../components/stackSystem/OverlayForm.vue";
import DeleteDialog from "@/components/DeleteDialog";
import ClientsListModal from "../components/File/ClientsListModal.vue";
import InstructionModalComp from "../components/stackSystem/InstructionModalComp.vue";
import showMessage from "@/Functions/message";

export default {
  name: "AdminStackSystem",
  components: {
    loader,
    OverlayForm,
    DeleteDialog,
    ClientsListModal,
    InstructionModalComp,
  },
  data() {
    return {
      loading: true,
      instructionModal: false, // модальное окно инструкции
      creatingTable: false, // флаг на формирование отчета
      stackFindProps: {
        departmentId: null,
      },
      showModal: false,
      addStackModal: false,
      editStackModal: false,
      addShelfModal: false,
      editShelfModal: false,
      showStackDeleteDialog: false,
      showShelfDeleteDialog: false,
      selectedStack: null,
      departments: [], // список департаментов в списке выбора
      stacks: [], // список типов оборудования
      shelves: [], // список полок
      products: [], // список продуктов
      willBeRemovedStack: null,
      willBeRemovedShelf: null,
      input: {
        departmentId: null,
        stackTypeCodeName: "",
        stackNumber: null,
        stackId: null,
        shelfNumber: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      USER: "User/STATE",
      STACKSYSTEM: "StackSystem/STATE",
      CITY: "City/STATE",
    }),
  },
  async created() {
    this.loading = true;
    await this.renderPage();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      GET_STACKS_BY_DEPARTMENT: "StackSystem/GET_STACKS_BY_DEPARTMENT",
      GET_SHELVES_BY_STACK: "StackSystem/GET_SHELVES_BY_STACK",
      GET_SHELF_PRODCUTS_BY_SHELF: "StackSystem/GET_SHELF_PRODCUTS_BY_SHELF",
      ADD_NEW_STACK: "StackSystem/ADD_NEW_STACK",
      DELETE_STACK: "StackSystem/DELETE_STACK",
      EDIT_STACK_BY_ID: "StackSystem/EDIT_STACK_BY_ID",
      ADD_NEW_SHELF: "StackSystem/ADD_NEW_SHELF",
      EDIT_SHELF_BY_ID: "StackSystem/EDIT_SHELF_BY_ID",
      DELETE_SHELF: "StackSystem/DELETE_SHELF",
      DELETE_SEVERAL_PRODUCTS: "StackSystem/DELETE_SEVERAL_PRODUCTS",
      GET_ALL_STACK_TYPES: "StackSystem/GET_ALL_STACK_TYPES",
    }),
    async renderPage() {
      this.input = {
        departmentId: null,
        stackTypeCodeName: "",
        stackNumber: null,
        stackId: null,
        shelfNumber: null,
        shelfId: null,
      };
      this.selectedStack = null;
      if (this.USER.userInfo.departments.length > 0) {
        if (this.stackFindProps.departmentId === null) {
          this.stackFindProps.departmentId = this.USER.userInfo.departments[0].id;
        }
        this.departments = this.USER.userInfo.departments;
        await this.loadStacks(this.stackFindProps.departmentId);
        await this.GET_ALL_STACK_TYPES();
      } else {
        showMessage(
          `нет доступных филиалов у данного аккаунта в ${
            this.CITY.allCity.filter((e) => e.id == this.$root.city)[0].name
          }`
        );
      }
    },

    async loadStacks(departmentId) {
      this.loading = true;
      this.shelves = [];
      this.products = [];
      await this.GET_STACKS_BY_DEPARTMENT(departmentId);
      this.stacks = this.STACKSYSTEM.stacks;
      this.loading = false;
    },
    async loadShelves(stack) {
      this.loading = true;
      this.shelves = [];
      this.products = [];
      await this.GET_SHELVES_BY_STACK(stack.stackId);
      this.shelves = this.STACKSYSTEM.shelves;
      this.loading = false;
    },
    async loadProducts(shelf) {
      this.loading = true;
      let id;
      if (shelf.shelfId) {
        id = shelf.shelfId;
      } else {
        id = shelf;
      }
      await this.GET_SHELF_PRODCUTS_BY_SHELF(id);
      this.products = this.STACKSYSTEM.shelfProducts;
      this.loading = false;
    },

    openStack(stack) {
      this.input = {
        ...this.input,
        departmentId: stack.departmentId,
        stackNumber: stack.stackNumber,
        stackTypeCodeName: this.STACKSYSTEM.stackTypes.filter(
          (i) => i.name === stack.stackTypeName
        )[0].codeName,
        stackId: stack.stackId,
      };
      this.editStackModal = true;
    },
    openShelf(shelf) {
      this.input = {
        ...this.input,
        stackId: shelf.stackId,
        shelfNumber: shelf.shelfNumber,
        shelfId: shelf.shelfId,
      };
      this.editShelfModal = true;
    },

    async addNewStack() {
      this.loading = true;
      const response = await this.ADD_NEW_STACK({
        departmentId: this.input.departmentId,
        stackTypeCodeName: this.input.stackTypeCodeName,
        stackNumber: this.input.stackNumber,
      });
      this.addStackModal = false;
      if (response.type === "error") {
        showMessage(response.data.message);
      }
      await this.renderPage();
      this.loading = false;
    },
    async editStack() {
      this.loading = true;
      const response = await this.EDIT_STACK_BY_ID({
        stackId: this.input.stackId,
        input: {
          departmentId: this.input.departmentId,
          stackTypeCodeName: this.input.stackTypeCodeName,
          stackNumber: this.input.stackNumber,
        },
      });
      this.editStackModal = false;
      if (response.type === "error") {
        showMessage(response.data.message);
      }
      await this.renderPage();
      this.loading = false;
    },
    async deleteStack() {
      this.loading = true;
      await this.DELETE_STACK(this.willBeRemovedStack.stackId);
      this.showStackDeleteDialog = false;
      await this.renderPage();
      this.loading = false;
    },

    async addNewShelf() {
      this.loading = true;
      const response = await this.ADD_NEW_SHELF({
        stackId: this.input.stackId,
        shelfNumber: this.input.shelfNumber,
      });
      this.addShelfModal = false;
      if (response.type === "error") {
        showMessage(response.data.message);
      }
      let tempSelectedStack = this.selectedStack;
      await this.renderPage();
      this.selectedStack = tempSelectedStack;
      await this.loadShelves(this.stacks[tempSelectedStack]);
      this.loading = false;
    },

    async editShelf() {
      this.loading = true;
      const response = await this.EDIT_SHELF_BY_ID({
        shelfId: this.input.shelfId,
        input: {
          stackId: this.input.stackId,
          shelfNumber: this.input.shelfNumber,
        },
      });
      this.editShelfModal = false;
      if (response.type === "error") {
        showMessage(response.data.message);
      }
      let tempSelectedStack = this.selectedStack;
      await this.renderPage();
      this.selectedStack = tempSelectedStack;
      await this.loadShelves(this.stacks[tempSelectedStack]);
      this.loading = false;
    },

    async deleteShelf() {
      this.loading = true;
      await this.DELETE_SHELF(this.willBeRemovedShelf.shelfId);
      this.showShelfDeleteDialog = false;
      let tempSelectedStack = this.selectedStack;
      await this.renderPage();
      this.selectedStack = tempSelectedStack;
      await this.loadShelves(this.stacks[tempSelectedStack]);
      this.loading = false;
    },

    async deleteProduct(product) {
      this.loading = true;
      await this.DELETE_SEVERAL_PRODUCTS([product.shelfProductId]);
      await this.loadProducts(product.shelfId);
      this.loading = false;
    },

    async importStackTable(val) {
      this.loading = true;
      let data = new FormData();
      data.append("file", val.target.files[0]);
      let response = await api.StackSystem.importStackInfoByExcel(data);
      if (response.type === "error") {
        showMessage(response.data.message);
      }
      this.$refs.excelFile.value = "";
      await this.renderPage();
      this.loading = false;
    },
    async exportStackTable() {
      this.creatingTable = true;
      await api.StackSystem.excportStackInfoExcelByDepartmentId(
        this.stackFindProps.departmentId
      );
      showMessage(
        "Файл начал формироваться, список сформированных файлов вы можете посмотреть выше",
        true
      );
      this.creatingTable = false;
    },
    async downloadFile(text) {
      let baseURL = process.env.VUE_APP_FILE_URL;
      let url = baseURL + "/uploads/" + text;
      const link = document.createElement("a");
      link.href = url;
      link.download = text;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    getList() {
      return api.StackSystem.getExelList;
    },
    goToExcludedQueuePage() {
      this.$router.push("/stackSystem/excluded-queue");
    },
  },
};
</script>

<style lang="scss" scoped>
.stack-system {
  &__title {
    font-weight: 600;
    font-size: 20px;
    @media all and (max-width: 600px) {
      font-size: 12px;
    }
  }
  &__container {
    /* height: calc(100vh - 94px - 72px - 50px); */
    border-radius: 8px 8px;
    overflow: hidden;
    &-sub {
      height: calc(100% - 105px);
      overflow-y: scroll;
      @media all and (max-width: 959px) {
      }
      &.mobile {
        height: max-content;
        display: flex;
        @media all and (max-width: 959px) {
          display: none;
        }
      }
    }
  }
  &__title-row {
    font-weight: 600;
    font-size: 18px;
    border-bottom: #e7e6e6 2px solid;
    box-sizing: border-box;
  }
}
.collumn {
  padding: 0;
}
.scrollable {
  overflow: scroll;
}
.col {
  flex-grow: 0;
}
</style>
