<template>
  <div id="printSection">
    <v-simple-table fixed-header height="78.5vh">
      <thead>
        <tr>
          <th style="width: 40px; padding: 0 3px !important">Сотрудник</th>
          <th
            v-for="(day, index) in days"
            :key="day + ' ' + index"
            class="cell text-center"
            :style="{
              color: day == 'Сб' || day == 'Вс' ? 'red' : '',
            }"
          >
            {{ day + " " + (index + 1) }}
          </th>
          <th class="cell">Итого</th>
        </tr>
        <tr>
          <th class="text-left cell">Слоты</th>
          <th v-for="slot in slots" :key="slot.date" class="cell">
            <v-btn small text @click="openSlotModal(slot)">{{
              getCount(slot)
            }}</v-btn>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          :style="{
            display: role == 0 || role == 1 ? '' : 'none',
          }"
        >
          <td :colspan="days.length + 1" class="text-center">Курьеры</td>
        </tr>
        <tr
          v-for="item in couriers"
          :key="'curier_' + item.employee.id"
          :style="{
            display: role == 0 || role == 1 ? '' : 'none',
          }"
        >
          <td class="cell">
            <div class="name-cell">
              <span>{{ item.employee.username }}</span>
              <div class="name-cell_btns">
                <v-btn
                  icon
                  color="#5d4bd0"
                  @click="$router.push('/employee-profile/' + item.employee.id)"
                >
                  <v-icon>mdi-account-circle</v-icon>
                </v-btn>
                <v-btn
                  icon
                  color="success"
                  @click="
                    showPlan = true;
                    choosenEmloyee = item.employee;
                  "
                >
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
              </div>
            </div>
          </td>
          <td
            v-for="(day, index) in days"
            :key="'day_cur_' + index"
            class="text-center hover_edge cell"
            :style="{
              background: day == 'Сб' || day == 'Вс' ? '#ffdada' : '',
              color: day == 'Сб' || day == 'Вс' ? 'black' : '',
              border:
                $dark.get() === true
                  ? 'thin solid rgba(255, 255, 255, 0.3)'
                  : 'thin solid rgba(0, 0, 0, 0.12)',
            }"
            @click="
              dialog = true;
              shift = item.shift[index] || item;
              getDay(index);
            "
          >
            <div
              v-if="item.shift[index]"
              :class="getShiftStatusClass(item.shift[index].shiftStatus.id)"
            >
              {{ item.shift[index].hours || 0 }}ч.
              {{ item.shift[index].minutes || 0 }}м.
            </div>
          </td>
          <td
            :style="{
              border:
                $dark.get() === true
                  ? 'thin solid rgba(255, 255, 255, 0.3)'
                  : 'thin solid rgba(0, 0, 0, 0.12)',
            }"
          >
            {{ item.result.hours }}ч. {{ item.result.minutes }}м.
          </td>
        </tr>
        <tr
          :style="{
            display: role == 0 || role == 2 ? '' : 'none',
          }"
        >
          <td :colspan="days.length + 1" class="text-center">Сборщики</td>
        </tr>
        <tr
          v-for="item in pickers"
          :key="'picker_' + item.employee.id"
          :style="{
            display: role == 0 || role == 2 ? '' : 'none',
          }"
        >
          <td class="cell">
            <div class="name-cell">
              <span>{{ item.employee.username }}</span>
              <div class="name-cell_btns">
                <v-btn
                  icon
                  color="#5d4bd0"
                  @click="$router.push('/employee-profile/' + item.employee.id)"
                >
                  <v-icon>mdi-account-circle</v-icon>
                </v-btn>
                <v-btn
                  icon
                  color="success"
                  @click="
                    showPlan = true;
                    choosenEmloyee = item.employee;
                  "
                >
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
              </div>
            </div>
          </td>
          <td
            v-for="(day, index) in days"
            :key="'day_pic_' + index"
            class="text-center hover_edge cell"
            :style="{
              background: day == 'Сб' || day == 'Вс' ? '#ffdada' : '',
              color: day == 'Сб' || day == 'Вс' ? 'black' : '',
              border:
                $dark.get() === true
                  ? 'thin solid rgba(255, 255, 255, 0.3)'
                  : 'thin solid rgba(0, 0, 0, 0.12)',
            }"
            @click="
              dialog = true;
              shift = item.shift[index] || item;
              getDay(index);
            "
          >
            <div
              v-if="item.shift[index]"
              :class="getShiftStatusClass(item.shift[index].shiftStatus.id)"
            >
              {{ item.shift[index].hours || 0 }}ч.
              {{ item.shift[index].minutes || 0 }}м.
            </div>
          </td>
          <td
            :style="{
              border:
                $dark.get() === true
                  ? 'thin solid rgba(255, 255, 255, 0.3)'
                  : 'thin solid rgba(0, 0, 0, 0.12)',
            }"
          >
            {{ item.result.hours }}ч. {{ item.result.minutes }}м.
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <schedule-dialog
      :dialog="dialog"
      :shift="shift"
      :input="input"
      :shift-date="shiftDate"
      @close-modal="dialog = false"
      @rerender="$emit('rerender')"
    ></schedule-dialog>
    <SlotModal
      :dialog.sync="showSlot"
      :data="editedSlot"
      :dep="input.departmentId"
      @rerender="$emit('rerender')"
    />
    <PlanModal
      :modal.sync="showPlan"
      :employee="choosenEmloyee"
      :dep="input.departmentId"
      :date-prop="[slots[0].date, slots.slice(-1)[0].date]"
      @rerender="$emit('rerender')"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import ScheduleDialog from "./ScheduleDialog";
import SlotModal from "./SlotModal";
import PlanModal from "@/components/TimeTable/PlanModal";
export default {
  components: { ScheduleDialog, SlotModal, PlanModal },
  props: {
    date: {
      type: String,
      default: null,
    },
    pickers: {
      type: Array,
      default: null,
    },
    couriers: {
      type: Array,
      default: null,
    },
    role: {
      type: Number,
      default: 0,
    },
    input: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      shift: {},
      shiftDate: "",
      showSlot: false,
      showPlan: false,
      editedSlot: null,
      choosenEmloyee: null,
      showPlanModal: false,
      firstAndLastDay: {},
    };
  },
  computed: {
    ...mapGetters({
      SCHEDULE: "Schedule/STATE",
    }),
    days() {
      moment.locale("ru");
      let days = moment(this.date, "YYYY-MM").daysInMonth();
      let week_title = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];
      let days_week = [];
      for (let i = 1; i <= days; i++) {
        days_week[i - 1] =
          week_title[moment(this.date + "-" + i, "YYYY-MM-DD").weekday()];
      }
      return days_week;
    },
    slots() {
      moment.locale("ru");
      let days = moment(this.date, "YYYY-MM").daysInMonth();
      let slots = [];
      for (let i = 1; i <= days; i++) {
        slots[i - 1] = {
          date: moment(this.date + "-" + i).format("YYYY-MM-DD"),
          courierSlot: this.getSlot(i, true),
          pickerSlot: this.getSlot(i),
        };
      }
      return slots;
    },
  },
  methods: {
    getDay(val) {
      val++;
      if (val < 10) {
        val = "0" + val;
      }
      this.shiftDate = this.date + "-" + val;
    },
    openSlotModal(slot) {
      this.showSlot = true;
      this.editedSlot = slot;
    },
    getSlot(i, courier) {
      let slots;
      if (courier) {
        slots = this.SCHEDULE.courierSlots.filter(
          (slot) => moment(slot.day).date() == i
        );
      } else {
        slots = this.SCHEDULE.pickerSlots.filter(
          (slot) => moment(slot.day).date() == i
        );
      }
      return slots;
    },
    getShiftStatusClass(statusId) {
      const statusClasses = {
        1: "black--text",
        2: "text-status_green-text",
        3: "text-status_yellow",
        4: "text-status_yellow-text",
        5: "text-status_red-background",
        6: "text-status_orange-text",
        7: "text-status_blue-text",
        8: "text-status_red-text",
      };
      return statusClasses[statusId] || "";
    },
    getCount(slot) {
      let confirmedCoirier = slot.courierSlot.reduce(
        (acc, item) => (acc += item.confirmedCount),
        0
      );
      let countCourier = slot.courierSlot.reduce(
        (acc, item) => (acc += item.count),
        0
      );
      let confirmedPicker = slot.pickerSlot.reduce(
        (acc, item) => (acc += item.confirmedCount),
        0
      );
      let countPicker = slot.pickerSlot.reduce(
        (acc, item) => (acc += item.count),
        0
      );
      return `${confirmedCoirier + confirmedPicker}/${
        countCourier + countPicker
      }`;
    },
  },
};
</script>
<style lang="scss">
.hover_edge {
  cursor: pointer;

  &:hover {
    background-color: #ccc !important;
  }
}
.cell {
  padding: 0 3px !important;
  width: 20px;
}
.name-cell {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &_btns {
    display: flex;
  }
}
.text-status {
  &_red-background {
    background-color: red;
    display: flex;
    align-items: center;
    // font-weight: 500;
    height: -webkit-fill-available;
  }
  &_blue-text {
    color: #1976d2;
  }
  &_grey-text {
    color: #aaa2a2;
    font-weight: 400;
  }
  &_yellow-text {
    color: #b3b000;
  }
  &_yellow {
    color: #b3b000;
  }
  &_green-text {
    color: rgb(3, 180, 3);
  }
  &_orange-text {
    color: #d86011;
  }
  &_red-text {
    color: red;
  }
}
</style>
