<template>
  <v-dialog
    v-model="show"
    activator="parent"
    width="70%"
    @click:outside="$emit('update:show', false)"
  >
    <v-card class="modal" :dark="$dark.get()">
      <v-card-title class="flex justify-center"> Изменить время </v-card-title>
      <v-card-text>
        <p v-if="editedDay">
          День :<span style="color: black; font-weight: bold">{{
            returnRuDay(editedDay.day)
          }}</span>
        </p>
        <v-form v-if="input.startTime !== null" ref="timeForm">
          <v-text-field
            v-model="input.startTime"
            v-mask="'##:##'"
            label="Начало работы филиала"
            :rules="[$validate.required, basicDataRule]"
          >
          </v-text-field>
          <v-text-field
            v-model="input.endTime"
            v-mask="'##:##'"
            label="Конец работы филиала"
            :rules="[$validate.required, basicDataRule]"
          >
          </v-text-field>
        </v-form>
        <div v-else>
          <p>Нерабочий день</p>
          <v-btn small color="#5d4bd0" dark @click="makeDayWork()"
            >Сделать рабочим</v-btn
          >
        </div>
      </v-card-text>
      <v-card-actions class="modal__actions">
        <v-btn @click="$emit('update:show', false)">Закрыть</v-btn>
        <v-btn color="#5d4bd0" dark @click="updateDay()">Изменить</v-btn>
        <v-btn
          v-if="input.startTime !== null"
          color="error"
          @click="makeDayFree()"
          >Сделать выходным</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import api from "@/api";
import showMessage from "@/Functions/message";
import returnRuDayFunc from "@/Functions/returnRuDay";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    editedDay: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      loading: false,
      mode: "Добавить",
      input: {
        startTime: "",
        endTime: "",
      },
    };
  },
  computed: {
    basicDataRule() {
      if (
        this.input.startTime &&
        this.input.endTime &&
        +(this.input.startTime.slice(0, 2) < +this.input.endTime.slice(0, 2))
      ) {
        return true;
      } else return "Время начала работы должно быть ранее чем конец";
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.input.startTime = this.editedDay.startTime;
        this.input.endTime = this.editedDay.endTime;
      } else {
        if (this.input.startTime !== null) {
          this.$refs.timeForm.resetValidation();
        }
      }
    },
  },
  methods: {
    async updateDay() {
      if (this.input.startTime !== null) {
        if (!this.$refs.timeForm.validate()) {
          return;
        }
      }
      this.input.id = this.editedDay.id;
      let response = await api.TimeControl.editDay(this.input);
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("День изменен", true);
        this.$emit("update:show", false);
        this.$emit("update-time");
      }
    },
    makeDayFree() {
      this.input.startTime = null;
      this.input.endTime = null;
    },
    makeDayWork() {
      this.input.startTime = "";
      this.input.endTime = "";
    },
    returnRuDay(enDay) {
      return returnRuDayFunc(enDay);
    },
  },
};
</script>
