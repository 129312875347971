/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";
const name = "VitrinTags";
const namespaced = true;

const state = {
  allTags: [],
};

const getters = {
  STATE: (state) => {
    return state.allTags;
  },
};
const mutations = {
  //Получение всего
  SET_All_TAGS: (state, data) => {
    state.allTags = data;
  },
};

const actions = {
  GET_ALL_TAGS: async ({ commit }) => {
    const data = await api.VitrinTags.getAll();
    commit("SET_All_TAGS", data);
  },
  ADD_NEW_TAG: async ({ commit }, payload) => {
    const data = await api.Reviews.addNewTag(payload);
    return data;
  },
  REDACT_TAG: async ({ commit }, payload) => {
    const data = await api.Reviews.redactTag(payload);
    return data;
  },
};

export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
