<template>
  <div>
    <v-card :dark="$dark.get()" style="border-radius: 0" elevation="1">
      <delete-dialog
        :show="show"
        :is-delete-reason="isDeleteReason"
        @close-modal="show = !show"
        @delete-modal="deleteModal($event)"
      />
      <div v-if="showErrorDialog">
        <error-dialog v-if="items.body.length === 0" />
      </div>
      <v-card-title class="header">
        {{ `Таблица "${$route.name}"` }}
      </v-card-title>
      <div v-if="$route.path === '/department'" class="text-block">
        {{ text }}
      </div>
      <div class="d-flex align-center">
        <div v-if="select" style="width: 200px" class="d-inline-block ml-4">
          <v-select
            :value="selId"
            outlined
            hide-details
            dense
            :items="select"
            item-text="name"
            item-value="id"
            @change="$emit('set-select', $event)"
          ></v-select>
        </div>
        <div v-if="select2" style="width: 200px" class="d-inline-block ml-4">
          <v-select
            :value="sel2Id"
            outlined
            hide-details
            dense
            :items="select2"
            item-text="name"
            item-value="id"
            @change="$emit('set-secselect', $event)"
          ></v-select>
        </div>
        <div v-if="search" style="width: 200px" class="d-inline-block ml-4">
          <v-autocomplete
            v-model="searchModel"
            :search-input.sync="searchItem"
            :items="searchItems"
            item-text="name"
            item-value="id"
            no-data-text="Начните поиск"
            outlined
            label="Категории"
            dense
            hide-details
            append-outer-icon="mdi-magnify"
            @change="$emit('get-search', searchModel)"
            @click:append-outer="$emit('search', searchItem)"
            @keydown.enter="$emit('search', searchItem)"
          >
            <template slot="item" slot-scope="data">
              <v-list-item-content>
                <v-list-item-title
                  >{{ data.item.name + " - " + data.item.id }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>
        <v-btn v-if="buttonShow" text :to="$route.path + tag + '/new'"
          ><v-icon color="#5d4bd0" class="mr-2">mdi-plus-circle</v-icon>Добавить
        </v-btn>
        <v-btn
          v-if="limits"
          color="success"
          class="ml-4"
          @click="$router.push('providers-limit/0')"
        >
          Посмотреть лимиты
        </v-btn>
        <v-spacer></v-spacer>
        <div style="width: 300px" class="d-inline-block mr-4">
          <slot></slot>
        </div>
      </div>
      <v-card-text class="pa-0 mt-1">
        <v-simple-table height="64vh" class="table">
          <thead>
            <tr class="table_header">
              <th v-if="edit || delet" style="width: 20px">
                <v-checkbox
                  v-model="all"
                  @change="
                    checks.forEach((el) => {
                      el.state = all;
                    })
                  "
                />
              </th>
              <th v-for="item in items.head" :key="item.id">
                <span
                  v-if="item.name === 'Изображение' || item.name === 'Баланс'"
                  style="cursor: pointer"
                  @click="sort(item.id)"
                >
                  {{ item.name }}
                  <v-icon v-if="sortDirection === 'desc'">mdi-arrow-up</v-icon>
                  <v-icon v-if="sortDirection === 'asc'">mdi-arrow-down</v-icon>
                </span>
                <span v-else>{{ item.name }}</span>
              </th>
            </tr>
          </thead>
          <tbody style="max-height: 92vh; min-height: 64vh">
            <tr
              v-for="(item, index) in items.body"
              :key="item.id + '/' + index"
            >
              <td v-if="edit || delet">
                <v-checkbox
                  v-model="checks[index].state"
                  @change="all = false"
                />
              </td>
              <td
                v-for="field in item.fields"
                :key="'table-cell/' + field.id"
                style="max-width: 300px; max-height: 50px"
              >
                <!-- type String -->
                <span v-if="field.type === 'string'">
                  {{ field.name === true ? "Да" : field.name || "Нет" }}
                </span>
                <!-- type Image -->
                <div v-if="field.type === 'image'">
                  <input
                    :ref="'imgInput' + item.fields[0].name"
                    type="file"
                    hidden
                    @change="
                      $emit('image_click', {
                        value: $event,
                        id: item.fields[0].name,
                      })
                    "
                  />
                  <v-img
                    width="50px"
                    height="50px"
                    :style="{
                      cursor: 'pointer' && item.type === 'image',
                    }"
                    :src="field.name"
                    @click="$refs['imgInput' + item.fields[0].name][0].click()"
                  />
                </div>
                <!-- type CategorySelect -->
                <v-autocomplete
                  v-if="field.type === 'select'"
                  v-model="field.name"
                  :items="CATEGORY.allCategory"
                  :item-text="getProductName"
                  item-value="id"
                  multiple
                  label="Категория"
                  @change="updateCategoryOfProduct(item.fields[0].name, $event)"
                >
                </v-autocomplete>
                <!-- type Button -->
                <v-btn
                  v-if="field.type === 'button'"
                  color="#5d4bd0"
                  dark
                  @click="$emit('seo-save', item.fields[0].name)"
                >
                  {{ field.name }}
                </v-btn>
                <v-btn
                  v-if="field.type === 'redirect'"
                  icon
                  color="#5d4bd0"
                  dark
                  @click="$router.push('department-profile/' + field.name)"
                >
                  <v-icon>mdi-arrow-right-circle</v-icon>
                </v-btn>
                <!-- для восстановление работников -->
                <v-btn
                  v-if="field.type === 'restoreEmployee' && field.name"
                  color="#5d4bd0"
                  dark
                  small
                  @click="$emit('restore', field.name)"
                >
                  Восстановить
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-pagination
      v-if="pages !== null"
      :value="pages.current + 1"
      :dark="$dark.get()"
      :total-visible="7"
      :length="pages.count + 1"
      color="#5d4bd0"
      @input="$emit('change_page', $event - 1)"
    ></v-pagination>
    <bottom-bar
      :id="id"
      :active="active"
      :edit="edit"
      :delet="delet"
      :disable="states.length > 1"
      :tag="tag"
      @show="show = true"
    >
    </bottom-bar>
  </div>
</template>

<script>
import "babel-polyfill";
import DeleteDialog from "@/components/DeleteDialog";
import BottomBar from "@/components/BottomBar";
import ErrorDialog from "@/components/ErrorDialog";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { ErrorDialog, BottomBar, DeleteDialog },
  props: {
    items: {
      type: Object,
      required: true,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    delet: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: false,
    },
    searchItems: {
      type: Array,
      default: null,
    },
    pages: {
      type: Object,
      default: null,
    },
    select: {
      type: Array,
      default: null,
    },
    selId: {
      type: Number,
      default: 0,
    },
    select2: {
      type: Array,
      default: null,
    },
    sel2Id: {
      type: Number,
      default: 0,
    },
    tag: {
      type: String,
      default: "",
    },
    buttonShow: {
      type: Boolean,
      default: true,
    },
    showErrorDialog: {
      type: Boolean,
      default: true,
      required: false,
    },
    isDeleteReason: {
      type: Boolean,
      default: false,
    },
    limits: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      add: false,
      page: 1,
      show: false,
      checks: [],
      all: false,
      searchItem: null,
      searchModel: null,
      categoryIds: [],
      currentSort: 0,
      sortDirection: "asc",
      text:
        "В системе AyanMarket филиалом называется торговая точка, с которой продавец производит отгрузку товаров. На Ваших филиалах может быть разный ассортимент, разные цены и акционная активность. ",
    };
  },
  computed: {
    ...mapGetters({
      CATEGORY: "Category/STATE",
    }),
    states() {
      return this.checks.filter((x) => x.state === true);
    },
    active() {
      if (this.states.length > 0) return "active";
      return "";
    },
    id() {
      if (this.states.length !== 0) return this.states[0].id;
      return null;
    },
  },
  async created() {
    this.checks = this.items.body.map(function (x) {
      return {
        state: false,
        id: x.fields[0].name,
      };
    });
    await this.GET_TOTAL_CATEGORY();
  },
  beforeUpdate() {
    for (let item in this.items.body) {
      this.checks[item].id = this.items.body[item].fields[0].name;
    }
  },
  methods: {
    ...mapActions({
      UPDATE_CATEGORY_OF_PRODUCT: "Category/UPDATE_CATEGORY_OF_PRODUCT",
      GET_TOTAL_CATEGORY: "Category/GET_ALL_CATEGORY",
      ADD_IMAGE: "Products/ADD_IMAGE",
    }),
    updateCategoryOfProduct(productId, categoryIds) {
      const response = this.UPDATE_CATEGORY_OF_PRODUCT({
        productId,
        categoryIds,
      });
    },
    getProductName(val) {
      return val.name + " - " + val.id;
    },
    sort(sortType) {
      if (sortType === this.currentSort) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      }
      this.currentSort = sortType;
      this.$emit("sort-items", {
        currentSort: this.currentSort,
        sortDirection: this.sortDirection,
      });
    },
    deleteModal(payload) {
      if (payload) {
        let body = {
          deleteReason: payload,
          productId: this.states[0].id,
        };
        this.$emit("delete-button", body);
      } else {
        this.$emit("delete-button", this.states);
      }
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss">
.adaptive_table {
  height: 74.8vh;
  overflow-y: auto;
}
@media screen and (max-width: 1400px) {
  .adaptive_table {
    height: 65.4vh;
    overflow-y: auto;
  }
}
.header {
  background: linear-gradient(to right, #e93e7b 50%, white 70%);
  margin-left: -12px;
  color: white;
  padding: 5px 50px;
  margin-bottom: 10px;
  @media (max-width: 500px) {
    margin-left: 0px;
    padding: 5px;
    justify-content: center;
    background: linear-gradient(90deg, #e93e7b 70%, white 90%);
  }
}
.text-block {
  width: 70%;
  padding: 10px;
  @media (max-width: 500px) {
    width: 100%;
  }
}
.table {
  margin-top: 20px;
  &_header {
    background: linear-gradient(to right, #5d4bd0, #e93e7b);
  }
  th {
    background: none !important;
    color: white !important;
    @media (max-width: 500px) {
      padding: 0 3px !important;
    }
  }
  td {
    border-bottom: solid 1px #e93e7a45 !important;
    @media (max-width: 500px) {
      padding: 0 3px !important;
    }
  }
}
</style>
