<template>
  <div v-if="loading" class="loader">
    <loader></loader>
  </div>
  <v-card v-else fluid :dark="$dark.get()">
    <v-card-title class="pt-2 pb-2">
      {{ $route.name }}
    </v-card-title>
    <v-card-text style="padding-top: 2.5px">
      <v-row>
        <v-col cols="12" class="pt-0">
          <yandex-map
            :zoom="map.zoom"
            :settings="mapSettings"
            :coords="map.coords"
            map-type="map"
            @boundschange="mapBoundschange"
            @map-was-initialized="initMap"
          >
          </yandex-map>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="2" class="text-right pb-0 pt-0">
              Наименование района
            </v-col>
            <v-col cols="9" class="pb-0 pt-0">
              <v-text-field
                v-model="singleZone.name"
                outlined
                dense
                :rules="[$validate.required]"
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="btns-block">
            <v-btn @click="$router.push('/couriers-area')"> Назад </v-btn>
            <v-btn
              v-if="buttonType !== 'Добавить'"
              color="error"
              @click="show = true"
            >
              Удалить
            </v-btn>
            <v-btn color="#5d4bd0" dark @click="buttonMeth()">{{
              buttonType
            }}</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <delete-dialog
      :show="show"
      @close-modal="show = !show"
      @delete-modal="deleteDeliveryZone()"
    />
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";
import DeleteDialog from "@/components/DeleteDialog";
import showMessage from "@/Functions/message";
export default {
  components: { Loader, DeleteDialog },
  data() {
    return {
      myMap: {},
      polygonList: [],
      editPolygonData: [],
      editPolygon: {},
      addPolygon: {},
      singleZone: {
        coords: [],
        name: "",
        id: "",
      },
      stateMonitor: {},
      map: {
        zoom: 11,
        coords: [49.783936, 73.145232],
      },
      loading: false,
      mapSettings: {
        apiKey: "",
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
      },
      btn: {
        add: {},
        edit: {},
        save: {},
        remove: {},
        close: {},
      },
      buttonType: "Добавить",
      show: false,
    };
  },
  computed: {
    ...mapGetters({
      STATE: "Couriers/STATE",
    }),
  },
  mounted() {
    this.$eventBus.$on("change-city", this.changeCity);
  },
  async created() {
    this.loading = true;
    if (this.$route.params.id) {
      if (this.STATE.allDeliveryZones.length === 0) {
        await this.getAllDeliveryZone();
      }
      this.singleZone = this.STATE.allDeliveryZones.filter(
        (zone) => zone.id == this.$route.params.id
      )[0];
      this.buttonType = "Изменить";
    }
    this.loading = false;
  },
  methods: {
    ...mapActions({
      addNewZone: "Couriers/ADD_DELIVERY_ZONE",
      removeZone: "Couriers/DELETE_DELIVERY_ZONE",
      updateZone: "Couriers/EDIT_DELIVERY_ZONE",
      getAllDeliveryZone: "Couriers/GET_ALL_DELIVERY_ZONES",
    }),
    mapBoundschange(e) {
      if (parseInt(e._cache.newZoom) > 0) {
        this.map.zoom = e._cache.newZoom;
      }
      if (typeof e._cache.newCenter.isArray === "undefined") {
        this.map.coords = e._cache.newCenter;
      }
    },
    initMap(map) {
      this.myMap = map;
      this.addPolygonList();

      this.btn.add = new ymaps.control.Button({
        data: {
          content: "Создать",
        },
        options: {
          layout: ymaps.templateLayoutFactory.createClass(
            [
              '<div class="ymap_btn ymap_btn--blue">{{ data.content }}</div>',
            ].join("")
          ),
        },
      });
      this.btn.edit = new ymaps.control.Button({
        data: {
          content: "Редактировать",
        },
        options: {
          layout: ymaps.templateLayoutFactory.createClass(
            [
              '<div class="ymap_btn ymap_btn--yellow">{{ data.content }}</div>',
            ].join("")
          ),
        },
      });
      if (this.$route.params.id == undefined) {
        this.myMap.controls.add(this.btn.add, { float: "right" });
        this.btn.add.events.add("click", (e) => {
          this.createPolygon();
          this.myMap.controls.remove(this.btn.add);
        });
        this.myMap.controls.add(this.btn.add, { float: "right" });
      } else {
        this.myMap.controls.add(this.btn.edit, { float: "right" });
        this.btn.edit.events.add("click", (e) => {
          this.myMap.controls.remove(this.btn.edit);
          this.$set(this, "editPolygonData", this.singleZone); // Записали на редактирование
          this.startEditPolygon();
        });
      }
    },
    startEditPolygon() {
      let coords = this.editPolygonData.coords;
      if (typeof coords == "string")
        coords = JSON.parse(this.editPolygonData.coords);
      this.editPolygon = new ymaps.Polygon([coords], {
        editorDrawingCursor: "crosshair",
        fillColor: "#6699ff",
        // Делаем полигон прозрачным для событий карты.
        interactivityModel: "default#transparent",
        strokeWidth: 8,
        opacity: 0.5,
      });

      this.myMap.geoObjects.add(this.editPolygon);
      this.stateMonitor = new ymaps.Monitor(this.editPolygon.editor.state);
      this.stateMonitor.add("drawing", (newValue) => {
        this.editPolygon.options.set(
          "strokeColor",
          newValue ? "#bcc1c1" : "#0000FF"
        );
      });
      this.editPolygon.editor.startDrawing();
      this.myMap.setBounds(this.editPolygon.geometry.getBounds());
    },
    // Создаем полигон для карты
    createPolygon() {
      this.$set(this, "editPolygonData", {
        id: 0,
        coords: [],
        name: "",
      });

      this.editPolygon = new ymaps.Polygon(
        [],
        {},
        {
          editorDrawingCursor: "crosshair",
          fillColor: "#00adff",
          strokeWidth: 3,
          opacity: 0.5,
        }
      );
      this.myMap.geoObjects.add(this.editPolygon);
      this.stateMonitor = new ymaps.Monitor(this.editPolygon.editor.state);
      this.stateMonitor.add("drawing", (newValue) => {
        this.editPolygon.options.set(
          "strokeColor",
          newValue ? "#bcc1c1" : "#0000FF"
        );
      });

      this.editPolygon.editor.startDrawing();
    },
    // Создает из массива список полигонов на карте
    addPolygonList() {
      // Сначала удаляем все полигоны
      for (let i = 0; i < this.polygonList.length; i++) {
        this.myMap.geoObjects.remove(this.polygonList[i]);
      }

      if (typeof this.myMap["geoObjects"] == "undefined") return false;

      let coords = this.singleZone.coords;
      if (typeof coords == "string")
        coords = JSON.parse(this.singleZone.coords);

      this.polygonList[0] = new ymaps.Polygon(
        [coords],
        {
          hintContent: this.singleZone.name,
        },
        {
          fillColor: "#00adff",
          interactivityModel: "default#transparent",
          strokeWidth: 1,
          opacity: 0.5,
        }
      );
      this.myMap.geoObjects.add(this.polygonList[0]);
      this.polygonList[0].id = this.singleZone.id;
    },
    // Возвращает данные текущего редактируемого полигона
    getEditPolygonData() {
      let data = [];
      this.myMap.geoObjects.each((GeoObject) => {
        if (this.singleZone.id > 0) {
          if (GeoObject.id == this.singleZone.id)
            data = GeoObject.geometry.getCoordinates()[0];
        }
        if (GeoObject.properties._data.hintContent === undefined) {
          data = GeoObject.geometry.getCoordinates()[0];
        }
      });
      return data;
    },
    async savePolygonFunc() {
      this.singleZone.coords = this.getEditPolygonData();
      delete this.singleZone.deliveryZoneDepartments;
      let response = await this.updateZone(this.singleZone);
      if (response?.type === "error") {
        showMessage(response.data.message);
      } else {
        this.$router.push("/couriers-area");
      }
    },
    async addPolygonFunc() {
      this.singleZone.coords = this.getEditPolygonData();
      if (this.singleZone.name === "") {
        showMessage("Укажите название зоны");
        return;
      }
      if (this.singleZone.coords.length === 0) {
        showMessage("Введите координаты зоны");
        return;
      }
      delete this.singleZone.id;
      let response = await this.addNewZone(this.singleZone);
      if (response?.type === "error") {
        showMessage(response.data.message);
      } else {
        this.$router.push("/couriers-area");
      }
    },
    async buttonMeth() {
      if (this.buttonType === "Добавить") {
        this.addPolygonFunc();
      } else {
        this.savePolygonFunc();
      }
    },
    async deleteDeliveryZone() {
      let response = await this.removeZone(this.singleZone.id);
      if (response?.type === "error") {
        showMessage(response.data.message);
      } else {
        this.$router.push("/couriers-area");
      }
    },
  },
};
</script>
<style lang="scss">
.ymap-container {
  @media screen and (max-width: 3000px) {
    min-height: 32vmax;
    height: 75%;
  }
  @media screen and (max-width: 1920px) {
    min-height: 29vmax;
    height: 70%;
  }
  @media screen and (max-width: 1440px) {
    min-height: 450px;
    height: 50%;
  }
  @media screen and (max-width: 1366px) {
    min-height: 320px;
    height: 30%;
  }
}
.ymap_btn {
  background-color: #fff;
  border-color: transparent;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.15),
    0 2px 5px -3px rgba(0, 0, 0, 0.15);
  box-sizing: border-box !important;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  background-clip: border-box;
  color: #000;
  vertical-align: middle;
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
  -webkit-transition: background-color 0.15s ease-out,
    border-color 0.15s ease-out, opacity 0.15s ease-out;
  transition: background-color 0.15s ease-out, border-color 0.15s ease-out,
    opacity 0.15s ease-out;
  display: inline-block;
  text-align: left;
  height: 28px;
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
  padding: {
    top: 4px;
    left: 10px;
    right: 10px;
  }

  &--blue {
    color: #fff;
    background-color: rgb(85, 201, 255);
  }
  &--red {
    color: #fff;
    background-color: #f55;
  }
  &--yellow {
    color: #fff;
    background-color: #f4c712;
  }
  &--green {
    color: #fff;
    background-color: #00c469;
  }
}
.loader {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
}
.btns-block {
  display: flex;
  justify-content: right;
  gap: 10px;
}
</style>
